import {ce} from "./MiscUtils";
import {Centered} from "./MiscComponents";
import {useEffect, useState} from "react";


export type IppeLoadingProps = {}

export const IppeLoadingComponent = (props: IppeLoadingProps) => {
  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setClicks(clicks => ((clicks + 1) % 4));
    }, 300);

    return () => clearInterval(interval);
  }, [clicks]);

  const ellipsis = ".".repeat(clicks % 4)
  return ce(Centered, {style: {width: "100%", height: "100%"}},
    ce("div", {style: {width: 100, padding: 16}}, "Loading" + ellipsis));
}