import React from "react";
import IconButton, {IconButtonProps} from "@mui/material/IconButton";
import {ce} from "../IppeUtils/MiscUtils";
import {Button, Popover, PopoverProps, TextField, Tooltip} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {HorizontalStack, VerticalStack} from "../IppeUtils/MiscComponents";
import axios from "axios";
import {getPostFavoritesUrl} from "../IppeUtils/IppeUrls";


export type FavoriteButtonProps = {
  expression: string,
  disabled: boolean
}

type FavoriteButtonState = {
  anchorEl: HTMLButtonElement | undefined,
  name: string,
  executing: boolean
}

export class FavoriteButton extends React.Component<FavoriteButtonProps, FavoriteButtonState> {
  constructor(props: FavoriteButtonProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
      name: "",
      executing: false
    };

    this.onFavoriteClick = this.onFavoriteClick.bind(this);
    this.onTitleUpdated = this.onTitleUpdated.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onFavoriteClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({anchorEl: event.currentTarget})
  }

  onTitleUpdated(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({name: event.target.value})
  }

  onSubmit() {
    this.setState({executing: true})

    axios.post(getPostFavoritesUrl(), {
      name: this.state.name,
      expression: this.props.expression
    }).then((response) => {
      this.setState({anchorEl: undefined, name: "", executing: false})
    }).catch((error) => {
      this.setState({executing: false})
      console.log(error)
    })
  }

  onCancel() {
    this.setState({anchorEl: undefined, name: ""})
  }

  render() {
    const popoverProps: PopoverProps = {
      open: this.state.anchorEl !== undefined,
      onClose: () => {this.setState({anchorEl: undefined})},
      anchorEl: this.state.anchorEl || null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      }
    };

    const popover = this.state.anchorEl === undefined ? undefined :
      ce(Popover, popoverProps,
        ce(VerticalStack, {spacing: 1, style: {padding: 10, paddingTop: 25, width: 300}},
          ce(TextField, {disabled: this.state.executing, label: "Name", value: this.state.name, onChange: this.onTitleUpdated}),
          ce(HorizontalStack, {spacing: 1, justifyContent: "flex-end"},
            ce(Button, {onClick: this.onCancel, disabled: this.state.executing}, "Cancel"),
            ce(Button, {onClick: this.onSubmit, disabled: !this.state.name.trim() || this.state.executing}, "Submit"))));

    const buttonProps: IconButtonProps = {
      size: "small",
      onClick: this.onFavoriteClick,
      color: "error",
      disabled: this.props.disabled
    };

    return ce("div", {style: {display: "flex"}},
      ce(Tooltip, {title: "Favorite Expression"} as any,
        ce(IconButton, buttonProps,
          ce(FavoriteIcon, {fontSize: "small"}))),
      popover);
  }
}