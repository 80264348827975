import {Content} from "../../IppeUtils/IppeTypes";
import React from "react";
import {ce} from "../../IppeUtils/MiscUtils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {isText} from "../../IppeUtils/TextOrBinaryUtils";
import {AckingIconButton} from "../../IppeUtils/AckingIconButton";

export type ContentCopyButtonProps = {
  content: Content | undefined
}

export class ContentCopyButton extends React.Component<ContentCopyButtonProps, {}> {
  constructor(props: ContentCopyButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(): Promise<void> {
    return new Promise((resolve, reject) => {
      const {mimeType, content} = this.props.content || {};
      if (content !== undefined) {
        const blob = new Blob([content], {type: isText(content) ? "text/plain" : mimeType});
        const clipboardItem = new ClipboardItem({[blob.type]: blob})
        navigator.clipboard.write([clipboardItem]).then(r => {
          resolve();
        }).catch(err => {
          if (mimeType !== "text/plain" && mimeType !== undefined) {
            reject(`Browser clipboard support is inconsistent by content type.  Unable to copy ${mimeType}.`)
          } else {
            reject(`Unable to copy content to clipboard.`);
          }
        })
      }
    });
  }

  render() {
    return ce(AckingIconButton, {
      toolTip: "Copy",
      onClick: this.onClick,
      disabled: this.props.content === undefined,
      icon: ContentCopyIcon
    });
  }
}