import {merge} from "./MiscUtils";
import React from "react";
import StyleBuilder from "./StyleBuilder";

export const IPPE_APP_BAR_HEIGHT = 65;
export const IPPE_FOOTER_HEIGHT = 15;
export const IPPE_CONTENT_AREA_HEIGHT = `calc(100vh - ${IPPE_APP_BAR_HEIGHT}px - ${IPPE_FOOTER_HEIGHT}px)`;

export const IPPE_PRIMARY_COLOR = "#2E5090"; // 46, 80, 144
export const IPPE_PRIMARY_PRIME_COLOR = "rgb(137, 169, 229)";
export const IPPE_LIGHT_GREY_COLOR = "#f8f8f8";
export const IPPE_DIVIDER_COLOR = "#555555";

// export const IPPE_BOX_SHADOW="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"
export const IPPE_SHALLOW_BOX_SHADOW = "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
export const IPPE_DEEP_BOX_SHADOW = "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"

export const IPPE_CARD_BORDER_COLOR = "#999999";

export const IPPE_PREVIEW_CONTENT_BORDER = {
  borderStyle: "solid",
  borderWidth: "thin",
  borderColor: IPPE_CARD_BORDER_COLOR,
  borderRadius: 2
};

export const MONO_FONT_FAMILY = {fontFamily: "'Inconsolata', monospace"};
export const CODE_FONT_FAMILY = MONO_FONT_FAMILY;
export const CODE_FONT_FAMILY_BOLD = merge(CODE_FONT_FAMILY, {fontWeight: 600});

export const COPY_FONT_FAMILY = {
  fontFamily: "'Roboto', sans-serif",
  lineHeight: "1.35em",
};

export const IPPE_COMMAND_STYLE: React.CSSProperties = {
  color: "black",
  fontWeight: 600,
  ...CODE_FONT_FAMILY
};

export const IPPE_ARG_NAME_STYLE: React.CSSProperties = {
  color: IPPE_PRIMARY_COLOR,
  fontWeight: 600,
  ...CODE_FONT_FAMILY
};

export const IPPE_ARG_VALUE_STYLE: React.CSSProperties = {
  color: "darkgreen",
  fontStyle: "italic",
  ...CODE_FONT_FAMILY
};

export const IPPE_SMALL_BUTTON_STYLE = StyleBuilder.start().size(24).build();
