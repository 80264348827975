import {createQueryStringFromParams} from "./MiscUtilsTs";

export const IPPE_HOST: string = `${window.location.protocol}//${window.location.host}`

export function getListAliasesUrl(): string {
  return `/api/v0/aliases`;
}

export function getAliasUrl(key: string): string {
  return `/api/v0/aliases/${key}`;
}

export function getListToolsUrl(): string {
  return `/api/v0/tools`;
}

export function getToolUrl(key: string): string {
  return `/api/v0/tools/${key}`;
}

export function getSamplesListUrl(): string {
  return `/api/v0/samples`;
}

export function getListExecsUrl(): string {
  return `/api/v0/execs`;
}

export function getExecUrl(id: string): string {
  return `/api/v0/execs/$id`;
}

export function getPostExecsUrl(expression: string, preview: boolean = false): string {
  return `/api/v0/execs?expression=${encodeURIComponent(expression)}&preview=${String(preview)}`;
}

export function getDryrunsUrl(expression: string, mimeType: string | undefined): string {
  return `/api/v0/dryruns?expression=${encodeURIComponent(expression)}&mime_type=${encodeURIComponent(mimeType || "")}`;
}

export function getDashboardDocToolPath(fullCommandName: string): string {
  return `/docs/tools/${fullCommandName}`;
}

export function getPostAccountsUrl(): string {
  return `/api/v0/accounts`;
}

export function getPostLoginsUrl(): string {
  return `/api/v0/logins`;
}

export function getGetAccessKeysUrl(): string {
  return `/api/v0/access_keys`;
}

export function getPostAccessKeysUrl(): string {
  return `/api/v0/access_keys`;
}

export function getPatchAccessKeyUrl(accessKeyId: string): string {
  return `/api/v0/access_keys/${accessKeyId}`;
}

export function getGetFavoritesUrl(): string {
  return `/api/v0/favorites`;
}

export function getPostFavoritesUrl(): string {
  return `/api/v0/favorites`;
}

export function getPatchFavoriteUrl(favoriteId: string): string {
  return `/api/v0/favorites/${favoriteId}`;
}

export function getDeleteFavoriteUrl(favoriteId: string): string {
  return `/api/v0/favorites/${favoriteId}`;
}

export function getSampleContentUrl(id: string): string {
  return `/api/v0/samples/${id}`;
}

export function getBuilderUrl(expression: string, inputId: string | undefined): string {
  const params = new Map()

  params.set("expression", expression)
  if (inputId)
    params.set("input_id", inputId)

  const queryString = createQueryStringFromParams(params);
  return `/builder?${queryString}`;
}
