import {IPPE_DIVIDER_COLOR} from "./IppeStyles";

const {merge} = require("./MiscUtils");

export default class StyleBuilder {
  static start(style = {}) {
    return new StyleBuilder(style);
  }

  constructor(style) {
    this.style = style;
  }

  // height will default to width if unspecified
  size(width, height = null) {
    return new StyleBuilder(merge(this.style, {width, height: height == null ? width : height}));
  }

  // height will default to width if unspecified
  minSize(width, height = null) {
    return new StyleBuilder(merge(this.style, {minWidth: width, minHeight: height == null ? width : height}));
  }

  // height will default to width if unspecified
  maxSize(width, height = null) {
    return new StyleBuilder(merge(this.style, {maxWidth: width, maxHeight: height == null ? width : height}));
  }

  height(height) {
    return new StyleBuilder(merge(this.style, {height: height, maxHeight: height, minHeight: height}))
  }

  width(width) {
    return new StyleBuilder(merge(this.style, {width: width, maxWidth: width, minWidth: width}))
  }

  sizeToParent() {
    return this.size("100%");
  }

  position(position) {
    return new StyleBuilder(merge(this.style, {position}));
  }

  fontSize(fontSize) {
    return new StyleBuilder(merge(this.style, {fontSize}));
  }

  lineHeight(lineHeight) {
    return new StyleBuilder(merge(this.style, {lineHeight}));
  }
  
  textColor(color) {
    return new StyleBuilder(merge(this.style, {color}));
  }

  uppercase() {
    return new StyleBuilder(merge(this.style, {textTransform: "uppercase"}));
  }

  bold() {
    return new StyleBuilder(merge(this.style, {fontWeight: "bold"}));
  }

  textAlign(alignment) {
    return new StyleBuilder(merge(this.style, {textAlign: alignment}));
  }

  background(background) {
    return new StyleBuilder(merge(this.style, {background}));
  }

  underline() {
    return new StyleBuilder(merge(this.style, {textDecoration: "underline"}));
  }

  padding(padding) {
    return new StyleBuilder(merge(this.style, {padding}));
  }

  // left will default to right if unspecified.
  hPadding(left, right = null) {
    return new StyleBuilder(merge(this.style, {paddingLeft: left, paddingRight: right == null ? left : right}));
  }

  // bottom will default to top if unspecified.
  vPadding(top, bottom = null) {
    return new StyleBuilder(merge(this.style, {paddingTop: top, paddingBottom: bottom == null ? top : bottom}));
  }

  // left will default to right if unspecified.
  hMargin(left, right = null) {
    return new StyleBuilder(merge(this.style, {marginLeft: left, marginRight: right == null ? left : right}));
  }

  // bottom will default to top if unspecified.
  vMargin(top, bottom = null) {
    return new StyleBuilder(merge(this.style, {marginTop: top, marginBottom: bottom == null ? top : bottom}));
  }

  top(top) {
    return new StyleBuilder(merge(this.style, {top: top}));
  }

  leftTop(left, top) {
    return new StyleBuilder(merge(this.style, {top: top, left: left}));
  }

  border(border) {
    return new StyleBuilder(merge(this.style, {border}));
  }

  topBorder(
    color = "lightgrey",
    width = 1,
  ) {
    return new StyleBuilder(merge(this.style, {
      borderTop: "solid",
      borderTopWidth: width,
      borderTopColor: color}));
  }

  bottomBorder(
    color = "lightgrey",
    width = 1,
  ) {
    return new StyleBuilder(merge(this.style, {
      borderBottom: "solid",
      borderBottomWidth: width,
      borderBottomColor: color
    }));
  }

  borderColor(borderColor) {
    return new StyleBuilder(merge(this.style, {borderColor}));
  }

  roundedGrayBorder() {
    return new StyleBuilder(merge(this.style, {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#333333",
      borderRadius: "4px"
    }));
  }

  boxShadow(boxShadow) {
    return new StyleBuilder(merge(this.style, {boxShadow}));
  }

  centeredFittedBackgroundImage(url) {
    return new StyleBuilder(merge(this.style, {
      backgroundImage: "url( " + url + " )",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain"
    }));
  }

  singleLineEllipsizeText() {
    return new StyleBuilder(merge(this.style, {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden"
    }));
  }

  overflowHidden() {
    return new StyleBuilder(merge(this.style, {overflow: "hidden"}))
  }

  overflowScroll() {
    return new StyleBuilder(merge(this.style, {overflow: "scroll"}))
  }

  build() {
    return this.style;
  }
}
