import React from "react";
import {HorizontalSpacer, HorizontalStack} from "../IppeUtils/MiscComponents";
import {CODE_FONT_FAMILY} from "../IppeUtils/IppeStyles";
import {mergeLeft} from "ramda";
import {Content, IppeCursorPosition, IppePipelineParsedNode, IppeToolSpec} from "../IppeUtils/IppeTypes";
import {ce, isReactDevMode, merge} from "../IppeUtils/MiscUtils";
import {IppeEditor} from "../IppeEditor/IppeCommandEditorComponent";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {CircularProgress, Tooltip} from "@mui/material";
import {SaveTestButton} from "../IppePreview/ActionButtons/SaveTestButton";
import {FavoriteButton} from "../IppeFavorite/FavoriteButton";
import {accumulateParseErrors} from "../IppeUtils/IppeUtils";


export type IppeShellComponentProps = {
  onCursorChange: (position: IppeCursorPosition) => void,
  onExpressionChange: (expression: string) => void,
  onExpressionSubmit: () => void,
  onExpressionReset: () => void,
  currentExpression: string,
  parsedExpression: IppePipelineParsedNode,
  tools: Array<IppeToolSpec>,
  previewContent: Content,
  previewLoading: boolean
}


export class IppeShellComponent extends React.Component<IppeShellComponentProps, {}> {
  constructor(props: IppeShellComponentProps) {
    super(props);
  }

  render() {
    const commonStyle = {
      marginTop: 20,
      marginBottom: 20,
      lineHeight: 1.25
    };

    const editor = ce(IppeEditor, {
      expression: this.props.currentExpression,
      onCursorChange: this.props.onCursorChange,
      onExpressionChange: this.props.onExpressionChange,
      onExpressionSubmit: this.props.onExpressionSubmit,
      parsedExpression: this.props.parsedExpression,
      tools: this.props.tools,
      style: merge(commonStyle, {flexGrow: 1}),
    });

    const submitButton = ce(Tooltip, {title: "Update Preview"} as any,
      ce(IconButton, {size: "small", color: "success", onClick: () => this.props.onExpressionSubmit()},
        ce(PlayArrowIcon, {fontSize: "medium"})));

    const loadingSpinner = ce(CircularProgress, {color: "success", style: {width: 24, height: 24, padding: 5}});

    // LOWTODO: disable if expression fails dryrun.
    const favoriteButton = ce(FavoriteButton, {
      expression: this.props.currentExpression,
      disabled: this.props.parsedExpression.commands.length === 0 || accumulateParseErrors(this.props.parsedExpression).length > 0
    });

    const resetButton = ce(Tooltip, {title: "Reset"} as any,
      ce(IconButton, {size: "small", color: "primary", onClick: () => this.props.onExpressionReset()},
        ce(DeleteIcon, {fontSize: "small"})));

    const saveTest = isReactDevMode() ? ce(SaveTestButton, {
      expression: this.props.currentExpression,
      input: this.props.previewContent
    }) : null;

    return ce(HorizontalStack, {alignItems: "flex-start", style: {width: "100%", fontSize: "1.35em"}},
      ce(HorizontalSpacer, {thickness: ".5em"}),
      ce("div", {style: commonStyle}, ce(IppePrompt)),
      editor,
      ce(HorizontalStack, {style: {marginTop: 15, height: 34, spacing: 4}},
        this.props.previewLoading ? loadingSpinner : submitButton,
        favoriteButton,
        resetButton,
        saveTest),
      ce(HorizontalSpacer, {thickness: ".5em"}));
  }
}

const IppePrompt = () =>
  ce("span", {style: mergeLeft({marginRight: ".3em"}, CODE_FONT_FAMILY)}, "|ppe>")
