import {ExecError, LinkError, ParseError} from "../../IppeUtils/IppeTypes";
import React from "react";
import {IppeErrorComponent} from "../../IppeErrors/IppeErrorComponent";
import {Popover, PopoverProps, Tooltip} from "@mui/material";
import IconButton, {IconButtonProps} from "@mui/material/IconButton";
import StyleBuilder from "../../IppeUtils/StyleBuilder";
import WarningIcon from "@mui/icons-material/Warning";
import {HorizontalSliver, VerticalStack} from "../../IppeUtils/MiscComponents";
import {ce} from "../../IppeUtils/MiscUtils";
import {IPPE_DIVIDER_COLOR} from "../../IppeUtils/IppeStyles";

export type ShowWarningsButtonProps = {
  errors: Array<ParseError | LinkError | ExecError> | undefined
}

type ShowWarningsButtonState = {
  anchorEl: HTMLButtonElement | undefined
}

export class ShowWarningsButton extends React.Component<ShowWarningsButtonProps, ShowWarningsButtonState> {
  constructor(props: ShowWarningsButtonProps) {
    super(props);

    this.state = {anchorEl: undefined}
    this.onClick = this.onClick.bind(this);
  }

  onClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({anchorEl: event.currentTarget})
  }

  render() {
    const errorComponents = this.props.errors?.map((error) => {
      return ce(IppeErrorComponent, {error, style: {}});
    });

    const popoverProps: PopoverProps = {
      open: this.state.anchorEl !== undefined,
      onClose: () => {this.setState({anchorEl: undefined})},
      anchorEl: this.state.anchorEl || null,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      }
    };

    const disabled = this.props.errors === undefined || this.props.errors.length === 0;
    const fatalErrors = this.props.errors?.filter(e => e.isFatal) || [];
    const textColor = disabled ? undefined : fatalErrors.length > 0 ? "red" : "orange";

    const buttonProps: IconButtonProps = {
      size: "small",
      onClick: this.onClick,
      disabled,
      style: StyleBuilder.start().size(30).textColor(textColor).build()
    };

    return ce("div", {},
      ce(Tooltip, {title: "Warnings & Errors"} as any,
        ce(IconButton, buttonProps,
          ce(WarningIcon))),
      ce(Popover, popoverProps,
        ce(VerticalStack, {divider: ce(HorizontalSliver, {color: IPPE_DIVIDER_COLOR})}, errorComponents)))
  }
}