import Cookies from 'js-cookie';

const ACCESS_KEY_ID = "Access-Key-Id"
const ACCESS_KEY_SECRET = "Access-Key-Secret"

interface KeyToHandler {
  [key: string]: () => void
}

class IppeLoginManager {
  private readonly onLoginHandlers: KeyToHandler = {};
  private readonly onLogoutHandlers: KeyToHandler = {};
  private readonly onLoginRequestedHandlers: KeyToHandler = {};
  private readonly onCreateAccountRequestedHandlers: KeyToHandler = {};

  constructor() {
    this.onLoginHandlers = {};
    this.onLogoutHandlers = {};
    this.onLoginRequestedHandlers = {};
    this.onCreateAccountRequestedHandlers = {};
  }

  notifyLogin() {
    if (this.isLoggedIn()) {
      Object.values(this.onLoginHandlers).forEach((handler) => handler());
    }
  }

  requestLogin() {
    Object.values(this.onLoginRequestedHandlers).forEach((handler) => handler());
  }

  requestCreateAccount() {
    Object.values(this.onCreateAccountRequestedHandlers).forEach((handler) => handler());
  }

  isLoggedIn(): boolean {
    return Cookies.get(ACCESS_KEY_ID) !== undefined && Cookies.get(ACCESS_KEY_SECRET) !== undefined;
  }

  logout() {
    Cookies.remove(ACCESS_KEY_ID);
    Cookies.remove(ACCESS_KEY_SECRET);
    Object.values(this.onLogoutHandlers).forEach((handler) => handler());
  }

  registerOnLoginHandler(name: string, handler: () => void) {
    this.onLoginHandlers[name] = handler;
  }

  unregisterOnLoginHandler(name: string) {
    delete this.onLoginHandlers[name];
  }

  registerOnLogoutHandler(name: string, handler: () => void) {
    this.onLogoutHandlers[name] = handler;
  }

  unregisterOnLogoutHandler(name: string) {
    delete this.onLogoutHandlers[name];
  }

  registerOnLoginRequestedHandler(name: string, handler: () => void) {
    this.onLoginRequestedHandlers[name] = handler;
  }

  unregisterOnLoginRequestedHandler(name: string) {
    delete this.onLoginRequestedHandlers[name];
  }

  registerOnCreateAccountRequestedHandler(name: string, handler: () => void) {
    this.onCreateAccountRequestedHandlers[name] = handler;
  }

  unregisterOnCreateAccountRequestedHandler(name: string) {
    delete this.onCreateAccountRequestedHandlers[name];
  }
}

export const IPPE_LOGIN_MANAGER: IppeLoginManager = new IppeLoginManager();
