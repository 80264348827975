import {contentHash} from "./IppeUtils";

export interface Dryrun {
  parsedPipeline: IppePipelineParsedNode,
  linkedPipeline: LinkedCommandPipeline
}

export interface ParseError {
  description: string,
  startIdx: number,
  endIdx: number,
  isFatal: boolean
}

export interface IppeParsedNode {
  startIdx: number
  endIdx: number
  errors: Array<ParseError>
}

export interface IppeNameParsedNode extends IppeParsedNode {
  value: string,
}

export interface IppePipelineParsedNode extends IppeParsedNode {
  commands: Array<IppeCommandParsedNode>
}

export interface IppeCommandParsedNode extends IppeParsedNode {
  name: IppeNameParsedNode,
  namedArguments: Array<IppeNamedArgParsedNode>,
  positionedArguments: Array<IppePositionedArgParsedNode>,
}

export interface IppeValueParsedNode extends IppeParsedNode {
  value: string,
}

export interface IppeNamedArgParsedNode extends IppeParsedNode {
  name: IppeNameParsedNode,
  value: IppeValueParsedNode,
}

export interface IppePositionedArgParsedNode extends IppeParsedNode {
  position: number,
  value: string,
}


export interface LinkError {
  description: string,
  startIdx: number,
  endIdx: number,
  isFatal: Boolean
}


export interface IppeLinkedNode {
  errors: Array<LinkError>
}

export interface LinkedCommandPipeline extends IppeLinkedNode {
  commands: Array<LinkedCommand>,
  errors: Array<LinkError>
}

export interface LinkedCommand extends IppeLinkedNode {
  toolSpec?: IppeToolSpec,
  providedNamedArguments: Array<IppeNamedArgParsedNode>,
  providedPositionalArguments: Array<IppePositionedArgParsedNode>,
  derivedArguments?: Array<LinkedArgument>,
  mimeTypeIn?: string,
  mimeTypeOut?: string,
}

export interface LinkedArgument extends IppeLinkedNode {
  name: string,
  value?: any,
}


export interface IppeToolSpec {
  name: string,
  tags: Array<string>,
  description: string,
  examples: Array<ToolExampleSpec>,
// val version: String,  // TODO
// val sensitive: Boolean, // TODO
  sink: boolean,  // _can_ this command accept input?
  source: boolean,  // _can_ this command produce output?
  requiresInput: boolean, // Does this command require input?
  argSpecs: Array<IppeArgumentSpec>,
  accepts: Array<IppeMimeTypeSpec>,
  produces: Array<IppeMimeTypeSpec>,
}

export type ToolExampleSpec = {
  description: string,
  details: string | undefined,
  expression: string,
  sampleInputId: string | undefined
}

export interface ArgumentCaster {
  type: string
}

export interface IppeArgumentSpec {
  name: string,
  description: string,
  caster: ArgumentCaster,
  required: boolean,
  multi: boolean,
  positional: boolean,
  defaultsTo?: any,
  hint?: any
}

export function createContent(
  mimeType: string | undefined,
  content: ArrayBuffer | undefined
): Content {
  return {
    mimeType,
    content,
    contentHash: contentHash(mimeType, content)
  }
}

export type Content = {
  mimeType: string | undefined,
  content: ArrayBuffer | undefined,
  contentHash: number
}

export type IppeMimeTypeSpec = {
  type: string | undefined,
  subtype: string | undefined
}

export type IppeSampleInputSpec = {
  id: string,
  mimeType: string,
  // fileName: string,
  name: string
}

export type IppeCursorPosition = {
  offset: number,  // in expression
}

export interface ExecError {
  description: string,
  isFatal: boolean
}

export type AccessKeyResponse = {
  name: string,
  accessKeyId: string,
  accessKeySecret: string | undefined,
  expires: string,
  blacklisted: boolean
}

export type FavoriteResponse = {
  id: string,
  createdAt: string,
  name: string | undefined,
  expression: string
}