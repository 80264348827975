import {CopyAsComponentProps, PlainTextComponent, SnippetComponent} from "./CopyAsMisc";
import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {IPPE_HOST} from "../IppeUtils/IppeUrls";
import {VerticalStack} from "../IppeUtils/MiscComponents";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {TextField} from "@mui/material";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {IppeAccessKeyPicker} from "../IppeAccessKeys/IppeAccessKeyPickerComponent";
import {AccessKeyResponse} from "../IppeUtils/IppeTypes";


type CopyAsJavascriptComponentState = {
  functionName: string,
  accessKey: AccessKeyResponse | undefined
}

export class CopyAsJavascriptComponent extends React.Component<CopyAsComponentProps, CopyAsJavascriptComponentState> {
  constructor(props: CopyAsComponentProps) {
    super(props);

    this.state = {
      functionName: "",
      accessKey: undefined
    };

    this.onFunctionNameChange = this.onFunctionNameChange.bind(this);
  }

  onFunctionNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({functionName: event.target.value.replace(/[^a-zA-Z0-9_]/, "")});
  }

  render() {
    const functionName = this.state.functionName || "ippeFunction";
    const onAccessKeyChange = (accessKey: AccessKeyResponse) => this.setState({accessKey})

    return ce(VerticalStack, {spacing: "1.5em", style: StyleBuilder.start().size("inherit").build()},
      ce(IppeMarkdownComponent, null, createIntroSnippet()),
      ce(TextField, {label: "Function Name", onChange: this.onFunctionNameChange, value: this.state.functionName || ""}),
      ce(IppeAccessKeyPicker, {nameHint: this.state.functionName, accessKey: this.state.accessKey, onChange: onAccessKeyChange}),
      ce(IppeMarkdownComponent, {codeCopy: true},
        createBodySnippet(functionName, this.props.expression, this.props.mimeType, this.state.accessKey)));
  }
}


function createIntroSnippet() {
  return `
This snippet provides the capability defined by your Ippe expression as a javascript function.
`
}

function createBodySnippet(
  name: string,
  expression: string,
  mimeType: string | undefined,
  accessKey: AccessKeyResponse | undefined,
): string {
  const accessKeyIdValue = accessKey?.accessKeyId || "[access key id]";
  const accessKeySecretValue = accessKey?.accessKeySecret || "[access key secret]";
  const contentType = mimeType ?? "";

  return `  
~~~javascript
// body is an ArrayBuffer
export async function ${name}(body) {
  const url = "${IPPE_HOST}/api/v0/execs?expression=${encodeURIComponent(expression)}"

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': '${contentType}',
      'Access-Key-ID': '${accessKeyIdValue}',
      'Access-Key-Secret': '${accessKeySecretValue}',
    },
    body: body
  }).then((response)=>response.arrayBuffer())
}
~~~
`
}
