import {CopyAsComponentProps, PlainTextComponent, SnippetComponent} from "./CopyAsMisc";
import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {IPPE_HOST} from "../IppeUtils/IppeUrls";
import {VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {TextField} from "@mui/material";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {IppeAccessKeyPicker} from "../IppeAccessKeys/IppeAccessKeyPickerComponent";
import {AccessKeyResponse} from "../IppeUtils/IppeTypes";


type CopyAsPythonComponentState = {
  functionName: string,
  accessKey: AccessKeyResponse | undefined
}

export class CopyAsPythonComponent extends React.Component<CopyAsComponentProps, CopyAsPythonComponentState> {
  constructor(props: CopyAsComponentProps) {
    super(props);

    this.state = {
      functionName: "",
      accessKey: undefined
    };

    this.onFunctionNameChange = this.onFunctionNameChange.bind(this);
  }

  onFunctionNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({functionName: event.target.value.replace(/[^a-zA-Z0-9_]/, "")});
  }

  render() {
    const functionName = this.state.functionName || "ippe_function";
    const onAccessKeyChange = (accessKey: AccessKeyResponse) => this.setState({accessKey})

    return ce(VerticalStack, {spacing: "1.5em", style: StyleBuilder.start().size("inherit").build()},
      ce(IppeMarkdownComponent, null, createIntroSnippet()),
      ce(TextField, {label: "Function Name", onChange: this.onFunctionNameChange, value: this.state.functionName || ""}),
      ce(IppeAccessKeyPicker, {nameHint: this.state.functionName, accessKey: this.state.accessKey, onChange: onAccessKeyChange}),
      ce(IppeMarkdownComponent, {codeCopy: true, downloadCodeAs: functionName + ".py"},
        createBodySnippet(functionName, this.props.expression, this.props.mimeType, this.state.accessKey)),
      ce(IppeMarkdownComponent, {},
        createExampleSnippet(functionName)));
  }
}


function createIntroSnippet() {
  return `
This snippet provides the capability defined by your Ippe expression as a python function.  The implementation exclusively depends upon:

* python3 installation (and standard python modules)
* network-access to [${window.location.host}](${window.location.host}) 

The snippet can be invoked directly from your command-line (in which, it will operate on \`stdin\` and produce output to \`stdout\`), or dropped into an exising python project. 
`
}

function createBodySnippet(
  name: string,
  expression: string,
  mimeType: string | undefined,
  accessKey: AccessKeyResponse | undefined,
): string {
  const accessKeyIdValue = accessKey?.accessKeyId || "[access key id]"
  const accessKeySecretValue = accessKey?.accessKeySecret || "[access key secret]"

  const clientConnect = window.location.protocol === "https" ?
    `client.HTTPSConnection('${window.location.host}')` :
    `client.HTTPConnection('${window.location.host}')`;

  return `
~~~python 
#!/usr/bin/env python3

from http import client
from urllib.parse import urlencode
import sys

# body may be either a string or a bytes-like object.
#
# returns sequence of bytes.  If the bytes represent textual content, 
# the content will be utf-8.
#
# raises exception if unable to connect to ${IPPE_HOST}, or if ippe request
# returns a non-OK response.  
def ${name}(body):
  headers = {
    'Access-Key-ID' : '${accessKeyIdValue}',
    'Access-Key-Secret' : '${accessKeySecretValue}',
    'Content-Type' : '${mimeType ?? "None"}'
  }

  if isinstance(body, str):
    body = body.encode('utf8')

  url = '${"/api/v0/execs?expression=" + encodeURIComponent(expression)}'

  try:
    connection = ${clientConnect}
    connection.request('POST', url, body, headers)
    response = connection.getresponse()
    response_body = response.read()
  except Exception as e:
    raise e
  finally:
    connection.close()

  if response.status != 200:
    raise RuntimeError('Error evaluating ippe expression: ' + response_body.decode("utf8"))
  else:
    return response_body
    
if __name__ == "__main__":
  try:
    result = ${name}(sys.stdin.read())
    sys.stdout.buffer.write(result)
  except Exception as e:
    print(e, file=sys.stderr)
~~~
`
}

function createExampleSnippet(name: string) {
  return `
Example CLI invocation:

~~~
cat some_input_file | python3 ${name}.py | md5sum
~~~  
`
}
