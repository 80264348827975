export interface Range {
  start: number
  end: number
}


export interface CaptureResult {
  captureValue: string,
  captureRange: Range
  matchValue: string,
  matchRange: Range
}


export function capture(src: string, regex: RegExp, startIdx: number = 0): CaptureResult | undefined {
  const matchResult = regex.exec(src.substring(startIdx))

  if (!matchResult || !((matchResult as any).indices))
    return undefined;

  const matchValue = matchResult[0];
  const matchRange = (matchResult as any).indices[0];

  const captureValue = matchResult[1];
  const captureRange = (matchResult as any).indices[1];

  return {
    captureValue,
    captureRange: {
      start: startIdx + captureRange[0],
      end: startIdx + captureRange[1] - 1,  // -1 so that this behaves more like kotlin's capture.
    },
    matchValue,
    matchRange: {
      start: startIdx + matchRange[0],
      end: startIdx + matchRange[1] - 1,  // -1 so that this behaves more like kotlin's capture.
    },
  }
}


export function dropFirst(str: string, n: number): string {
  return str.substring(n)
}


export function isEmpty(str: string): boolean {
  return str === ""
}


export function isBlank(str: string): boolean {
  return str.trim() === ""
}


export function isLetterOrDigit(char: string): boolean {
  return (/[a-zA-Z0-9]/).test(char)
}


export function isWhitespace(str: string): boolean {
  return isEmpty(str.trim())
}

export function replaceRange(
  inStr: string,
  startIdx: number,
  length: number,
  replacement: string
): string {
  return inStr.substring(0, startIdx) + replacement + inStr.substring(startIdx + length);
}

export function maxLineLength(str: string): number {
  return Math.max(...str.split("\n").map(it => it.length));
}

export function replaceAll(
  source: string,
  from: string,
  to: string
): string {
  return source.split(from).join(to);  // LOWTODO: probably not the most efficient sulution.
}