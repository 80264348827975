import {ce, merge} from "../IppeUtils/MiscUtils";
import React from "react";
import axios from "axios";
import {VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import {
  Paper, Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {
  IPPE_CONTENT_AREA_HEIGHT,
  IPPE_LIGHT_GREY_COLOR
} from "../IppeUtils/IppeStyles";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {replace} from "../IppeUtils/MiscUtilsTs";
import {getGetFavoritesUrl, getPatchFavoriteUrl} from "../IppeUtils/IppeUrls";
import {FavoriteResponse} from "../IppeUtils/IppeTypes";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {IppeCommandViewer} from "../IppeEditor/IppeCommandViewerComponent";
import {parseIppePipelineNode} from "../IppeUtils/IppeParser";

export type IppeFavoritesProps = {
  style?: React.CSSProperties
}

type IppeFavoritesState = {
  favorites: Array<FavoriteResponse>,
}

// TODO: ability to delete a favorite
// TODO: ability to edit a favorite


export class IppeFavoritesComponent extends React.Component<IppeFavoritesProps, IppeFavoritesState> {
  constructor(props: IppeFavoritesProps) {
    super(props);

    this.onFavoriteUpdated = this.onFavoriteUpdated.bind(this);

    this.state = {
      favorites: [],
      // dialog: undefined
    };
  }

  componentDidMount() {
    axios.get(getGetFavoritesUrl()).then((response) => {
      this.setState({favorites: response.data.data})
    });
  }

  onFavoriteUpdated(favorite: FavoriteResponse) {
    this.setState({
      favorites: replace((t => t.id === favorite.id), this.state.favorites, favorite)
    })
  }

  render() {
    const rows = this.state.favorites.map((favorite) => {
      return ce(IppeFavoriteComponent, {
        key: favorite.id, favorite, onFavoriteUpdated: this.onFavoriteUpdated
      })
    });

    // TODO: nice no-favorites message.  loading message.  error message.
    return ce("div", {style: {height: IPPE_CONTENT_AREA_HEIGHT, width: "100%", overflow: "scroll"}},
      ce(VerticalStack, {style: StyleBuilder.start().vPadding("1em").hMargin("auto").width(800).build()},
        // this.state.dialog,
        ce(IppeMarkdownComponent, {codeCopy: false, style: {}},
          "lorem ipsum favorites"),  // TODO: Also, explain that input / output is not favorited
        ce(VerticalSpacer, {thickness: "1em"}),
        ce(TableContainer, {component: Paper} as any,
          ce(Table, {},
            ce(TableHead, {sx: {background: IPPE_LIGHT_GREY_COLOR}},
              ce(TableRow, {},
                ce(TableCell, {style: {minWidth: 150}}, "Name"),
                ce(TableCell, {}, "Expression"))),
            ce(TableBody, {},
              rows)))));
  }
}

type IppeFavoriteProps = {
  favorite: FavoriteResponse,
  onFavoriteUpdated: (favorite: FavoriteResponse) => void
};

function IppeFavoriteComponent(props: IppeFavoriteProps) {
  const {name, expression, id} = props.favorite;
  const parsedExpression = parseIppePipelineNode(expression);

  return ce(TableRow, {key: id},
    ce(TableCell, {style: StyleBuilder.start().singleLineEllipsizeText().width(150).build()}, name),
    ce(TableCell, {},
      ce(IppeCommandViewer, {expression, parsedExpression, style: { fontSize: "1.25em"}})));
}
