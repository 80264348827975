import {Content, createContent, ExecError, LinkError, ParseError} from "../../IppeUtils/IppeTypes";
import React from "react";
import IconButton from "@mui/material/IconButton";
import StyleBuilder from "../../IppeUtils/StyleBuilder";
import {ce} from "../../IppeUtils/MiscUtils";
import {Box, Button, Modal, Tooltip} from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {IPPE_LIGHT_GREY_COLOR, IPPE_PRIMARY_COLOR} from "../../IppeUtils/IppeStyles";
import {IppeContentComponent} from "../IppeContentComponent";
import {HorizontalStack, VerticalStack} from "../../IppeUtils/MiscComponents";
import {isMimeTypeEditable} from "../ContentEditor/ContentComponentMisc";


export type FullScreenButtonProps = {
  value: Content | undefined,
  onChange: undefined | ((value: Content) => void),
  errors: Array<ParseError | LinkError | ExecError>
}

export type FullScreenButtonState = {
  open: boolean
}

export class FullScreenButton extends React.Component<FullScreenButtonProps, FullScreenButtonState> {
  constructor(props: FullScreenButtonProps) {
    super(props);
    this.state = {open: false};
  }

  render() {
    const {mimeType, content} = this.props.value || {};
    const onSave = this.props.onChange === undefined ? undefined : (content: ArrayBuffer) => {
      const newContent = createContent(mimeType, content);

      if (this.props.onChange !== undefined && this.props.value?.contentHash !== newContent.contentHash)
        this.props.onChange(newContent)

      this.setState({open: false})
    };


    const contentModal = (mimeType && this.state.open) ? ce(ContentModal, {
      content: content || new ArrayBuffer(0),
      mimeType: mimeType,
      contentHash: undefined,
      onDismiss: () => { this.setState({open: false})},
      onSave,
      errors: this.props.errors
    }) : null

    const disabled = (mimeType === undefined) ||
      (this.props.onChange === undefined && this.props.value?.content === undefined);

    return ce("div", {},
      ce(Tooltip, {title: "Full Screen"} as any,
        ce(IconButton, {
          size: "small",
          onClick: () => this.setState({open: true}),
          disabled,
          style: StyleBuilder.start().size(30).build()
        }, ce(FullscreenIcon))),
      contentModal)
  }
}

export type ContentModalProps = {
  mimeType: string,
  content: ArrayBuffer,
  contentHash: number | undefined,
  onSave: undefined | ((content: ArrayBuffer) => void),
  onDismiss: () => void,
  errors: Array<ParseError | LinkError | ExecError>
}

export type ContentModalState = {
  content: ArrayBuffer
}

const MODAL_STYLE = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: IPPE_LIGHT_GREY_COLOR,
  border: '2px solid #000',
  borderColor: IPPE_PRIMARY_COLOR,
  boxShadow: 24,
  paddingTop: 4,
  paddingLeft: 4,
  paddingRight: 4,
  height: "75vh",
  width: "85vw"
};

export class ContentModal extends React.Component<ContentModalProps, ContentModalState> {
  constructor(props: ContentModalProps) {
    super(props);
    this.state = {content: props.content}
    this.onSave = this.onSave.bind(this);
  }

  onSave() {
    if (this.props.onSave)
      this.props.onSave(this.state.content);
  }

  render() {
    const contentComponent = ce(IppeContentComponent, {
      mimeType: this.props.mimeType,
      content: this.state.content,
      contentHash: undefined,
      onContentChange: this.props.onSave ? (content) => this.setState({content}) : undefined,
      errors: this.props.errors,
      style: {}
    })

    const buttons = this.props.onSave !== undefined && isMimeTypeEditable(this.props.mimeType) ? [
      ce(Button, {variant: "outlined", onClick: this.onSave}, "Save"),
      ce(Button, {variant: "outlined", onClick: this.props.onDismiss}, "Cancel"),
    ] : [
      ce(Button, {variant: "outlined", onClick: this.props.onDismiss}, "Dismiss"),
    ]

    const stupidHeight = "calc(75vh - 60px)";
    return ce(Modal, {open: true, onClose: this.props.onDismiss} as any,
      ce(Box, {sx: MODAL_STYLE}, ce(VerticalStack, {style: {maxHeight: stupidHeight, height: stupidHeight, width: "100%"}},
        contentComponent,
        ce(HorizontalStack,
          {spacing: 1, justifyContent: "flex-end", style: {marginTop: 10, marginBottom: 10}},
          buttons),
      )));
  }
}