import React from "react";
import axios from "axios";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {HorizontalStack, VerticalSliver, VerticalStack} from "../IppeUtils/MiscComponents";
import {
  COPY_FONT_FAMILY, IPPE_CONTENT_AREA_HEIGHT,
  IPPE_DIVIDER_COLOR,
  IPPE_LIGHT_GREY_COLOR, MONO_FONT_FAMILY,
} from "../IppeUtils/IppeStyles";
import {IppeToolSpec} from "../IppeUtils/IppeTypes";
import {Link, Outlet} from "react-router-dom";
import {getDashboardDocToolPath, getListToolsUrl} from "../IppeUtils/IppeUrls";
import {merge} from "../IppeUtils/MiscUtils";
import {sortBy} from "ramda";
import {IppeErrorComponent} from "../IppeUtils/IppeErrorComponent";
import {IppeLoadingComponent} from "../IppeUtils/IppeLoadingComponent";

const ce = React.createElement;

type IppeDocsProps = {}

type IppeDocsState = {
  tools: Array<IppeToolSpec>,
  loading: boolean,
  error: boolean
}

export class IppeDocsComponent extends React.Component<IppeDocsProps, IppeDocsState> {
  constructor(props: IppeDocsProps) {
    super(props);
    this.state = {
      tools: [],
      loading: false,
      error: false
    };
  }

  componentDidMount() {
    this.setState({loading: true})
    axios.get(getListToolsUrl())
      .then((response) => {
        this.setState({loading: false, tools: response.data.data})
      })
      .catch((ex) => {
        this.setState({loading: false, error: true})
        // TODO: handle error
        console.log(ex);
      });
  }

  render() {
    const wrapperStyle = StyleBuilder.start()
      .width("100%")
      .height(IPPE_CONTENT_AREA_HEIGHT)
      .overflowHidden()
      .build();

    const toolLink = merge(COPY_FONT_FAMILY, {
      fontSize: 18,
      marginTop: 2,
      marginBottom: 2,
    });

    const sortedTools = sortBy((spec) => spec.name, this.state.tools);
    const toolLinks = sortedTools.map((tool: IppeToolSpec) => {
      return ce(Link, {key: tool.name, to: getDashboardDocToolPath(tool.name), style: toolLink},
        "• ",
        ce("span", {style: merge(MONO_FONT_FAMILY, {fontWeight: 600})}, tool.name))
    });

    const linkStyle: React.CSSProperties = {
      fontSize: 18,
      marginTop: 4,
      marginBottom: 4,
      textTransform: "uppercase",
    };

    const links = [
      // @ts-ignore
      ce(Link, {key: "docs", to: "/docs", style: linkStyle}, "Getting Started"),
      ce(Link, {key: "foundation", to: "/docs/foundation", style: linkStyle}, "Foundation"),
      ce(Link, {key: "credentials", to: "/docs/credentials", style: linkStyle}, "Credentials"),
      ce(Link, {key: "builder", to: "/docs/builder", style: linkStyle}, "Builder"),
      ce(Link, {key: "cli", to: "/docs/cli", style: linkStyle}, "Command Line Interface (CLI)"),
      ce(Link, {key: "faq", to: "/docs/faq", style: linkStyle}, "Frequently Asked Questions"),
      ce(Link, {key: "gotchas", to: "/docs/gotchas", style: linkStyle}, "Gotchas"),
      ce("div", {key: "tools", style: linkStyle}, "Tools"),
      ...toolLinks
    ];

    const content = this.state.loading ? ce(IppeLoadingComponent) :
      this.state.error ? ce(IppeErrorComponent, {message: "Failed to load tools."}) :
        ce(Outlet);

    return ce(HorizontalStack, {style: wrapperStyle},
      ce(VerticalStack, {style: {minWidth: 320, background: IPPE_LIGHT_GREY_COLOR, padding: 16, overflow: "scroll"}},
        links),
      ce(VerticalSliver, {thickness: 1, color: IPPE_DIVIDER_COLOR}),
      content);
  }
}