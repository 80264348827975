import {ce, merge} from "../IppeUtils/MiscUtils";
import React from "react";
import axios from "axios";
import {HorizontalSpacer, HorizontalStack, VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import {
  Button,
  Paper, Switch,
  Table,
  TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {
  IPPE_CONTENT_AREA_HEIGHT,
  IPPE_LIGHT_GREY_COLOR,
  MONO_FONT_FAMILY
} from "../IppeUtils/IppeStyles";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {replace} from "../IppeUtils/MiscUtilsTs";
import {IppeCreateAccessKeyComponent} from "./IppeCreateAccessKeyComponent";
import {getGetAccessKeysUrl, getPatchAccessKeyUrl} from "../IppeUtils/IppeUrls";
import {AccessKeyResponse} from "../IppeUtils/IppeTypes";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";

export type IppeAccessKeysProps = {
  style?: React.CSSProperties
}

type IppeAccessKeysState = {
  accessKeys: Array<AccessKeyResponse>,
  dialog: React.ReactElement | undefined
}

export class IppeAccessKeysComponent extends React.Component<IppeAccessKeysProps, IppeAccessKeysState> {
  constructor(props: IppeAccessKeysProps) {
    super(props);

    this.onAddAccessKeyClicked = this.onAddAccessKeyClicked.bind(this);
    this.onAccessKeyUpdated = this.onAccessKeyUpdated.bind(this);

    this.state = {
      accessKeys: [],
      dialog: undefined
    };
  }

  componentDidMount() {
    axios.get(getGetAccessKeysUrl()).then((response) => {
      this.setState({accessKeys: response.data.data})
    });
  }

  onAddAccessKeyClicked() {
    this.setState({
      dialog: ce(IppeCreateAccessKeyComponent, {
        nameHint: undefined,
        closeOnCreate: false,
        onAccessKeyAdded: (accessKey: AccessKeyResponse) => this.setState({accessKeys: this.state.accessKeys.concat(accessKey)}),
        onClose: () => this.setState({dialog: undefined})
      })
    })
  }

  onAccessKeyUpdated(accessKey: AccessKeyResponse) {
    this.setState({
      accessKeys: replace((t => t.accessKeyId === accessKey.accessKeyId), this.state.accessKeys, accessKey)
    })
  }

  render() {
    const rows = this.state.accessKeys.map((accessKey) => {
      return ce(IppeAccessKeyComponent, {
        key: accessKey.accessKeyId, accessKey, onAccessKeyUpdated: this.onAccessKeyUpdated
      })
    });

    // TODO: nice "no access keys" message.  loading message.  error message.
    return ce("div", {style: {height: IPPE_CONTENT_AREA_HEIGHT, width: "100%", overflow: "scroll"}},
      ce(VerticalStack, {style: StyleBuilder.start().vPadding("3em").hMargin("auto").width(700).build()},
        this.state.dialog,
        ce(HorizontalStack, {alignItems: "center", style: {width: "inherit"}},
          ce("span", {style: {fontSize: "1.5em"}}, "Access Keys"),
          ce("div", {style: {flexGrow: 1}}),
          ce(Button, {
            size: "small",
            onClick: this.onAddAccessKeyClicked,
            variant: "outlined",
            endIcon: ce(AddIcon),
          }, "Create")),
        ce(IppeMarkdownComponent, {codeCopy: false, style: {}},
          "Create and manage *execution* credentials (access keys) used for invoking ippe functions from less-secure environments.  See [documentation](/docs/credentials) for more information."),
        ce(VerticalSpacer, {thickness: "1em"}),
        ce(TableContainer, {component: Paper} as any,
          ce(Table, {},
            ce(TableHead, {sx: {background: IPPE_LIGHT_GREY_COLOR}},
              ce(TableRow, {},
                ce(TableCell, {style: {minWidth: 150}}, "Name"),
                ce(TableCell, {}, "Access Key ID"),
                ce(TableCell, {}, "Expires"),
                ce(TableCell, {}, "Deactivated"))),
            ce(TableBody, {},
              rows)))));
  }
}

type IppeAccessKeyProps = {
  accessKey: AccessKeyResponse,
  onAccessKeyUpdated: (accessKey: AccessKeyResponse) => void
};

function IppeAccessKeyComponent(props: IppeAccessKeyProps) {
  const {name, accessKeyId, expires, blacklisted} = props.accessKey;

  const onToggleBlacklist = () => {
    // TODO: alert on failure.
    // TODO: show spinner.
    axios.patch(getPatchAccessKeyUrl(accessKeyId), {blacklist: !blacklisted}).then((result) => {
      props.onAccessKeyUpdated(result.data)
    }).catch(error => {
      console.log(error);
    });
  }

  const expiresDate = new Date(expires);
  return ce(TableRow, {key: accessKeyId},
    ce(TableCell, {style: StyleBuilder.start().singleLineEllipsizeText().width(200).build()}, name),
    ce(TableCell, {style: StyleBuilder.start(MONO_FONT_FAMILY).singleLineEllipsizeText().width(150).build()}, accessKeyId),
    ce(TableCell, {}, expiresDate.toDateString()),
    ce(TableCell, {}, ce(Switch, {checked: blacklisted, onChange: onToggleBlacklist})));
}
