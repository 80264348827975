import React from "react";
import {Autocomplete, TextField} from "@mui/material";
import {ce, merge} from "../../IppeUtils/MiscUtils";
import {TextPlainContentComponent} from "./TextPlainContentComponent";
import {UnknownContentComponent} from "./UnknownContentComponent";
import {ImageContentComponent} from "./ImageContentComponent";
import {none} from "ramda";
import {TextHtmlContentComponent} from "./TextHtmlContentComponent";
import {GeneralCodeContentComponent} from "./GeneralCodeContentComponent";
import {MIME_TYPES} from "../../IppeUtils/MimeUtils";
import {Centered} from "../../IppeUtils/MiscComponents";


export type MimeTypePickerComponentProps = {
  value: string | null | undefined,
  onChange: (value: string) => void,
  style: React.CSSProperties
}

const CONTENT_TYPES: Array<string> = [
  "",
  "text/plain",
  "text/csv",
  "application/json",
  "application/x-ndjson",
  // "text/html",
  "application/xml",
  "application/x-yaml",
  "image/jpg",
  "image/jpeg",
  "image/png",
]
// const CONTENT_TYPES = [""].concat(MIME_TYPES.map(it=>it.mt));

export const MimeTypePickerComponent: React.FC<MimeTypePickerComponentProps> = function ({value, onChange, style}) {
  const options = (value && none(it => it === value, CONTENT_TYPES)) ?
    CONTENT_TYPES.concat(value) : CONTENT_TYPES;

  return ce(Autocomplete, {
    disablePortal: true,
    id: "mime-type-picker",
    options,
    renderInput: (params) => ce(TextField, merge(params, {label: "mime-type"})),
    value: value || "",  // need to pass "" here if we want this to be a 'controlled' component.
    onChange: (e: any, newValue: any) => {
      onChange(newValue)
    },
    style: style
  });
}

export type ContentComponentProps = {
  style: React.CSSProperties,
  mimeType: string,
  content: ArrayBuffer,
  // contentHash: number,
  onContentChange: undefined | ((value: ArrayBuffer) => void)
}


// LOWTODO: In a perfect world, the CONTET_CONTENTS would themselves indicated whether they supported edit,
// but I'm not sure how best to reconcile with them being react components.
const MIME_TYPE_EDITABLE: Array<string> = [
  "text/plain",
  "text/csv",
  "application/x-ndjson",
  "application/x-ldjson",
  "application/ndjson",
  "application/ldjson",
  "application/json",
  "application/x-yaml",
  "application/yaml",
  "application/xml",
];

interface KeyToContentComponentMap {
  [key: string]: React.ComponentClass<ContentComponentProps, any>
}

const CONTENT_COMPONENT_FACTORY: KeyToContentComponentMap = {
  "text/plain": TextPlainContentComponent,
  "text/csv": GeneralCodeContentComponent,
  "text/html": TextHtmlContentComponent,  // TODO - needs to be able to validate
  "application/x-ndjson": GeneralCodeContentComponent,
  "application/x-ldjson": GeneralCodeContentComponent,
  "application/ndjson": GeneralCodeContentComponent,
  "application/ldjson": GeneralCodeContentComponent,
  "application/json": GeneralCodeContentComponent,
  "application/x-yaml": GeneralCodeContentComponent,
  "application/yaml": GeneralCodeContentComponent,
  "application/xml": GeneralCodeContentComponent,
  "image/bmp": ImageContentComponent,
  "image/jpeg": ImageContentComponent,
  "image/jpg": ImageContentComponent,
  "image/png": ImageContentComponent,
  "image/svg+xml": ImageContentComponent,
  "image/tiff": ImageContentComponent,
  "image/webp": ImageContentComponent,
}

export function getContentComponentForMimeType(
  mimeType: string | null | undefined
): React.ComponentClass<ContentComponentProps, {}> {
  let cc = null;

  if (mimeType)
    cc = CONTENT_COMPONENT_FACTORY[mimeType]

  return cc || UnknownContentComponent;
}

export function isMimeTypeEditable(
  mimeType: string | null | undefined
): boolean {
  return mimeType ? MIME_TYPE_EDITABLE.find(it => it === mimeType) !== undefined : false;
}