import React from "react";
import {ButtonGroup, IconButton} from "@mui/material";
import {ce} from "./MiscUtils";
import {ZoomIn, ZoomOut} from "@mui/icons-material";
import {HorizontalStack, VerticalSliver} from "./MiscComponents";
import {IPPE_PRIMARY_COLOR} from "./IppeStyles";


export type ZoomComponentProps = {
  numZoomLevels: number,
  currLevel: number,
  onZoomLevelChange: (level: number) => void,
  style: React.CSSProperties
}


export class ZoomComponent extends React.Component<ZoomComponentProps, {}> {
  render() {
    const zoomInDisabled = this.props.currLevel >= (this.props.numZoomLevels - 1);
    const zoomOutDisabled = this.props.currLevel === 0;

    const enabledStyle = { color: IPPE_PRIMARY_COLOR};
    const disabledStyle = {};

    return ce(HorizontalStack, {variant: "contained", style: this.props.style},
      ce(IconButton, {
          disabled: zoomOutDisabled,
          onClick: () => { this.props.onZoomLevelChange(this.props.currLevel - 1)}
        },
        ce(ZoomOut, {style: zoomOutDisabled ? disabledStyle : enabledStyle})),
      ce(VerticalSliver),
      ce(IconButton, {
          disabled: zoomInDisabled,
          onClick: () => { this.props.onZoomLevelChange(this.props.currLevel + 1)}
        },
        ce(ZoomIn, {style: zoomInDisabled ? disabledStyle : enabledStyle})))
  }
}