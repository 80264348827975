import {ExecError, LinkError, ParseError} from "../IppeUtils/IppeTypes";
import React from "react";
import {Alert} from "@mui/material";
import {merge} from "../IppeUtils/MiscUtils";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";

const ce = React.createElement;

export type IppeErrorComponentProps = {
  error: ParseError | LinkError | ExecError,
  style: React.CSSProperties
}

export class IppeErrorComponent extends React.Component<IppeErrorComponentProps, {}> {
  constructor(props: IppeErrorComponentProps) {
    super(props);
  }

  render() {
    const severity = this.props.error.isFatal ? "error" : "warning";

    // The alert has padding, so we use the outer-div to more easily size / constrain it.
    const style = merge({
      width: 320,
      maxWidth: 320,
      textAlign: "left",
    }, this.props.style);

    return ce("div", {style},
      ce(Alert, {severity}, ce(IppeMarkdownComponent, {compact: true, codeCopy: false}, this.props.error.description)));
  }
}