import {base64Encode, encodeToBuffer} from "./ArrayBufferUtils";
import {AxiosResponse} from "axios";


export function hashBuff(
  buff: ArrayBuffer | undefined
): number {
  const str = `${base64Encode(buff ?? encodeToBuffer(""))}`
  return hashString(str);
}

export function hashString(
  str: string | undefined
): number {
  let hash = 0;

  if (str === undefined || str.length === 0)
    return hash;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
}

export function ellipsize(input: string, max: number): string {
  return input.length > max ?
    `${input.substring(0, Math.max(0, max - 3))}...` :
    input;
}

export function readablizeBytes(contentLengthBytes: number): string {
  if (contentLengthBytes < 1024) {
    return `${contentLengthBytes} B`;
  } else if (contentLengthBytes < 1024 * 1024) {
    return `~${Math.floor(contentLengthBytes / 1024)} KB`;
  } else if (contentLengthBytes < 1024 * 1024 * 1024) {
    return `~${Math.floor(contentLengthBytes / 1024 / 1024)} MB`;
  } else if (contentLengthBytes < 1024 * 1024 * 1024 * 1024) {
    return `~${Math.floor(contentLengthBytes / 1024 / 1024 / 1024)} GB`;
  } else {
    return `~${Math.floor(contentLengthBytes / 1024 / 1024 / 1024 / 1024)} TB`;
  }
}

export function mbAsBytes(mb: number) {
  return mb * 1024 * 1024;
}

export function getMimeTypeFromAxiosResponse(response: AxiosResponse): string | undefined {
  const contentType = response.headers["content-type"];
  return contentType ? contentType.split(";")[0].toLowerCase() : undefined;
}

export function escapeForShell(str: string): string {
  return '"' + str.replace(/"/g, '\\"') + '"';  // TODO:
}


export function replace<T>(pred: (t: T) => boolean, arr: Array<T>, sub: T): Array<T> {
  return arr.map(elt => pred(elt) ? sub : elt);
}


export function updateURLParameter(
  rawUrl: string,
  param: string,
  paramVal: string | undefined
): string {
  const url = new URL(rawUrl);

  url.searchParams.delete(param)

  if (paramVal !== undefined)
    url.searchParams.append(param, paramVal)

  return url.toString()
}

export function removeURLParameter(
  rawUrl: string,
  param: string
): string {
  const url = new URL(rawUrl);
  url.searchParams.delete(param)
  return url.toString()
}

export function createQueryStringFromParams(params: Map<string, string | undefined>): string {
  return Array.from(params.entries())
    .filter(entry=>entry[1] !== undefined)
    .map( (entry)=> `${entry[0]}=${encodeURIComponent(entry[1]!!)}`)
    .join("&")
}