import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {Modal} from "@mui/material";
import {IPPE_LOGIN_MANAGER} from "./IppeLoginManager";
import {IppeLoginComponent} from "./IppeLoginComponent";
import {IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import {IppeCreateAccountComponent} from "./IppeCreateAccountComponent";


export type IppeLoginCreateProps = {};

type IppeLoginCreateState = {
  create: boolean,
  open: boolean
};

export class IppeLoginCreateModal extends React.Component<IppeLoginCreateProps, IppeLoginCreateState> {
  constructor(props: IppeLoginCreateProps) {
    super(props);

    this.state = {
      create: false,
      open: false
    };
  }

  componentDidMount() {
    IPPE_LOGIN_MANAGER.registerOnLoginRequestedHandler("ilcm", () => this.setState({open: true, create: false}))
    IPPE_LOGIN_MANAGER.registerOnCreateAccountRequestedHandler("ilcm", () => {
      console.log("FFFFF")
      this.setState({open: true, create: true})
    })
  }

  componentWillUnmount() {
    IPPE_LOGIN_MANAGER.unregisterOnLoginRequestedHandler("ilcm")
    IPPE_LOGIN_MANAGER.unregisterOnCreateAccountRequestedHandler("ilcm")
  }

  render() {
    const style: React.CSSProperties = {
      position: 'absolute' as 'absolute',
      background: "white",
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: "scroll",
      border: '2px solid #000',
      borderColor: IPPE_PRIMARY_COLOR,
      paddingBottom: "1em"
    };

    const onDismiss = () => this.setState({open: false, create: false})

    // @ts-ignore
    return ce(Modal, {
        open: this.state.open,
        onClose: () => this.setState({open: false})
      },
      ce("div", {style},
        this.state.create ?
          ce(IppeCreateAccountComponent, {onDismiss}) : ce(IppeLoginComponent, {onDismiss})));
  }
}