import React from "react";
import {ce, merge} from "./MiscUtils";
import {CODE_FONT_FAMILY} from "./IppeStyles";

export type LightMarkupProps = {
  markup: string,
  style?: React.CSSProperties
}

/**
 * Okay...  So this exists because lots tool spec fields contain markdown-ish backticks to indicate
 * 'code' / 'preformat'.  I could use a full markdown renderer, but doing so is a bit heavy-weight
 * for my needs, and results and decent-amounts of code to get things styled the way I want.  Easier
 * to just code up a really light-weight markup component.
 */
export const LightMarkupComponent: React.FC<LightMarkupProps> = function ({markup, style}) {
  const codeStyle = merge(CODE_FONT_FAMILY, {background: "#00000020"});
  const spans: Array<React.ReactElement> = []

  // LOWTODO: this implementation is just kind of lazy.
  let inCode = false;

  let buff = ""
  for (let idx = 0; idx < markup.length; ++idx) {
    const currChar = markup[idx];

    if (currChar === '\n' && !inCode) {
      spans.push(ce("span", {key: idx}, buff));
      buff = ""

      spans.push(ce("div", { style : { height: ".25em"}}))
    // } else if (currChar === '\\') {
    //   // if the current char is a \, then the next character is escaped.  So we drop the 'escape', and add
    //   // the next character to the buff.  We artificially increment idx so as not to "revisit" the escaped
    //   // char on the next loop.
    //   idx = idx + 1;
    //   buff += (idx < markup.length) ? markup[idx] : "";
    } else if (currChar === '`' && inCode) {
      spans.push(ce("span", {key: idx, className: "ippe-markdown-code-style"}, buff));
      inCode = false;
      buff = ""
    } else if (currChar === '`' && !inCode) {
      spans.push(ce("span", {key: idx}, buff));
      inCode = true;
      buff = ""
    } else {
      buff += currChar;
    }
  }

  if (inCode && buff.length > 0) {
    spans.push(ce("span", {key: "end-cap", className: "ippe-markdown-code-style"}, buff));
  } else if (!inCode && buff.length > 0) {
    spans.push(ce("span", {key: "end-cap"}, buff));
  }

  return ce("span", {style: merge({lineHeight: "1.35em"}, style)}, spans);
}