import React from "react";
import {ce} from "../../IppeUtils/MiscUtils";
import {ContentComponentProps} from "./ContentComponentMisc";
import {IppeMarkdownComponent} from "../../IppeUtils/IppeMarkdownComponent";
import {Alert} from "@mui/material";

export class UnknownContentComponent extends React.Component<ContentComponentProps, {}> {
  render() {
    return ce(Alert, {severity: "warning"},
      ce(IppeMarkdownComponent, {compact: true, codeCopy: false},
        `Unable to preview content with mime-type \`${this.props.mimeType}\`.`));
  }
}