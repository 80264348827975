import React from "react";
import axios from "axios";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {HorizontalStack, VerticalSliver, VerticalStack} from "../IppeUtils/MiscComponents";
import {IPPE_DIVIDER_COLOR, IPPE_LIGHT_GREY_COLOR} from "../IppeUtils/IppeStyles";
import {IppeToolSpec} from "../IppeUtils/IppeTypes";
import {Outlet} from "react-router-dom";
import {IppeLoadingComponent} from "../IppeUtils/IppeLoadingComponent";
import {IppeErrorComponent} from "../IppeUtils/IppeErrorComponent";

const ce = React.createElement;

type IppeDocsMarkdownProps = {
  mdUri: string
}

type IppeDocsMarkdownState = {
  content: string | undefined,
  error: boolean
}

export class IppeDocsMarkdownComponent extends React.Component<IppeDocsMarkdownProps, IppeDocsMarkdownState> {
  constructor(props: IppeDocsMarkdownProps) {
    super(props);
    this.state = {
      content: undefined,
      error: false
    };
  }

  componentDidMount() {
    axios.get(this.props.mdUri)
      .then(result => {
        this.setState({content: result.data})
      })
      .catch((ex) => {
        this.setState({error: true})
        console.log(ex);
      });
  }

  render() {
    const content = this.state.content === undefined && !this.state.error ? ce(IppeLoadingComponent) :
      this.state.error ? ce(IppeErrorComponent, {message: "Failed to load content."}) :
        ce(IppeMarkdownComponent, {codeCopy: true, style: {padding: 16, overflow: "scroll"}}, this.state.content);

    return content
  }
}