import React from "react";
import {ce} from "./MiscUtils";
import {Centered} from "./MiscComponents";
import {IPPE_DIVIDER_COLOR} from "./IppeStyles";
import StyleBuilder from "./StyleBuilder";

// Note: this expects parent view to be a "positioned" component.
export type WarningBannerProps = {};
export function WarningBanner(props: React.PropsWithChildren<WarningBannerProps>) {
  return ce(Centered, {
    style: StyleBuilder.start({
      position: "absolute",
      background: "#fff4e5",
      color: "rgb(102, 60, 0)",
      opacity: 0.90,
      height: 23,
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
      fontSize: ".75em",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
    }).bottomBorder("rgb(102, 60, 0)", .5).build()
  }, props.children);
}