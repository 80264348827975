import React from "react";
import DownloadIcon from '@mui/icons-material/Download';
import {ce, download} from "./MiscUtils";
import {AckingButton} from "./AckingButton";


export type DownloadButtonProps = {
  content: string
  filename: string
  compact?: boolean | undefined
  style: React.CSSProperties
  secondaryColor?: boolean | undefined
}

export class DownloadButton extends React.Component<DownloadButtonProps, {}> {
  constructor(props: DownloadButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(): Promise<void> {
    return new Promise((resolve, reject) => {
      const blob = new Blob([this.props.content]);
      const url = URL.createObjectURL(blob);
      download(url, this.props.filename);
      URL.revokeObjectURL(url);

      resolve();
    })
  }

  render() {
    const color = this.props.secondaryColor ? "secondary" : "primary"

    return ce(AckingButton, {
      copy: "Download",
      icon: DownloadIcon,
      disabled: this.props.content === undefined,
      color: color,
      onClick: this.onClick,
      style: { width: 130}
    })
  }
}