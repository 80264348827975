import React from "react";
import axios from "axios";
import {ce, merge} from "../IppeUtils/MiscUtils";
import {Autocomplete, Button, TextField} from "@mui/material";
import {IppeCreateAccessKeyComponent} from "./IppeCreateAccessKeyComponent";
import {HorizontalSpacer, HorizontalStack} from "../IppeUtils/MiscComponents";
import AddIcon from '@mui/icons-material/Add';
import {getGetAccessKeysUrl} from "../IppeUtils/IppeUrls";
import {AccessKeyResponse} from "../IppeUtils/IppeTypes";


export type IppeAccessKeyPickerProps = {
  nameHint: string | undefined,
  accessKey: AccessKeyResponse | undefined,
  onChange: (accessKey: AccessKeyResponse) => void
}

type IppeAccessKeyPickerState = {
  accessKeys: Array<AccessKeyResponse>,
  dialog: React.ReactElement | undefined
}

export class IppeAccessKeyPicker extends React.Component<IppeAccessKeyPickerProps, IppeAccessKeyPickerState> {
  constructor(props: IppeAccessKeyPickerProps) {
    super(props);

    this.onAddAccessKeyClicked = this.onAddAccessKeyClicked.bind(this);

    this.state = {
      accessKeys: [],
      dialog: undefined
    };
  }

  componentDidMount() {
    axios.get(getGetAccessKeysUrl()).then((response) => {
      this.setState({accessKeys: response.data.data})
    });
  }

  onAddAccessKeyClicked() {
    this.setState({
      dialog: ce(IppeCreateAccessKeyComponent, {
        nameHint: this.props.nameHint,
        closeOnCreate: true,
        onAccessKeyAdded: (accessKey: AccessKeyResponse) => {
          this.setState({accessKeys: this.state.accessKeys.concat(accessKey)})
          this.props.onChange(accessKey);
        },
        onClose: () => this.setState({dialog: undefined})
      })
    })
  }

  render() {
    return ce(HorizontalStack, {style: {width: "100%"}},
      this.state.dialog,
      ce(Autocomplete, {
        disablePortal: true,
        id: "access-key-picker",
        options: this.state.accessKeys,
        getOptionLabel: (accessKey: any) => accessKey.name,
        renderInput: (params) => ce(TextField, merge(params, {label: "Execution Access Key"})),
        value: this.props.accessKey,
        onChange: (e: any, newValue: any) => this.props.onChange(newValue),
        style: {flexGrow: 1}
      }),
      ce(HorizontalSpacer),
      ce(Button, {
        onClick: this.onAddAccessKeyClicked,
        variant: "outlined",
        endIcon: ce(AddIcon),
      }, "Create"));
  }
}
