import {ce} from "../IppeUtils/MiscUtils";
import React from "react";
import StyleBuilder from "../IppeUtils/StyleBuilder";

export class IppeUnderConstructionComponent extends React.Component<any, any> {
  render() {
    return ce("div", {
        style: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }
      },
      ce("img", {src: "/under_construction.svg", style: StyleBuilder.start().size(200).build()}));
  }
}