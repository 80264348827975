import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {Centered, HorizontalSliver, HorizontalStack, VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import {
  IPPE_CONTENT_AREA_HEIGHT,
  IPPE_LIGHT_GREY_COLOR,
  IPPE_PRIMARY_COLOR,
  MONO_FONT_FAMILY
} from "../IppeUtils/IppeStyles";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import ImageCarousel from "../IppeUtils/ImageCarousel";


const DESCRIPTION = `
Ippe is a collection of cloud-hosted tools that can be composed into pipelines and invoked from
 just about any platform capable of issuing HTTP requests.  
 
Ippe tools cover a wide range of use cases, including image manipulation, visualization, data 
 format conversion, computer vision, and more.
 
Ippe tools can be seamlessly incorporated into shell scripts & pipelines via the ippe CLI, or
executed from your programming-language of choice.   
`

const IMAGE_CAROUSEL: Array<string> = [
  "./home/examples/plot-pins.webp",
  "./home/examples/terminal.webp",
  "./home/examples/flip-image.webp"
]

const LOGOS: Array<string> = [
  "./home/logos/one.webp",
  "./home/logos/two.webp",
  "./home/logos/three.webp",
  "./home/logos/four.webp",
  "./home/logos/five.webp",
  "./home/logos/six.webp",
];

export class IppeHome extends React.Component<{}, {}> {
  render() {
    const background = ce("div", {
      style: {
        overflow: "hidden",
        position: "absolute",
        background: IPPE_LIGHT_GREY_COLOR,
        height: IPPE_CONTENT_AREA_HEIGHT,
        width: "100%"
      }
    }, ce("div", {
      style: {
        marginTop: 300,
        color: "white",
        fontSize: 700,
        fontFamily: MONO_FONT_FAMILY,
        marginLeft: 25
      }
    }, "|ppe>"))

    const logosCopyStyle = {fontSize: ".75em", color: "darkgray"};

    const stack = ce(VerticalStack, {
        style: {
          height: IPPE_CONTENT_AREA_HEIGHT,
          position: "absolute",
          width: "100%"
        }
      },
      ce(Centered, {style: {overflow: "scroll"}},
        ce(VerticalSpacer, {thickness: "3.5em"}),
        ce("div", {
          style: {
            fontFamily: MONO_FONT_FAMILY.fontFamily,
            fontWeight: 500,
            fontSize: "3.75em"
          }
        }, "Cloud-hosted tools,"),
        ce(VerticalSpacer, {thickness: "2.25em"}),
        ce("div", {
          style: {
            /*fontWeight: 600,*/
            fontFamily: MONO_FONT_FAMILY.fontFamily,
            fontSize: "3.5em",
            color: IPPE_PRIMARY_COLOR
          }
        }, "executable everywhere."),
        ce(VerticalSpacer, {thickness: "2em"}),
        // ce("div", { style: { width: 400, minHeight: 1, background: IPPE_PRIMARY_COLOR}}),
        ce(IppeMarkdownComponent, {
          style: {
            fontSize: "1em",
            width: 690,
          }
        }, DESCRIPTION),
        // ce(VerticalSpacer, {thickness: "1em"}),
        ce(ImageCarousel, {images: IMAGE_CAROUSEL}),
        createImageGrid(),
        ce(VerticalSpacer),
        ce("div", {style: logosCopyStyle},
          "Logos by ",
          ce("a", {href: "https://openai.com/dall-e-2" }, "dall·e"),
          " to look legit."),
        ce(VerticalSpacer, {thickness: "4em"}),
      ));

    return ce("div", {style: {position: "relative"}},
      background,
      stack);
  }
}


function createImageGrid(): React.ReactElement {
  const logos = LOGOS.map((logo) => {
    return ce("img", {src: logo, style: {width: "auto", maxHeight: 75}});
  })

  return ce(VerticalStack, {spacing: 2},
    ce(HorizontalStack, {spacing: 2}, logos[0], logos[1], logos[3], logos[4], logos[5]));
}