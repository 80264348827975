import {Content, createContent, IppeSampleInputSpec} from "../../IppeUtils/IppeTypes";
import React from "react";
import IconButton from "@mui/material/IconButton";
import StyleBuilder from "../../IppeUtils/StyleBuilder";
import {ce} from "../../IppeUtils/MiscUtils";
import {
  Avatar,
  List, ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
  PopoverProps, Tooltip
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import axios from "axios";
import {getSampleContentUrl, getSamplesListUrl} from "../../IppeUtils/IppeUrls";
import {sortBy, uniq} from "ramda";
import {getMimeTypeFromAxiosResponse} from "../../IppeUtils/MiscUtilsTs";
import {updateSampleContentIdentifierInUrl} from "../IppeSampleContentUtils";


export type SampleContentButtonProps = {
  mimeType: string | undefined
  onChange: ((value: Content, reload?: boolean) => void),
}

export type SampleContentButtonState = {
  anchorEl: HTMLButtonElement | undefined,
  samples: Array<IppeSampleInputSpec>
}

export class SampleContentButton extends React.Component<SampleContentButtonProps, SampleContentButtonState> {
  constructor(props: SampleContentButtonProps) {
    super(props);
    this.state = {anchorEl: undefined, samples: []};
    this.onSampleSelected = this.onSampleSelected.bind(this);
  }

  componentDidMount() {
    axios.get(getSamplesListUrl()).then((response) => {
      this.setState({samples: response.data.data})
    });
  }

  onSampleSelected(sample: IppeSampleInputSpec) {
    this.setState({anchorEl: undefined});

    const sampleInputUrl = getSampleContentUrl(sample.id);
    axios.get(sampleInputUrl, {responseType: "arraybuffer"}).then(response => {
      updateSampleContentIdentifierInUrl(sample.id);
      this.props.onChange(createContent(getMimeTypeFromAxiosResponse(response), response.data), true);
    });
  }

  render() {
    const popoverProps: PopoverProps = {
      open: this.state.anchorEl !== undefined,
      onClose: () => {this.setState({anchorEl: undefined})},
      anchorEl: this.state.anchorEl || null,
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      }
    };

    return ce("div", {},
      ce(Tooltip, {title: "Load Test Input"} as any,
        ce(IconButton, {
          size: "small",
          onClick: (evt) => this.setState({anchorEl: evt.currentTarget}),
          style: StyleBuilder.start().size(30).build()
        }, ce(AutoAwesomeIcon))),
      ce(Popover, popoverProps,
        ce(SampleContentPicker, {samples: this.state.samples, onSampleSelected: this.onSampleSelected})))
  }
}

type SampleContentPickerProps = {
  samples: Array<IppeSampleInputSpec>,
  onSampleSelected: (sample: IppeSampleInputSpec) => void
};

function SampleContentPicker(props: SampleContentPickerProps) {
  const mimeTypes = sortBy((it) => it, uniq(props.samples.map(it => it.mimeType)));

  const items = mimeTypes.flatMap(mimeType => {
    const samples = props.samples
      .filter((sample) => (sample.mimeType === mimeType))
      .map((sample) => {
        const onClick = () => props.onSampleSelected(sample);
        const image = sample.mimeType.startsWith("image/") ?
          ce(ListItemAvatar, {}, ce(Avatar, {variant: "rounded", src: getSampleContentUrl(sample.id)})) :
          null;

        return ce(ListItemButton, {key: sample.id, onClick},
          image,
          ce(ListItemText, {}, sample.name))
      })
    return [ce(ListSubheader, {}, mimeType), samples];
  })

  return ce(List, {style: {maxHeight: 320, overflowY: "scroll", paddingTop: 0}}, ...items);
}
