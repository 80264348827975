import {useState} from "react";
import axios from "axios";
import {ce} from "../IppeUtils/MiscUtils";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import moment from "moment/moment";
import {AccessKeyResponse} from "../IppeUtils/IppeTypes";
import {getPostAccessKeysUrl} from "../IppeUtils/IppeUrls";
import {VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {TextCopyFieldComponent} from "../IppeUtils/TextCopyFieldComponent";

export type IppeCreateAccessKeyProps = {
  nameHint: string | undefined,
  closeOnCreate: boolean,
  onAccessKeyAdded: (accessKey: AccessKeyResponse) => void,
  onClose: () => void
};

export function IppeCreateAccessKeyComponent(props: IppeCreateAccessKeyProps) {
  const [name, setName] = useState(props.nameHint || "");
  const [lifespanMins, setLifespanMins] = useState(60 * 24 * 365);
  const [accessKey, setAccessKey] = useState<AccessKeyResponse | undefined>(undefined);

  const onSave = () => {
    // TODO: need to show a spinner.
    axios.post(getPostAccessKeysUrl(), {
      name, lifespanMins
    }).then((response) => {
      const accessKey = response.data;
      setAccessKey(accessKey);
      props.onAccessKeyAdded(accessKey);

      if (props.closeOnCreate)
        props.onClose();

    }).catch((error) => {
      // TODO: error handling
      console.log(error)
    })
  };

  // LOWTODO: lazy implementation.
  if (accessKey === undefined) {
    return ce(Dialog, {open: true, onClose: props.onClose},
      ce(DialogTitle, {}, "Create Access Key"),
      ce(DialogContent, {},
        ce(TextField, {
          autoFocus: true,
          value: name,
          margin: "dense",
          fullWidth: true,
          variant: "standard",
          label: "Name",
          onChange: (evt: any) => setName(evt.target.value),
          style: {minWidth: 320},
        }),
        ce(TextField, {
          autoFocus: true,
          value: lifespanMins,
          margin: "dense",
          fullWidth: true,
          variant: "standard",
          type: "number",
          label: "Lifespan (mins)",
          onChange: (evt: any) => setLifespanMins(Number(evt.target.value)),
          helperText: moment.duration(lifespanMins * 60 * 1000).humanize(),
          style: {minWidth: 320},
        })),
      ce(DialogActions, {},
        ce(Button, {onClick: props.onClose}, "Cancel"),
        ce(Button, {onClick: onSave}, "Save")));
  } else {
    return ce(Dialog, {open: true, onClose: props.onClose},
      ce(DialogTitle, {}, "Create Access Key"),
      ce(DialogContent, {},
        ce(VerticalStack, {},
          ce(IppeMarkdownComponent, {codeCopy: false}, "Your access keys have been created.  Be sure to copy the `ACCESS_KEY_SECRET` before closing this dialog; it will not be presented again."),
          ce(VerticalSpacer),
          ce(TextCopyFieldComponent, {label: "Access Key ID", value: accessKey.accessKeyId}),
          ce(VerticalSpacer),
          ce(TextCopyFieldComponent, {label: "Access Key Secret", value: accessKey.accessKeySecret!!})
        ),
        ce(VerticalSpacer)),
      ce(DialogActions, {},
        ce(Button, {onClick: props.onClose}, "Close")));
  }
}
