import '../App.css';
import React from "react";
import {VerticalStack} from "../IppeUtils/MiscComponents";
import {IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import StyleBuilder from '../IppeUtils/StyleBuilder';
import {Outlet} from "react-router-dom";
import IppeAppBarComponent from "./IppeAppBar";
import {IppeLoginCreateModal} from "../IppeLogin/IppeLoginCreateModal";

const ce = React.createElement;

type IppeAppProps = {}

type IppeAppState = {}

class IppeApp extends React.Component<IppeAppProps, IppeAppState> {
  // componentDidMount() {
  //   IPPE_LOGIN_MANAGER.registerOnLogoutHandler("ippe-app", () => {})
  // }
  //
  // componentWillUnmount() {
  //   IPPE_LOGIN_MANAGER.unregisterOnLogoutHandler("ippe-app")
  // }

  render() {
    return ce(VerticalStack, {style: StyleBuilder.start().height("100vh").build()},
      ce(IppeAppBarComponent),
      ce("div", {style: {flexGrow: 1}},
        ce(Outlet),
        ce(IppeLoginCreateModal)),
      ce("footer", {style: {minHeight: 15, background: IPPE_PRIMARY_COLOR}}))
  }
}

export default IppeApp;
