import React from "react";
import SuccessIcon from '@mui/icons-material/DoneAll';
import FailureIcon from '@mui/icons-material/ErrorOutline';
import {Button, CircularProgress} from "@mui/material";
import {ce, merge} from "./MiscUtils";

export type AckingButtonProps = {
  copy?: string | undefined,
  icon?: any,  // LOWTODO type
  onClick: () => Promise<void>
  disabled?: boolean | undefined,
  color: string,
  style: React.CSSProperties,
}

enum Status {
  NORMAL,
  PENDING,
  SUCCESS,
  FAILURE,
}

export type AckingButtonState = {
  status: Status
}

export class AckingButton extends React.Component<AckingButtonProps, AckingButtonState> {
  constructor(props: AckingButtonProps) {
    super(props);
    this.state = {status: Status.NORMAL};
    this.onClick = this.onClick.bind(this);
  }

  onClick(event: React.MouseEvent<HTMLButtonElement>) {
    // TODO: need visual indication of disabled...  but setting disabled on button
    // currently changes color of it in a way that poorly affects some current use-cases.
    const disabled = (this.state.status !== Status.NORMAL) || this.props.disabled;

    if (disabled) {
      event.stopPropagation();
      return
    }

    this.setState({status: Status.PENDING});
    const resetToNormalAfterDelay = ()=>{
      setTimeout(() => this.setState({status: Status.NORMAL}), 1500);
    };

    this.props.onClick().then(() => {
      this.setState({status: Status.SUCCESS}, resetToNormalAfterDelay);
    }).catch(()=> {
      this.setState({status: Status.FAILURE}, resetToNormalAfterDelay);
    });
  }

  render() {
    const color = this.props.color;
    const status = this.state.status;

    let icon: any = undefined;
    if (status === Status.NORMAL) {
      icon = ce(this.props.icon);
    } else if (status === Status.PENDING) {
      icon = ce(CircularProgress, {
        color: color as any,  // LOWTODO
        style: {width: 18, height: 18}
      });
    } else if (status === Status.SUCCESS) {
      icon = ce(SuccessIcon)
    } else if (status === Status.FAILURE) {
      icon = ce(FailureIcon)
    }

    return ce(Button, {
      size: "small",
      variant: "outlined",
      endIcon: icon,
      onClick: this.onClick,
      color: color as any, // LOWTODO
      style: merge({ height: 31 }, this.props.style )
    }, this.props.copy);
  }
}