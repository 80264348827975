import {CopyAsComponentProps} from "./CopyAsMisc";
import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {VerticalStack} from "../IppeUtils/MiscComponents";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {TextField} from "@mui/material";
import {
  IppeCommandParsedNode,
  IppeNamedArgParsedNode, IppePipelineParsedNode,
  IppePositionedArgParsedNode
} from "../IppeUtils/IppeTypes";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {escapeForShell} from "../IppeUtils/MiscUtilsTs";


type CopyAsIppeComponentState = {
  functionName: string
}

export class CopyAsIppeComponent extends React.Component<CopyAsComponentProps, CopyAsIppeComponentState> {
  constructor(props: CopyAsComponentProps) {
    super(props);
    this.state = {functionName: ""}
    this.onFunctionNameChange = this.onFunctionNameChange.bind(this);
  }

  onFunctionNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({functionName: event.target.value.replace(/[^a-zA-Z0-9_]/, "")});
  }

  render() {
    const functionName = this.state.functionName || "ippe_function";
    const {expression, parsedExpression, mimeType} = this.props;

    return ce(VerticalStack, {spacing: "1.5em", style: StyleBuilder.start().size("inherit").build()},
      ce(IppeMarkdownComponent, null, createIntroSnippet()),
      ce(TextField, {label: "Function Name", onChange: this.onFunctionNameChange, value: this.state.functionName || ""}),
      ce(IppeMarkdownComponent, {codeCopy: true}, createBodySnippet(functionName, expression, parsedExpression, mimeType)));
  }
}


function createIntroSnippet() {
  // TODO: Don't love the approach that I'm taking here wrt credentials.
  return `
This snippet provides the capability defined by your Ippe expression to your shell.

The function accepts input on stdin, and produces output to stdout.  It will return a 0 exit code on success.

It is compatible \`sh\`, \`bash\`, and \`zsh\`.  It requires installation of the Ippe command-line-interface.

This alternative provides a handful of advantages over _copy as curl_, including:

- Better readability, and
- Better tweakability.  For example, it's easy to update the function defined below to accept arguments to parameterize the ippe command.

The \`ippe\` command line interface will use user-login credentials (configured via \`ippe login\`).  If you intend to execute this command from an insecure environment, you should instead configure *execution* credentials via the \`IPPE_ACCCESS_KEY_ID\` and \`IPPE_ACCESS_KEY_SECRET\` environmental variables.
`
}

function createBodySnippet(
  name: string,
  expression: string,
  parsedExpression: IppePipelineParsedNode,
  mimeType: string | undefined
): string {
  const cliCommand = cliPipeline(mimeType, expression, parsedExpression, 4)

  return `
~~~bash  
function ${name} 
{
  ippe execute \\\n${cliCommand}
}
~~~

Invocation Examples:

~~~
${name} < some_file.in > some_file.out
~~~

~~~
cat some_file.in | ${name} | md5sum
~~~
`
}

function cliPositionedArg(
  expression: string,
  node: IppePositionedArgParsedNode,
  depth: number
): string {
  return `${" ".repeat(depth)}${escapeForShell(node.value)}`
}

function cliNamedArg(
  expression: string,
  node: IppeNamedArgParsedNode,
  depth: number
): string {
  return " ".repeat(depth) + (node.value.value === "" ? `--${node.name.value}` :
    `--${node.name.value}=${escapeForShell(node.value.value)}`)
}

function cliCommand(
  expression: string,
  mimeType: string | undefined,
  node: IppeCommandParsedNode,
  depth: number,
  idx: number
): string {
  const pipe = idx === 0 ? "" : ":: ";
  const cmdAlreadyHasITArg = node.namedArguments.find((arg)=>arg.name.value === "it")
  const inputType = ((!cmdAlreadyHasITArg && mimeType) ? ` --it="${mimeType}"` : "");
  const newlineIfArgs = (node.namedArguments.length + node.positionedArguments.length > 0) ? " \\\n" : "";
  return [" ".repeat(depth) + pipe + node.name.value + inputType + newlineIfArgs] +
    node.namedArguments.map(a => cliNamedArg(expression, a, depth + 2)).concat(
      node.positionedArguments.map(a => cliPositionedArg(expression, a, depth + 2))).join(" \\\n")
}

function cliPipeline(
  mimeType: string | undefined,
  expression: string,
  node: IppePipelineParsedNode,
  depth: number = 0
): string {
  const stages: Array<string> = [];

  node.commands.forEach((c, idx) => {
    stages.push(cliCommand(expression, idx === 0 ? mimeType : undefined, c, depth, stages.length));
  });

  return stages.join(" \\\n");
}
