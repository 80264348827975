import React from "react";
import {ce, merge} from "../../IppeUtils/MiscUtils";
import {ContentComponentProps} from "./ContentComponentMisc";
import {decodeToString} from "../../IppeUtils/ArrayBufferUtils";

export class TextHtmlContentComponent extends React.Component<ContentComponentProps, {}> {
  constructor(props: ContentComponentProps) {
    super(props);
  }

  render() {
    const style = merge({
      minHeight: "100%",
      height: "100%",
      width: "inherit",
      border: "none"
    }, this.props.style);

    const content = this.props.content
    const srcDoc: string = content ? decodeToString(content) : "";
    return ce("iframe", {srcDoc, style});
  }
}