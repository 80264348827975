import {
  Content,
  Dryrun,
  ExecError,
  IppeArgumentSpec,
  IppeCommandParsedNode,
  IppeLinkedNode, IppeMimeTypeSpec,
  IppeNamedArgParsedNode,
  IppeNameParsedNode,
  IppeParsedNode,
  IppePipelineParsedNode,
  IppePositionedArgParsedNode,
  IppeToolSpec,
  IppeValueParsedNode, LinkedCommandPipeline,
  LinkError,
  ParseError
} from "./IppeTypes";
import {flatten} from "ramda";
import {replaceRange} from "./StringUtils";
import {hashBuff, hashString} from "./MiscUtilsTs";


export function allErrors(
  dryrun: Dryrun
): Array<ParseError | LinkError> {
  const errors: Array<ParseError | LinkError> = accumulateParseErrors(dryrun.parsedPipeline);
  errors.push(...accumulateLinkErrors(dryrun.linkedPipeline))
  return errors;
}

export function containsFatalErrors(
  errors: Array<ParseError | LinkError | ExecError>
): boolean {
  return errors.filter(e => e.isFatal).length > 0
}

export function accumulateParseErrors(node: IppeParsedNode): Array<ParseError> {
  // TODO: this is kind of fragile.  Need to sort out gson on service side to get a "type" field in these objects.
  const anyNode = node as any;
  const children = flatten([
    anyNode?.commands || [],
    anyNode?.namedArguments || [],
    anyNode?.positionedArguments || [],
    anyNode?.name || []
  ]);

  return node?.errors.concat(flatten(children.map(accumulateParseErrors))) || [];
}


export function accumulateLinkErrors(node: IppeLinkedNode): Array<LinkError> {
  // TODO: this is kind of fragile.  Need to sort out gson on service side to get a "type" field in these objects.
  const anyNode = node as any;

  const children = flatten([
    anyNode?.commands || [],
    anyNode?.derivedArguments || [],
  ]);

  return node?.errors.concat(flatten(children.map(accumulateLinkErrors))) || [];
}


export function findToolByFullName(tools: Array<IppeToolSpec>, name: string): IppeToolSpec | undefined {
  return tools.find(t => t.name === name);
}

export function isCursorInNodeRange(
  cursorPosition: number,
  node: IppeParsedNode | undefined
): boolean {
  return node !== undefined && node.startIdx <= cursorPosition && cursorPosition <= node.endIdx;
}

export type GetToolAndArgSpecResult = {
  toolSpec: IppeToolSpec | undefined,
  argSpec: IppeArgumentSpec | undefined,

  cmdCtxt: IppeCommandParsedNode | undefined,
  cmdNameCtxt: IppeNameParsedNode | undefined

  namedArgCtxt: IppeNamedArgParsedNode | undefined,
  namedArgNameCtxt: IppeNameParsedNode | undefined,
  namedArgValueCtxt: IppeValueParsedNode | undefined,

  // LOWTODO:
  // posArgArgCtxt: IppePositionedArgParsedNode | undefined,
}

export function getCursorContext(
  expression: string,
  parsedPipeline: IppePipelineParsedNode,
  cursorPosition: number,
  tools: Array<IppeToolSpec>
): GetToolAndArgSpecResult {
  let toolSpec: IppeToolSpec | undefined = undefined;
  let argSpec: IppeArgumentSpec | undefined = undefined;

  let cmdCtxt: IppeCommandParsedNode | undefined = undefined;
  let cmdNameCtxt: IppeNameParsedNode | undefined = undefined;

  let namedArgCtxt: IppeNamedArgParsedNode | undefined = undefined;
  let namedArgNameCtxt: IppeNameParsedNode | undefined = undefined;
  let namedArgValueCtxt: IppeValueParsedNode | undefined = undefined;

  cmdCtxt = parsedPipeline?.commands?.find((cmd) => {
    const cursorInCommandRange = isCursorInNodeRange(cursorPosition, cmd)
    const cursorInWhitespaceAfterCommand =
      expression.substring(cmd.endIdx, cursorPosition).trim().replace(".", "").length === 0;

    return cursorInCommandRange || cursorInWhitespaceAfterCommand
  });

  if (cmdCtxt === undefined) {
    return {argSpec, cmdCtxt, cmdNameCtxt, namedArgCtxt, namedArgNameCtxt, namedArgValueCtxt, toolSpec};
  }

  if (isCursorInNodeRange(cursorPosition, cmdCtxt.name))
    cmdNameCtxt = cmdCtxt.name;

  namedArgCtxt = cmdCtxt.namedArguments.find(arg => isCursorInNodeRange(cursorPosition, arg));

  if (isCursorInNodeRange(cursorPosition, namedArgCtxt?.name))
    namedArgNameCtxt = namedArgCtxt!!.name;
  else if (isCursorInNodeRange(cursorPosition, namedArgCtxt?.value))
    namedArgValueCtxt = namedArgCtxt!!.value;

  toolSpec = tools.find((it) => it.name === cmdCtxt!!.name.value);

  if (toolSpec !== undefined && namedArgCtxt !== undefined)
    argSpec = toolSpec.argSpecs.find(spec => spec.name === namedArgCtxt!!.name.value);

  return {cmdCtxt, cmdNameCtxt, namedArgCtxt, namedArgNameCtxt, namedArgValueCtxt, toolSpec, argSpec}

}

export function updateNamedArgValueInExpression(
  expression: string,
  arg: IppeNamedArgParsedNode,
  newValue: string
) {
  const escapedArg = JSON.stringify(newValue);

  return replaceRange(expression,
    arg.name.endIdx, arg.value.endIdx - arg.value.startIdx + 1,
    `=${escapedArg}`);
}

export function updateCommandNameInExpression(
  expression: string,
  command: IppeCommandParsedNode,
  newValue: string
) {
  return replaceRange(expression,
    command.name.startIdx, command.name.endIdx - command.name.startIdx + 1,
    newValue);
}

export function updateArgName(
  expression: string,
  argSpec: IppeArgumentSpec | undefined,
  arg: IppeNamedArgParsedNode,
  newValue: string
) {
  let foo = "";
  if (expression.length >= arg.endIdx && expression[arg.endIdx] !== "=") {
    if (argSpec && argSpec.caster.type === "boolean" && argSpec.defaultsTo !== true) {
      foo = " ";
    } else {
      foo = "=";
    }
  }

  return replaceRange(expression,
    arg.name.startIdx, arg.name.endIdx - arg.name.startIdx + 1,
    newValue + foo);
}

export function stringifyParseError(
  err: ParseError,
  depth: number
): string {
  return " ".repeat(depth) + `error: ${err.startIdx}-${err.endIdx}: ${err.description}`;
}

export function stringifyPositionedArg(
  node: IppePositionedArgParsedNode,
  depth: number
): Array<string> {
  return [" ".repeat(depth) + `pos_arg: ${node.startIdx}-${node.endIdx}: ${node.value}`].concat(
    node.errors.map(e => stringifyParseError(e, depth + 2))
  );
}


export function stringifyValue(
  node: IppeValueParsedNode,
  depth: number
): Array<string> {
  return [" ".repeat(depth) + `value: ${node.startIdx}-${node.endIdx}: ${node.value}`].concat(
    node.errors.map(e => stringifyParseError(e, depth + 2))
  );
}

export function stringifyName(
  node: IppeNameParsedNode,
  depth: number
): Array<string> {
  return [" ".repeat(depth) + `name: ${node.startIdx}-${node.endIdx}: ${node.value}`].concat(
    node.errors.map(e => stringifyParseError(e, depth + 2))
  );
}

export function stringifyNamedArg(
  node: IppeNamedArgParsedNode,
  depth: number
): Array<string> {
  return [" ".repeat(depth) + `nam_arg: ${node.startIdx}-${node.endIdx}`].concat(
    stringifyName(node.name, depth + 2),
    stringifyValue(node.value, depth + 2)
  ).concat(
    node.errors.map(e => stringifyParseError(e, depth + 2))
  );
}

export function stringifyCommand(
  node: IppeCommandParsedNode,
  depth: number
): Array<string> {
  return [" ".repeat(depth) + `command: ${node.startIdx}-${node.endIdx}`].concat(
    stringifyName(node.name, depth + 2)
  ).concat(
    flatten(node.namedArguments.map(a => stringifyNamedArg(a, depth + 2)))
  ).concat(
    flatten(node.positionedArguments.map(a => stringifyPositionedArg(a, depth + 2)))
  ).concat(
    node.errors.map(e => stringifyParseError(e, depth + 2))
  );
}

export function stringifyPipeline(
  node: IppePipelineParsedNode,
  depth: number = 0
): Array<string> {
  return [" ".repeat(depth) + `pipeline: ${node.startIdx}-${node.endIdx}`].concat(
    flatten(node.commands.map(c => stringifyCommand(c, depth + 2)))
  ).concat(
    node.errors.map(e => stringifyParseError(e, depth + 2))
  );
}

export function contentHash(
  mimeType: string | undefined,
  content: ArrayBuffer | undefined
): number {
  return hashString(mimeType ?? "") + hashBuff(content);
}

export function contentEquivalent(
  left: Content | undefined,
  right: Content | undefined
): boolean {
  return (left?.contentHash ?? 0) === (right?.contentHash ?? 0);
}

export function firstAcceptableMimeType(pipeline: LinkedCommandPipeline): string | undefined {
  const mt = pipeline.commands[0]?.toolSpec?.accepts?.[0];
  return mt && mt.type && mt.subtype ? `${mt.type}/${mt.subtype}` : undefined;
}

export function allMimeTypes(accepts: Array<IppeMimeTypeSpec>): boolean {
  return accepts.find((mt)=>(!mt.type && !mt.subtype)) !== undefined;
}
