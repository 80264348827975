import React from "react";
import {IppeToolSpec} from "../IppeUtils/IppeTypes";
import {useParams} from "react-router-dom";
import axios from "axios";
import {getToolUrl} from "../IppeUtils/IppeUrls";
import {ToolDetailComponent} from "../IppeAssist/IppeAssistComponent";

const ce = React.createElement;

type IppeDocsToolProps = { toolName: string }

type IppeDocsToolState = { tool: IppeToolSpec | undefined }

export function IppeDocsToolComponent() {
  let {toolName} = useParams();

  console.log(toolName);
  return ce(IppeDocsToolComponentHelper, {key: toolName, toolName: toolName as string})
}

class IppeDocsToolComponentHelper extends React.Component<IppeDocsToolProps, IppeDocsToolState> {
  constructor(props: IppeDocsToolProps) {
    super(props);
    this.state = {
      tool: undefined
    };
  }

  componentDidMount() {
    axios.get(getToolUrl(this.props.toolName))
      .then(result => {
        this.setState({tool: result.data})
      })
      .catch((ex) => {
        // TODO
        console.log(ex);
      });
  }

  render() {
    return ce("div", {style: {padding: 16, width: "100%", overflow: "scroll"}},
      this.state.tool === undefined ? "Loading..." : ce(ToolDetailComponent, {spec: this.state.tool})
    );
  }
}