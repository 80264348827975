import React from "react";
import SuccessIcon from '@mui/icons-material/DoneAll';
import FailureIcon from '@mui/icons-material/ErrorOutline';
import {Button, CircularProgress, Snackbar, Tooltip} from "@mui/material";
import {ce, merge} from "./MiscUtils";
import IconButton from "@mui/material/IconButton";
import StyleBuilder from "./StyleBuilder";

export type AckingIconButtonProps = {
  icon: any,  // LOWTODO type
  toolTip: string,
  onClick: () => Promise<void>
  disabled?: boolean | undefined,
  color?: string | undefined,
  style?: React.CSSProperties | undefined,
}

enum Status {
  NORMAL,
  PENDING,
  SUCCESS,
  FAILURE,
}

export type AckingIconButtonState = {
  status: Status,
  errorMessage: string | undefined
}

export class AckingIconButton extends React.Component<AckingIconButtonProps, AckingIconButtonState> {
  constructor(props: AckingIconButtonProps) {
    super(props);
    this.state = {
      status: Status.NORMAL,
      errorMessage: undefined
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (this.props.disabled || this.state.status !== Status.NORMAL)
      return

    this.setState({status: Status.PENDING});
    const resetToNormalAfterDelay = () => {
      setTimeout(() => this.setState({status: Status.NORMAL}), 1500);
    };

    this.props.onClick().then(() => {
      this.setState({status: Status.SUCCESS}, resetToNormalAfterDelay);
    }).catch((err) => {
      const errorMessage = typeof (err) === typeof ("") ? err : undefined;
      this.setState({status: Status.FAILURE, errorMessage}, resetToNormalAfterDelay);
    });
  }

  render() {
    const color = this.props.color;
    const status = this.state.status;

    let icon: any = undefined;
    if (status === Status.NORMAL) {
      icon = ce(this.props.icon);
    } else if (status === Status.PENDING) {
      icon = ce(CircularProgress, {
        color: color as any,  // LOWTODO
        style: {width: 18, height: 18}
      });
    } else if (status === Status.SUCCESS) {
      icon = ce(SuccessIcon)
    } else if (status === Status.FAILURE) {
      icon = ce(FailureIcon)
    }

    const errorBar = status === Status.FAILURE && this.state.errorMessage ? ce(Snackbar, {
      open: true,
      message: this.state.errorMessage,
    }) : undefined;

    return ce("div", {},
      ce(Tooltip, {title: this.props.toolTip} as any,
        ce(IconButton, {
          size: "small",
          disabled: this.props.disabled,
          onClick: this.onClick,
          color: color as any, // LOWTODO
          style: merge(StyleBuilder.start().size(30).build(), this.props.style)
        }, icon)),
      errorBar);
  }
}