import {Content, createContent} from "../../IppeUtils/IppeTypes";
import React, {createRef} from "react";
import IconButton from "@mui/material/IconButton";
import StyleBuilder from "../../IppeUtils/StyleBuilder";
import {ce} from "../../IppeUtils/MiscUtils";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {Tooltip} from "@mui/material";


export type UploadButtonProps = {
  onChange: (content: Content) => void
}

export class UploadButton extends React.Component<UploadButtonProps, {}> {
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: UploadButtonProps) {
    super(props);
    this.inputRef = React.createRef()
    this.onChange = this.onChange.bind(this);
  }

  onChange(input: any) {
    const file = input.currentTarget.files[0];

    file.arrayBuffer().then((content: ArrayBuffer) => {
      this.props.onChange(createContent(file.type, content))
    })
  }

  render() {
    return ce("div", {},
      ce(Tooltip, {title: "Upload Test Input"} as any,
        ce(IconButton, {
          size: "small",
          onClick: () => {this.inputRef.current!!.click()},
          style: StyleBuilder.start().size(30).build()
        }, ce(UploadFileIcon))),
      ce("input", {
        type: "file",
        onChange: this.onChange,
        ref: this.inputRef,
        style: {display: "none"}
      })
    )
  }
}
