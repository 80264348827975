import {
  IppeArgumentSpec,
  IppeToolSpec,
  IppeCursorPosition, IppePipelineParsedNode
} from "../IppeUtils/IppeTypes";
import React from "react";
import {ce, isParent, merge} from "../IppeUtils/MiscUtils";
import {
  HorizontalSliver,
  HorizontalStack,
  VerticalSpacer,
  VerticalStack
} from "../IppeUtils/MiscComponents";
import {
  IPPE_ARG_NAME_STYLE, IPPE_ARG_VALUE_STYLE,
  IPPE_COMMAND_STYLE, IPPE_LIGHT_GREY_COLOR, IPPE_PRIMARY_COLOR, IPPE_SMALL_BUTTON_STYLE,
} from "../IppeUtils/IppeStyles";
import {allMimeTypes, getCursorContext} from "../IppeUtils/IppeUtils";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {InputAdornment, List, ListItem, ListSubheader, TextField, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandIcon from '@mui/icons-material/ChevronRight';
import ContractIcon from '@mui/icons-material/ChevronLeft';
import {OpenNewWindowButton} from "../IppeUtils/OpenNewWindowButton";
import {getBuilderUrl, getDashboardDocToolPath} from "../IppeUtils/IppeUrls";
import ClearIcon from '@mui/icons-material/Clear';
import {LightMarkupComponent} from "../IppeUtils/LightMarkupComponent";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {flatten, includes, map, pipe, sort, sortBy, uniq} from "ramda";
import {Link} from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const IA_PADDING = {
  paddingLeft: 8,
  paddingRight: 8
};

export type IppeAssistComponentProps = {
  cursorPosition: IppeCursorPosition | undefined
  tools: Array<IppeToolSpec>
  expression: string,
  parsedExpression: IppePipelineParsedNode,
  width: number,
  onWidthChange: (width: number) => void
}

type IppeAssistComponentState = {
  inputTerm: string,
  hasFocus: boolean
};

const IPPE_ASSIST_WIDTHS = [30, 390, 620];
export const INITIAL_IPPE_ASSIST_WIDTH = IPPE_ASSIST_WIDTHS[1];

export class IppeAssistComponent extends React.Component<IppeAssistComponentProps, IppeAssistComponentState> {
  constructor(props: IppeAssistComponentProps) {
    super(props);

    this.state = {
      inputTerm: "",
      hasFocus: false
    }

    this.onFilterChange = this.onFilterChange.bind(this);
  }

  shouldComponentUpdate(
    nextProps: Readonly<IppeAssistComponentProps>,
    nextState: Readonly<IppeAssistComponentState>,
    nextContext: any
  ): boolean {
    if (this.state.inputTerm !== nextState.inputTerm || this.props.width !== nextProps.width || this.state.hasFocus !== nextState.hasFocus)
      return true;

    const oldCtxt = getCursorContext(
      this.props.expression,
      this.props.parsedExpression,
      this.props.cursorPosition?.offset ?? 0,
      this.props.tools)

    const oldFilter = oldCtxt.cmdNameCtxt?.value;

    const newCtxt = getCursorContext(
      nextProps.expression,
      nextProps.parsedExpression,
      nextProps.cursorPosition?.offset ?? 0,
      nextProps.tools)

    const newFilter = newCtxt.cmdNameCtxt?.value;

    return oldFilter !== newFilter ||
      oldCtxt.toolSpec !== newCtxt.toolSpec ||
      this.props.tools !== nextProps.tools;
  }

  onFilterChange(event: any) {
    this.setState({inputTerm: event.target.value});
  }

  render() {
    let widthIndex = IPPE_ASSIST_WIDTHS.indexOf(this.props.width);
    if (widthIndex === undefined) {
      this.props.onWidthChange(INITIAL_IPPE_ASSIST_WIDTH)
      return ce("div");
    }

    const ippeAssistStyle = StyleBuilder.start()
      .width(IPPE_ASSIST_WIDTHS[widthIndex])
      .height("inherit")
      .background("#fcfcfc")
      .build();

    const expandButton = ce(IconButton, {
      size: "small",
      onClick: () => this.props.onWidthChange(IPPE_ASSIST_WIDTHS[widthIndex + 1]),
      disabled: widthIndex >= (IPPE_ASSIST_WIDTHS.length - 1),
      style: IPPE_SMALL_BUTTON_STYLE
    }, ce(ExpandIcon))

    if (widthIndex === 0) {
      return ce("div", {style: ippeAssistStyle},
        ce("div", {style: {paddingTop: 20}}, expandButton));
    }

    const contractButton = ce(IconButton, {
      size: "small",
      onClick: () => this.props.onWidthChange(IPPE_ASSIST_WIDTHS[widthIndex - 1]),
      disabled: widthIndex === 0,
      style: IPPE_SMALL_BUTTON_STYLE
    }, ce(ContractIcon))

    const cursorCtxt = getCursorContext(
      this.props.expression,
      this.props.parsedExpression,
      this.props.cursorPosition?.offset ?? 0,
      this.props.tools)

    const contextTerm = cursorCtxt.cmdCtxt?.name.value || "";
    const inputTerm = this.state.inputTerm;
    const hasFocus = this.state.hasFocus;

    const term = inputTerm !== "" || hasFocus ? inputTerm : contextTerm;
    const controlBarStyle = StyleBuilder.start({alignItems: "center"})
      .hPadding(10)
      .vPadding(20)
      .background(IPPE_LIGHT_GREY_COLOR)
      .build();
    const searchBoxStyle = {
      minWidth: 280,
      maxWidth: 400,
      input: {background: "white", color: inputTerm ? "black" : "#888888"},
    };

    const clearButtonAdornment = this.state.inputTerm ? ce(InputAdornment, {position: "end"},
      ce(IconButton, {
        edge: "end",
        onClick: () => this.setState({inputTerm: ""}),
        onMouseDown: (evt) => evt.preventDefault(), // prevent focus changing
      }, ce(ClearIcon))) : null;

    const controlBar = ce(HorizontalStack, {style: controlBarStyle},
      ce(TextField, {
        label: "Filter",
        onChange: this.onFilterChange,
        value: inputTerm || hasFocus ? inputTerm : contextTerm,
        size: "small",
        sx: searchBoxStyle,
        spellCheck: false,
        autoCapitalize: "off",
        autoComplete: "off",
        autoCorrect: "off",
        InputProps: {endAdornment: clearButtonAdornment,},
        onFocus: () => this.setState({hasFocus: true}),
        onBlur: () => this.setState({hasFocus: false})
      }),
      ce("div", {style: {flexGrow: 1}}),
      contractButton,
      expandButton)

    const trimmedTerm = term.trim();
    const toolCandidates = this.props.tools.filter(tool =>
      trimmedTerm.length === 0 || tool.name.includes(trimmedTerm)
    );

    const toolSpec = this.props.tools.find(tool => tool.name === trimmedTerm) ||
      (toolCandidates.length === 1 ? toolCandidates[0] : null);

    const contentComponent = toolSpec ?
      ce("div", {style: StyleBuilder.start().overflowScroll().topBorder(IPPE_PRIMARY_COLOR).build()},
        ce(ToolDetailComponent, {spec: toolSpec})) :
      ce(AllToolsAssistComponent, {
        toolSpecs: this.props.tools,
        term,
        onToolSelected: (tool: string) => this.setState({inputTerm: tool})
      });

    return ce(VerticalStack, {id: "ippe-assist", style: ippeAssistStyle},
      controlBar,
      contentComponent);
  }
}

type AllToolsAssistProps = {
  toolSpecs: Array<IppeToolSpec>,
  onToolSelected: (toolName: string) => void,
  term: string
}

const AllToolsAssistComponent: React.FC<AllToolsAssistProps> = function (props) {
  const {toolSpecs, term, onToolSelected} = props;

  const filteredToolSpecs = toolSpecs.filter((spec) => {
    return spec.name.includes(term.toLowerCase());
  });

  const tags = uniq(sortBy(it => it, flatten(map(it => it.tags, filteredToolSpecs))));

  const sectionHeaderStyle = StyleBuilder.start()
    .topBorder(IPPE_PRIMARY_COLOR)
    .bottomBorder(IPPE_PRIMARY_COLOR)
    .textColor(IPPE_PRIMARY_COLOR)
    .lineHeight("30px")
    .build();

  const items = tags.flatMap(tag => {
    const subItems = filteredToolSpecs
      .filter(it => includes(tag, it.tags))
      .map((spec, idx) => ce(ToolSpecComponent, {spec, idx, onClick: onToolSelected}))

    return [
      ce(ListSubheader, {style: sectionHeaderStyle}, tag),
      ...subItems];
  });


  return ce(List, {style: {overflow: "scroll", paddingTop: 0, paddingBottom: 0}},
    items);
}

type ToolSpecComponentProps = {
  idx: number,
  spec: IppeToolSpec,
  onClick: (toolName: string) => void
}

const ToolSpecComponent: React.FC<ToolSpecComponentProps> = function (props) {
  const {spec, onClick} = props;

  // TODO: need to do a better job of documenting input content types
  const fullName = spec.name;

  const style = props.idx === 0 ? StyleBuilder.start().padding(8).build() :
    StyleBuilder.start().padding(8).topBorder().build();

  return ce("div", {
      key: fullName,
      // see note below about why this is onPointerDown as opposed to onClick.
      onPointerDown: (evt: any) => {
        // need to preventDefault...  otherwise, if user has cursor in Search Bar and curently blank content, then
        // clicking will cause textfield to blur, which means that the content of the textfield will immediately
        // revert to the contextual input (based on the current logic)
        evt.preventDefault();
        onClick(fullName)
      },
      className: "tool-spec",  // for applying hover
      style
    },
    ce("span", {style: merge(IPPE_COMMAND_STYLE, {fontSize: "1.1em"})}, fullName),
    " - ",
    ce(LightMarkupComponent, {markup: spec.description.split("\n")[0]}));
}

type ToolDetailComponentProps = {
  spec: IppeToolSpec
}

export const ToolDetailComponent: React.FC<ToolDetailComponentProps> = function (props) {
  const {spec} = props;
  const name = spec.name;

  const headerStyle = {
    paddingTop: 12,
    paddingBottom: 8,
    fontWeight: 600
  };

  const typesStyle = {
    fontSize: 14,
    marginTop: 0,
    margin: 0
  };

  const acceptsCopy = !spec.sink ? ce(LightMarkupComponent, {markup: `\`${name}\` does not process input.`}) :
    allMimeTypes(spec.accepts) ? ce(LightMarkupComponent, {markup: `\`${name}\` accepts input of all mime-types.`}) :
      spec.accepts.map(mt => ce("pre", {key: `${mt.type}/${mt.subtype}`, style: typesStyle}, `• ${mt.type}/${mt.subtype}`));

  const producesCopy = !spec.source ? ce(LightMarkupComponent, {markup: `\`${name}\` does not produce output.`}) :
    allMimeTypes(spec.produces) ? ce(LightMarkupComponent, {markup: `\`${name}\` produces output of all mime-types.`}) :
      spec.produces.map(mt => ce("pre", {key: `${mt.type}/${mt.subtype}`, style: typesStyle}, `• ${mt.type}/${mt.subtype}`));

  const argsComponents = spec.argSpecs.map((argSpec) =>
    ce(ArgumentDetailComponent, {key: argSpec.name, spec: argSpec}))
  const examples = spec.examples.length === 0 ? [] :
    [ce("span", {style: headerStyle}, "Examples"),
      ...spec.examples.map((example, idx) => {
        const details = example.details ?
          ce(IppeMarkdownComponent, {key: `example_${idx}`, compact: true, codeCopy: false, style: {paddingLeft: 17, paddingTop: 5}}, example.details) :
          null;

        const exampleUrl = getBuilderUrl(example.expression, example.sampleInputId);
        return ce("div", {},
          // I want these bullets to look like the accepts / produces bullets... hence the monospace.
          ce("span", {style: {fontFamily: "monospace"}}, "• "),
          ce("a", {href: exampleUrl},
            ce(LightMarkupComponent, {markup: example.description})),
          " ",
          ce(OpenNewWindowButton, { url: exampleUrl, style: { width: ".65em", height: ".65em"}}),
          details);
      })]

  return ce(VerticalStack, {spacing: ".55em", alignItems: "stretch", style: {paddingBottom: 12}},
    ce(VerticalSpacer, {thickness: ".3em"}),
    ce(HorizontalStack, {style: merge(IPPE_COMMAND_STYLE, IA_PADDING, {fontSize: "1.3em", alignItems: "center"})},
      spec.name,
      ce("div", {style: {flexGrow: 1}}),
      ce(OpenNewWindowButton, {url: getDashboardDocToolPath(spec.name), style: {}})
    ),
    ce(VerticalStack, {spacing: ".5em", style: IA_PADDING},
      ce(IppeMarkdownComponent, {compact: true, codeCopy: false}, spec.description),
      ce("span", {style: headerStyle}, "Accepts"),
      acceptsCopy,
      ce("span", {style: headerStyle}, "Produces"),
      producesCopy,
      argsComponents.length > 0 ? ce("span", {style: headerStyle}, "Arguments") : null,
      argsComponents,
      ...examples));
}

type ArgumentDetailComponentProps = {
  spec: IppeArgumentSpec
}

const ArgumentDetailComponent: React.FC<ArgumentDetailComponentProps> = function (props) {
  const {spec} = props;

  const argBannerStyle = {
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
    borderWidth: 1,
  };

  const requiredStyle = {
    textAlign: "right",
    fontStyle: "italic",
    fontSize: ".75em",
  };

  const required = spec.required ? ce("span", {style: requiredStyle}, "required") : null;
  const type = spec.caster.type === "enum" ? ("{" + (spec.caster as any).options.map((it: string) => `${it}`).join(", ") + "}") :
    spec.caster.type === "javascript_function" ? ((spec.caster as any).signature) :
      `[${spec.caster.type}]`;

  let tooltipTitle: any = type;
  if (spec.caster.type === "javascript_function") {
    const jsCaster = (spec.caster as any);
    tooltipTitle = ce("pre", {}, (jsCaster.types as Array<string>).concat(jsCaster.signature).join("\n"));
  }

  const description = spec.defaultsTo ?
    (spec.description + ` (defaults to: \`${spec.defaultsTo}\`)`) :
    spec.description;

  const typeStyle = merge(IPPE_ARG_VALUE_STYLE, {
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingRight: "1em"
  });

  // TODO: finePrint
  // TODO: examples.  Primarily for command, but also for args
  return ce(VerticalStack, {key: spec.name, style: {marginTop: 5, marginBottom: 5}},
    ce(HorizontalStack, {style: argBannerStyle, alignItems: "center", flexWrap: "nowrap"},
      ce("span", {style: merge(IPPE_ARG_NAME_STYLE, {})}, `--${spec.name}`),
      ce("span", {}, `=`),
      ce(Tooltip, {title: tooltipTitle, placement: "bottom-start"} as any,
        ce("span", {className: "prevent-safari-tooltip", style: typeStyle}, type)),
      ce("div", {style: {flexGrow: 1}}),
      required),
    ce(VerticalSpacer, {thickness: "4px"}),
    ce(LightMarkupComponent, {markup: description, style: {paddingLeft: 18}}));
}
