import {getContentComponentForMimeType} from "./ContentEditor/ContentComponentMisc";
import {ce, merge} from "../IppeUtils/MiscUtils";
import React from "react";
import {IPPE_PREVIEW_CONTENT_BORDER, IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import {ExecError, LinkError, ParseError} from "../IppeUtils/IppeTypes";
import {containsFatalErrors} from "../IppeUtils/IppeUtils";

export type IppeContentComponentProps = {
  mimeType: string,
  content: ArrayBuffer,
  contentHash: number | undefined,
  onContentChange: undefined | ((value: ArrayBuffer) => void),
  style: React.CSSProperties,
  errors: Array<ParseError | LinkError | ExecError>
}

export class IppeContentComponent extends React.Component<IppeContentComponentProps, {}> {
  render() {
    const {mimeType, content} = this.props;

    const contentComponentClass = getContentComponentForMimeType(mimeType);
    const contentComponent = ce(contentComponentClass, {
      mimeType: mimeType!!,
      content: content,
      onContentChange: this.props.onContentChange,
      style: {}
    });

    const borderColor = containsFatalErrors(this.props.errors) ? "red" :
      this.props.errors.length > 0 ? "orange" :
        IPPE_PREVIEW_CONTENT_BORDER.borderColor;

    const contentWrapperStyle = merge(IPPE_PREVIEW_CONTENT_BORDER, {
      position: "relative",
      width: "100%",
      height: "100%",
      background: "white",
      borderColor
    }, this.props.style);

    return ce("div", {style: contentWrapperStyle}, contentComponent);
  }
}