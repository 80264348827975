import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@emotion/react";
import {COPY_FONT_FAMILY, IPPE_LIGHT_GREY_COLOR, IPPE_PRIMARY_COLOR} from "./IppeUtils/IppeStyles";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {ce} from "./IppeUtils/MiscUtils";
import IppeApp from "./IppeApp/IppeApp";
import IppeBuilder from "./IppeBuilder/IppeBuilder";
import {IppeUnderConstructionComponent} from "./IppeUnderConstruction/IppeUnderConstructionComponent";
import {IppeInvalidPathComponentComponent} from "./IppeInvalidPath/IppeInvalidPathComponent";
import {IppeAccessKeysComponent} from "./IppeAccessKeys/IppeAccessKeysComponent";
import {IppeDocsComponent} from "./IppeDocs/IppeDocsComponent";
import {IppeDocsToolComponent} from "./IppeDocs/IppeDocsToolComponent";
import {IppeDocsMarkdownComponent} from "./IppeDocs/IppeDocsMarkdownComponent";
import {IppeHome} from "./IppeHome/IppeHome";

// TODO: I find this kind of weird. This seems to be how you set the color mode to "light" for react-textarea-code-editor.
document.documentElement.setAttribute('data-color-mode', 'light')

const theme = createTheme({
  palette: {
    primary: {
      main: IPPE_PRIMARY_COLOR
    },
    secondary: {
      main: IPPE_LIGHT_GREY_COLOR
    }
  },
  typography: {
    fontFamily: COPY_FONT_FAMILY.fontFamily
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: IPPE_PRIMARY_COLOR
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: "#D5E3FC",
          color: IPPE_PRIMARY_COLOR,
        },
        // can't figure out how to change icon color here...  had to add rule to index.css.
      }
    }
  }
});

ReactDOM.render(ce(React.StrictMode, null,
  ce(ThemeProvider, {theme},
    ce(BrowserRouter, {},
      ce(Routes, {},
        ce(Route, {path: "/", element: ce(IppeApp)},
          ce(Route, {index: true, element: ce(IppeHome)}),
          ce(Route, {path: "builder", element: ce(IppeBuilder)}),
          ce(Route, {path: "pricing", element: ce(IppeUnderConstructionComponent)}),
          ce(Route, {path: "docs", element: ce(IppeDocsComponent)},
            ce(Route, {index: true, element: ce(IppeDocsMarkdownComponent, {key: "intro", mdUri: "/docs/intro.md"})}),
            ce(Route, {path: "foundation", element: ce(IppeDocsMarkdownComponent, {key: "foundation", mdUri: "/docs/foundation.md"})}),
            ce(Route, {path: "credentials", element: ce(IppeDocsMarkdownComponent, {key: "cli", mdUri: "/docs/credentials.md"})}),
            ce(Route, {path: "builder", element: ce(IppeDocsMarkdownComponent, {key: "builder", mdUri: "/docs/builder.md"})}),
            ce(Route, {path: "cli", element: ce(IppeDocsMarkdownComponent, {key: "cli", mdUri: "/docs/cli.md"})}),
            ce(Route, {path: "gotchas", element: ce(IppeDocsMarkdownComponent, {key: "gotchas", mdUri: "/docs/gotchas.md"})}),
            ce(Route, {path: "faq", element: ce(IppeDocsMarkdownComponent, {key: "faq", mdUri: "/docs/faq.md"})}),
            ce(Route, {path: "tools/:toolName", element: ce(IppeDocsToolComponent)})),
          ce(Route, {path: "account", element: ce(IppeUnderConstructionComponent)}),
          ce(Route, {path: "credentials", element: ce(IppeAccessKeysComponent)}),
          ce(Route, {path: "docs", element: ce(IppeUnderConstructionComponent)}),
          ce(Route, {path: "terms", element: ce(IppeUnderConstructionComponent)}),
          ce(Route, {path: "*", element: ce(IppeInvalidPathComponentComponent)}),
        )))
  )), document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
