import {
  IppeCommandParsedNode,
  IppeNamedArgParsedNode,
  IppePipelineParsedNode,
  IppePositionedArgParsedNode,
  ParseError
} from "./IppeTypes";
import {capture, dropFirst, isBlank, isEmpty, isLetterOrDigit, isWhitespace} from "./StringUtils";
import {head, last} from "ramda";


interface EatStringResult {
  value: string
  endIdx: number
  errors: Array<ParseError>
}

function createParseError(
  description: string,
  startIdx: number,
  endIdx: number,
  isFatal: boolean
): ParseError {
  return {description, startIdx, endIdx, isFatal}
}

export function eatString(
  src: string,
  startIdx: number
): EatStringResult {
  const SINGLE_QUOTE: string = "'"
  const DOUBLE_QUOTE: string = '"'
  const BACKSLASH: string = "\\"

  let valueBuff = ""
  let inSingleQuote = false
  let inDoubleQuote = false

  let endIdx = startIdx
  while (endIdx < src.length) {
    const curr = src[endIdx]
    const nextChar = endIdx + 1  < src.length ? src[endIdx + 1] : undefined;

    if (curr === BACKSLASH) {
      if (nextChar !== undefined) {
        // TODO: more special chars?
        valueBuff += nextChar === 'n' ? '\n' :
          nextChar === 't' ? '\t' :
            nextChar;

        endIdx += 2
        continue
      } else if (endIdx + 1 >= src.length) {
        return {
          value: valueBuff.toString(),
          endIdx: src.length,
          errors: [createParseError("Unexpected end string.  Unfulfilled escape \\", startIdx, src.length, true)]
        }
      }
    }

    if (inSingleQuote) {
      if (curr === SINGLE_QUOTE) inSingleQuote = false
      else valueBuff += curr

      endIdx++
      continue
    } else if (inDoubleQuote) {
      if (curr === DOUBLE_QUOTE) inDoubleQuote = false
      else valueBuff += curr

      endIdx++
      continue
    }

    if (!inSingleQuote && curr === SINGLE_QUOTE) {
      inSingleQuote = true
      endIdx++
      continue
    } else if (!inDoubleQuote && curr === DOUBLE_QUOTE) {
      inDoubleQuote = true
      endIdx++
      continue
    }

    // TODO: this requires additional thought
    const isIppePipe = (curr === ':' && nextChar === ':');
    const isStrChar = (isLetterOrDigit(curr) || ":-_/@.%+=,".includes(curr)) && !isIppePipe;
    if (!isStrChar) { // TODO: double-check comma
      return {
        value: valueBuff.toString(),
        endIdx: endIdx, errors: []
      }
    }

    valueBuff += curr
    ++endIdx
  }

// we only get here if we hit the end of src
  const errors = inSingleQuote ? [createParseError("Unexpected end string.  Missing closing single-quote", startIdx, src.length, true)] :
    inDoubleQuote ? [createParseError("Unexpected end string.  Missing closing single-quote", startIdx, src.length, true)] :
      [];

  return {value: valueBuff.toString(), endIdx, errors}
}

function parseIppePositionedArgNode(
  src: string,
  startIdx: number,
  position: number
): IppePositionedArgParsedNode | undefined {
  var subStartIdx = startIdx

  while (subStartIdx < src.length && isWhitespace(src[subStartIdx]))
    ++subStartIdx

  const eatStringResult = eatString(src, subStartIdx)
  if (eatStringResult.value == null || isEmpty(eatStringResult.value))
    return undefined;

  const node: IppePositionedArgParsedNode = {
    position,
    value: eatStringResult.value,
    startIdx: subStartIdx,
    endIdx: eatStringResult.endIdx,
    errors: eatStringResult.errors
  };

  return node
}

function parseIppeNamedArgNode(
  src: string,
  startIdx: number
): IppeNamedArgParsedNode | undefined {
  const regex = /^\s*--([a-zA-Z][\w-]*)=?/d
  const captureResult = capture(src, regex, startIdx)

  if (!captureResult)
    return undefined

  const valueStartIdx = captureResult.matchRange.end + 1;
  const eatResult = eatString(src, valueStartIdx);

  const node: IppeNamedArgParsedNode = {
    name: {
      value: captureResult.captureValue,
      startIdx: captureResult.captureRange.start,
      endIdx: captureResult.captureRange.end + 1,
      errors: []
    },
    value: {
      value: eatResult.value,
      startIdx: valueStartIdx,
      endIdx: eatResult.endIdx,
      errors: eatResult.errors
    },
    startIdx: captureResult.captureRange.start,
    endIdx: eatResult.endIdx,
    errors: []
  }

  return node;
}

function parseIppeCommandNode(
  src: string,
  startIdx: number
): IppeCommandParsedNode | undefined {
  const regex = /^\s*([a-zA-Z]\w*(\.[a-zA-Z]\w*)?)/d
  const captureResult = capture(src, regex, startIdx)

  if (!captureResult)
    return undefined

  let namedArgs = Array<IppeNamedArgParsedNode>()
  let positionedArgs = Array<IppePositionedArgParsedNode>()
  let subLeftIdx = captureResult.captureRange.end + 1

  while (subLeftIdx < src.length) {
    const nextNamedArg = parseIppeNamedArgNode(src, subLeftIdx)
    if (nextNamedArg) {
      namedArgs = namedArgs.concat(nextNamedArg)
      subLeftIdx = nextNamedArg.endIdx
      continue
    }

    const nextPositionedArg = parseIppePositionedArgNode(src, subLeftIdx, positionedArgs.length)
    if (nextPositionedArg) {
      positionedArgs = positionedArgs.concat(nextPositionedArg);
      subLeftIdx = nextPositionedArg.endIdx
      continue
    }

    break
  }

  const node: IppeCommandParsedNode = {
    name: {
      value: captureResult.captureValue,
      startIdx: captureResult.captureRange.start,
      endIdx: captureResult.captureRange.end + 1,
      errors: []
    },
    namedArguments: namedArgs,
    positionedArguments: positionedArgs,
    startIdx: captureResult.captureRange.start,
    endIdx: subLeftIdx,
    errors: []
  };

  return node;
}

function eatPipe(
  src: string,
  startIdx: number
): number | undefined {
  const regex = /^\s*(::|\|)/d

  const captureResult = capture(src, regex, startIdx)

  if (!captureResult)
    return undefined

  return captureResult.captureRange.end + 1
}

export function parseIppePipelineNode(
  src: string
): IppePipelineParsedNode {
  let cmds = Array<IppeCommandParsedNode>()
  let errors = Array<ParseError>()
  let expectingCommand = false
  let startIdx = 0

  // TODO: what if this is blank to begin with?  Is that an error?

  while (startIdx < src.length) {
    if (isBlank(dropFirst(src, startIdx))) {
      startIdx = src.length
      break
    }

    const result = parseIppeCommandNode(src, startIdx)
    if (result != null) {
      cmds = cmds.concat(result)
      startIdx = result.endIdx

      expectingCommand = false
      const postPipeIdx = eatPipe(src, startIdx)
      if (postPipeIdx) {
        expectingCommand = true   // if we ingest a pipe, then we're expecting another command.
        startIdx = postPipeIdx
      }
    } else {
      errors = errors.concat(createParseError("Unexpected content.", startIdx, src.length, true))
      startIdx = src.length
    }
  }

  if (expectingCommand) {
    errors = errors.concat(createParseError("Expected command after pipe.", startIdx, src.length, true))
  }

  const firstCommand = head(cmds);
  const lastCommand = last(cmds);

  const node: IppePipelineParsedNode = {
    commands: cmds,
    startIdx: firstCommand ? firstCommand.startIdx : startIdx,
    endIdx: lastCommand ? lastCommand.endIdx : startIdx,
    errors
  };

  return node;
}
