import React from "react";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {Content, ExecError, IppeToolSpec, LinkError, ParseError} from "../IppeUtils/IppeTypes";
import {containsFatalErrors, findToolByFullName} from "../IppeUtils/IppeUtils";
import {IppePreviewEvaluationStage} from "./IppePreviewEvaluator";
import {SampleContentComponent, CommandContentComponent} from "./IppeStageComponent";
import Xarrow from "react-xarrows";
import {ce, merge, randomItem} from "../IppeUtils/MiscUtils";


const NODE_ZOOM_LEVELS = [250, 300, 350, 400, 450, 500];
export const PREVIEW_ZOOM_LEVELS = NODE_ZOOM_LEVELS.length;
export const DEFAULT_PREVIEW_ZOOM_LEVEL = 2;

const NODE_VERT_SPACER = 75;
const NODE_HORIZ_SPACER = 75;

export type IppePreviewComponentProps = {
  previewInput: Content,
  tools: Array<IppeToolSpec>,
  onPreviewInputChange: (value: Content, reload?: boolean) => void,
  stages: Array<IppePreviewEvaluationStage>,
  currPreviewZoomLevel: number,
  widthPx: number
};

export type IppePreviewComponentState = {};

export class IppePreviewComponent extends React.Component<IppePreviewComponentProps, IppePreviewComponentState> {
  constructor(props: IppePreviewComponentProps) {
    super(props);
    this.state = {};
    this.onStagesChange = this.onStagesChange.bind(this);
  }

  shouldComponentUpdate(
    nextProps: Readonly<IppePreviewComponentProps>,
    nextState: Readonly<IppePreviewComponentState>,
    nextContext: any
  ): boolean {
    return (nextProps.previewInput.contentHash !== this.props.previewInput.contentHash) ||
      (nextProps.tools.length !== this.props.tools.length) ||
      (nextProps.widthPx !== this.props.widthPx) ||
      (nextProps.currPreviewZoomLevel !== this.props.currPreviewZoomLevel) ||
      (stagesDiffer(this.props.stages, nextProps.stages));
  }

  onStagesChange(stages: Array<IppePreviewEvaluationStage>) {
    this.setState({stages, stagesVersion: Math.random()})
  }

  render() {
    const nodeWidthPx = NODE_ZOOM_LEVELS[this.props.currPreviewZoomLevel];  // TODO: be more defensive.
    const nodeHeightPx = nodeWidthPx;

    const columns = calcNumColumnsForWidth(this.props.widthPx, nodeWidthPx);
    const consumedWidth = nodeWidthPx + (nodeWidthPx + NODE_HORIZ_SPACER) * (columns - 1);

    const toolSpec = this.props.stages.length > 0 ?
      findToolByFullName(this.props.tools, this.props.stages[0].command.name.value) : undefined;
    const showPreviewNode = toolSpec === undefined || toolSpec.sink

    const previewNodeId = `content_preview`;
    const nodes: Array<React.ReactElement<any, any>> = [];

    if (showPreviewNode) {
      nodes.push(ce(SampleContentComponent, {
        key: previewNodeId,
        nodeId: previewNodeId,
        dimensions: {top: 0, left: 0, width: nodeWidthPx, height: nodeHeightPx},
        value: this.props.previewInput,
        onValueChange: this.props.onPreviewInputChange
      }))
    }

    const edges: Array<React.ReactElement> = [];
    this.props.stages.forEach((stage, idx) => {
      const stageNodeId = `stage_${idx}`;

      const column = (nodes.length % columns);
      const contentXDim = column * (nodeWidthPx + NODE_HORIZ_SPACER);

      const row = Math.floor(nodes.length / columns);
      const contentYDim = row * (nodeWidthPx + NODE_VERT_SPACER);

      const contentNode = ce(CommandContentComponent, {
        key: stageNodeId,
        nodeId: stageNodeId,
        stage: stage,
        dimensions: {left: contentXDim, top: contentYDim, width: nodeWidthPx, height: nodeHeightPx}
      });

      nodes.push(contentNode);

      const firstInColumn = column === 0;

      const commonArrowProps = {
        // TODO: really hate to do this, but otherwise arrow locations sometimes don't update on window resize / zoom.
        key: Math.random(), // `edge_${stageNodeId}`,
        strokeWidth: 2,
        color: containsFatalErrors(stage.errors) ? "red" :
          stage.aborted || stage.errors.length > 0 ? "orange" : "#666666",
        curveness: 1,
        end: stageNodeId,
        startAnchor: firstInColumn ? "bottom" : "right",
        endAnchor: firstInColumn ? "top" : "left",
      };

      if (idx === 0 && showPreviewNode) {
        edges.push(ce(Xarrow, merge(commonArrowProps, {start: previewNodeId})));
      } else if (idx !== 0) {
        edges.push(ce(Xarrow, merge(commonArrowProps, {start: `stage_${idx - 1}`})));
      }
    });

    const calculatedHeight = (Math.ceil(nodes.length / columns)) * (nodeHeightPx + NODE_VERT_SPACER)
    const style = StyleBuilder.start({marginTop: "2em"})
      .hMargin("auto")
      .position("relative")  // children will be absolutely positioned, so this is necessary.
      .width(consumedWidth)
      .height(`max(100%, ${calculatedHeight}px)`)
      .build();

    return ce("div", {style}, nodes, edges);
  }
}

function calcNumColumnsForWidth(
  canvasWidthPx: number,
  nodeWidthPx: number,
): number {
  const remainingWidth = canvasWidthPx - nodeWidthPx - 30; // 30 is just some slush for margins
  const columns = 1 + (remainingWidth < 0 ? 0 : Math.floor(remainingWidth / (nodeWidthPx + NODE_HORIZ_SPACER)));

  return columns;
}

function stagesDiffer(
  left: Array<IppePreviewEvaluationStage>,
  right: Array<IppePreviewEvaluationStage>,
): boolean {
  if (left.length !== right.length)
    return true

  for (let idx = 0; idx < left.length; ++idx) {
    if (stageDiffers(left[idx], right[idx]))
      return true;
  }

  return false;
}

function stageDiffers(
  left: IppePreviewEvaluationStage,
  right: IppePreviewEvaluationStage,
): boolean {
  return (left.subExpression !== right.subExpression) ||
    (left.output?.contentHash !== right.output?.contentHash) ||
    (left.pending !== right.pending) ||
    (left.aborted !== right.aborted) ||
    errorsDiffer(left.errors, right.errors);
}

function errorsDiffer(
  left: Array<ParseError | LinkError | ExecError>,
  right: Array<ParseError | LinkError | ExecError>,
): boolean {
  if (left.length !== right.length)
    return true

  for (let idx = 0; idx < left.length; ++idx) {
    if (JSON.stringify(left[idx]) !== JSON.stringify(right[idx]))
      return true;
  }

  return false;
}
