import {
  IppeArgumentSpec,
  IppeToolSpec,
  IppeCursorPosition, IppePipelineParsedNode, IppeNamedArgParsedNode
} from "../IppeUtils/IppeTypes";
import React from "react";
import {ce, isParent, merge} from "../IppeUtils/MiscUtils";
import {
  CODE_FONT_FAMILY,
  IPPE_CARD_BORDER_COLOR, IPPE_DEEP_BOX_SHADOW,
  IPPE_LIGHT_GREY_COLOR,
  IPPE_PRIMARY_COLOR,
} from "../IppeUtils/IppeStyles";
import {getCursorContext, updateNamedArgValueInExpression} from "../IppeUtils/IppeUtils";
import CodeEditor from "@uiw/react-textarea-code-editor";
import {Popper} from "@mui/material";
import {maxLineLength, replaceRange} from "../IppeUtils/StringUtils";
import {HorizontalSliver, VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import {COMMAND_VIEWER_CURSOR_ANCHOR_ID} from "../IppeEditor/IppeCommandViewerComponent";


export type IppeArgEditorComponentProps = {
  cursorPosition: IppeCursorPosition | undefined,
  onExpressionChange: (expression: string) => void,
  tools: Array<IppeToolSpec>
  expression: string,
  parsedExpression: IppePipelineParsedNode
}


type IppeArgEditorComponentState = {
  argSpec: IppeArgumentSpec | undefined
  arg: IppeNamedArgParsedNode | undefined

  // If the IAE gains focus, it doesn't want to give it up until the user dismisses it.
  hasFocus: boolean

  // user pressed `escape` or clicked outside shell / arg-editor, so we dismissed the IAE component.  We don't
  // want to re-display it until one of a handful of conditions arise...  otherwise, it'll get annoying.
  dismissed: boolean
}


export class IppeArgEditorComponent extends React.Component<IppeArgEditorComponentProps, IppeArgEditorComponentState> {
  private readonly popoverRef: React.RefObject<HTMLDivElement>;

  constructor(props: IppeArgEditorComponentProps) {
    super(props);

    this.popoverRef = React.createRef()

    this.state = {
      hasFocus: false,
      argSpec: undefined,
      arg: undefined,
      dismissed: false,
    }

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(
    prevProps: Readonly<IppeArgEditorComponentProps>,
    prevState: Readonly<IppeArgEditorComponentState>,
    snapshot?: any
  ) {
    if (prevProps.expression !== this.props.expression || prevProps.cursorPosition !== this.props.cursorPosition) {
      if (this.state.hasFocus && this.state.arg !== undefined) {
        const {argSpec, namedArgCtxt} = getCursorContext(this.props.expression, this.props.parsedExpression,
          this.state.arg.startIdx, this.props.tools)

        this.setState({argSpec, arg: namedArgCtxt});
      } else if (this.props.cursorPosition !== undefined) {
        const {argSpec, namedArgCtxt} = getCursorContext(this.props.expression, this.props.parsedExpression,
          this.props.cursorPosition.offset, this.props.tools)

        const dismissed = this.state.dismissed ? prevState.arg?.value.startIdx === namedArgCtxt?.value.startIdx : false;
        this.setState({argSpec, arg: namedArgCtxt, dismissed});
      }
    }
  }

  componentDidMount() {
    document.addEventListener("keyup", this.onKeyUp)
    document.addEventListener("click", this.onClick)
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onKeyUp)
    document.removeEventListener("click", this.onClick)
  }

  onKeyUp(event: any) {
    if (event.key === "Escape") {
      if (this.state.hasFocus) {
        // LOWTODO: circuitous and hacky.
        const shellElement = document.getElementById("shell") as HTMLTextAreaElement;
        shellElement.focus()
      }

      this.setState({
        dismissed: true,
        hasFocus: false,
      })
    }
  }

  onClick(event: MouseEvent) {
    const shellElement = document.getElementById("shell") as HTMLTextAreaElement;
    const clickInShell = shellElement && isParent(shellElement, event.target);
    const clickInPopover = this.popoverRef.current && isParent(this.popoverRef.current, event.target);

    // If it's in a dismissed state and the user clicks in the shell, then undo the dismissed state.
    if (this.state.dismissed && clickInShell) {
      this.setState({dismissed: false});
      return;
    }

    if (clickInShell || clickInPopover) {
      return;
    }

    this.setState({
      dismissed: true,
      hasFocus: false,
    });
  }

  onFocus() {
    if (!this.state.hasFocus &&
      this.state.arg &&
      !this.state.arg.value.value &&
      this.state.argSpec &&
      this.state.argSpec.hint
    ) {
      this.props.onExpressionChange(updateNamedArgValueInExpression(
        this.props.expression, this.state.arg, this.state.argSpec.hint))
    }

    this.setState({hasFocus: true})
  }

  onBlur() {
    this.setState({hasFocus: false})
  }

  onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const {expression} = this.props;
    const {arg} = this.state;

    if (arg === undefined || expression === undefined)
      return;     // how did we get here?

    const newExpression = updateNamedArgValueInExpression(expression, arg, event.target.value);

    this.props.onExpressionChange(newExpression);
  }

  render() {
    // LOWTODO: This is really circuitous and hacky.
    const shellElement = document.getElementById("shell") as HTMLTextAreaElement;
    const anchorEl = document.getElementById(COMMAND_VIEWER_CURSOR_ANCHOR_ID);

    const {argSpec, arg, hasFocus, dismissed} = this.state;

    if (!shellElement ||
      !anchorEl ||
      !(hasFocus || this.props.cursorPosition) ||
      dismissed ||
      arg === undefined ||
      argSpec === undefined ||
      argSpec.caster.type !== "javascript_function"
    ) {
      return ce("div");
    }

    const approxCharWidth = 12.5;
    const width = Math.max(480,
      Math.min(60 * approxCharWidth, maxLineLength(arg.value.value) * approxCharWidth));

    const wrapperStyle = {
      position: "relative",
      borderColor: IPPE_CARD_BORDER_COLOR,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 2,
      background: "#FDFDFD",
      minWidth: width,
      width,
      maxWidth: width,
      boxShadow: IPPE_DEEP_BOX_SHADOW,
      zIndex: 1,
      overflow: "hidden"
    };


    const headerStyle: React.CSSProperties = merge(CODE_FONT_FAMILY, {
      padding: 10,
      margin: 0,
      fontSize: "1em",
      background: IPPE_PRIMARY_COLOR,
      color: "white",
    });

    const descriptionStyle = merge({
      position: "relative",
      padding: 10,
      margin: 0,
      fontSize: "1em",
      left: 0,
      right: 0,

      background: IPPE_LIGHT_GREY_COLOR,
    });

    const codeEditorStyle = merge(CODE_FONT_FAMILY, {
      background: "#FDFDFD",
      minHeight: "3em",
      minWidth: width,
      fontSize: "1.25em",
      width: "max-content",
      overflow: undefined,
    });

    return ce(Popper, {open: true, anchorEl, placement: "bottom-start", ref: this.popoverRef, style: { zIndex: 5}},
      ce(VerticalStack, {alignItems: "stretch", style: wrapperStyle},
        ce("pre", {style: headerStyle}, "--" + argSpec.name),
        // ce("span", {style: descriptionStyle}, argSpec.description),
        ce(HorizontalSliver),
        ce("div", {style: {maxWidth: width, overflow: "scroll"}}, ce(CodeEditor, {
          tabIndex: 2,
          value: arg.value.value || argSpec.hint || "",
          onChange: this.onChange,
          language: "javascript",
          style: codeEditorStyle,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
        }))));
  }
}
