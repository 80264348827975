import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {ce} from "./MiscUtils";
import {AckingButton} from "./AckingButton";


export type CopyButtonProps = {
  content: string | undefined
  compact?: boolean | undefined
  style: React.CSSProperties
  secondaryColor?: boolean | undefined
}

export class CopyButton extends React.Component<CopyButtonProps, {}> {
  constructor(props: CopyButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(): Promise<void> {
    return new Promise((resolve, reject) => {
      const content = this.props.content;
      if (content !== undefined) {
        navigator.clipboard.writeText(content)
          .then(r => {resolve();})
          .catch(err => {reject();});
      }
    });
  }

  render() {
    const color = this.props.secondaryColor ? "secondary" : "primary"

    return ce(AckingButton, {
      copy: "Copy",
      icon: ContentCopyIcon,
      disabled: this.props.content === undefined,
      color: color,
      onClick: this.onClick,
      style: {width: 85}
    })
  }
}