import {ce} from "../IppeUtils/MiscUtils";
import {CenterVertical, HorizontalStack, VerticalSliver} from "../IppeUtils/MiscComponents";
import React from "react";
import {Button, ButtonGroup} from "@mui/material";
import {IppeCopyAsModalComponent} from "../IppeCopyAs/IppeCopyAsModalComponent";
import {IPPE_COPY_AS_FACTORY} from "../IppeCopyAs/CopyAsMisc";
import {IppePipelineParsedNode} from "../IppeUtils/IppeTypes";
import {IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import {IppeFavoritesModalComponent} from "../IppeFavorite/IppeFavoritesModalComponent";
import {ZoomComponent} from "../IppeUtils/ZoomComponent";
import {PREVIEW_ZOOM_LEVELS} from "../IppePreview/IppePreviewComponent";


export type IppeToolbarComponentProps = {
  expression: string,
  parsedExpression: IppePipelineParsedNode
  mimeType: string | undefined,
  currPreviewZoomLevel: number,
  onPreviewZoomLevelChanged: (zoomLevel: number) => void,
}

type IppeToolbarComponentState = {
  modal: React.ReactElement | undefined,
}


export class IppeToolbarComponent extends React.Component<IppeToolbarComponentProps, IppeToolbarComponentState> {
  constructor(props: IppeToolbarComponentProps) {
    super(props);
    this.state = {modal: undefined};

    this.handleCopyAsSelected = this.handleCopyAsSelected.bind(this)
    this.handleFavoritesSelected = this.handleFavoritesSelected.bind(this)
  }

  shouldComponentUpdate(
    nextProps: Readonly<IppeToolbarComponentProps>,
    nextState: Readonly<IppeToolbarComponentState>,
    nextContext: any
  ): boolean {
    return this.props.expression !== nextProps.expression
      || this.props.mimeType !== nextProps.mimeType
      || this.state.modal !== nextState.modal
      || this.props.currPreviewZoomLevel !== nextProps.currPreviewZoomLevel;
  }

  handleCopyAsSelected(copyAsSelection: string) {
    const modal = ce(IppeCopyAsModalComponent, {
      copyAsType: copyAsSelection,
      mimeType: this.props.mimeType,
      expression: this.props.expression,
      parsedExpression: this.props.parsedExpression,
      onClose: () => this.setState({modal: undefined})
    })

    this.setState({modal})
  }

  handleFavoritesSelected() {
    const modal = ce(IppeFavoritesModalComponent,
      {onClose: () => this.setState({modal: undefined})});

    this.setState({modal})
  }

  render() {
    const copyAsTypes = Object.keys(IPPE_COPY_AS_FACTORY);
    const copyAsButtons = copyAsTypes.map((copyAsSelection) => {
      return ce(Button, {
        key: copyAsSelection,
        onClick: () => this.handleCopyAsSelected(copyAsSelection)
      }, copyAsSelection)
    });

    const zoomComponent = ce(ZoomComponent, {
      numZoomLevels: PREVIEW_ZOOM_LEVELS,
      currLevel: this.props.currPreviewZoomLevel,
      onZoomLevelChange: this.props.onPreviewZoomLevelChanged,
      style: {}
    });

    const heavyDivider = ce(VerticalSliver, {thickness: 2, color: IPPE_PRIMARY_COLOR});
    // TODO: need to reevaluate this mime-type handling now that lack of mime-type is only warning worthy
    // (also because non-sinks don't take a mime-type)
    const copyAsDisabled = this.props.expression.trim() === ""
    return ce(HorizontalStack, {height: "2em", padding: 0},
      heavyDivider,
      ce(CenterVertical, {style: {fontSize: "13px", paddingLeft: 5, paddingRight: 5}}, "COPY AS"),
      heavyDivider,
      this.state.modal,
      ce(ButtonGroup, {variant: "text", disabled: copyAsDisabled},
        ...copyAsButtons,
        // ce(Button, {}, "More ⌄")
      ),
      heavyDivider,
      // TODO
      // ce(ButtonGroup, {variant: "text"},
      //   ce(Button, {}, "share"),
      //   ce(Button, { onClick: this.handleFavoritesSelected}, "favorites"),
      // ),
      ce("div", {style: {flexGrow: 1}}),
      heavyDivider,
      zoomComponent
    )
  }
}