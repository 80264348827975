import React from "react";
import {CopyAsIppeComponent} from "./CopyAsIppeComponent";
import {CopyAsCurlComponent} from "./CopyAsCurlComponent";
import {ce} from "../IppeUtils/MiscUtils";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {COPY_FONT_FAMILY, IPPE_LIGHT_GREY_COLOR} from "../IppeUtils/IppeStyles";
import {IppePipelineParsedNode} from "../IppeUtils/IppeTypes";
import {CopyAsPythonComponent} from "./CopyAsPython";
import {CopyAsJavaComponent} from "./CopyAsJava";
import {CopyAsJavascriptComponent} from "./CopyAsJavascript";


export type CopyAsComponentProps = {
  style: React.CSSProperties,
  mimeType: string | undefined,
  expression: string,
  parsedExpression: IppePipelineParsedNode
}

interface KeyToCopyAsComponentMap {
  [key: string]: React.ComponentClass<CopyAsComponentProps, any>
}

// TODO: need to be able to handle non-sinks
export const IPPE_COPY_AS_FACTORY: KeyToCopyAsComponentMap = {
  "ippe-cli": CopyAsIppeComponent,
  "curl": CopyAsCurlComponent,
  "python" : CopyAsPythonComponent,
  "java" : CopyAsJavaComponent,
  "javascript" : CopyAsJavascriptComponent,
  // "ruby" : CopyAsIppeComponent,
  // "kotlin" : CopyAsIppeComponent,
  // "swift" : CopyAsIppeComponent,
  // "go" : CopyAsIppeComponent,
}

type SnippetComponentProps = {
  children: React.ReactNode
}

export const SnippetComponent: React.FC<SnippetComponentProps> = function ({children}) {
  const style = StyleBuilder.start()
    .overflowScroll()
    .background(IPPE_LIGHT_GREY_COLOR)
    .roundedGrayBorder()
    .vPadding("1em")
    .hPadding("1em")
    .build()

  return ce("pre", {style}, children);
}

type PlainTextComponentProps = {
  children: React.ReactNode
}

export const PlainTextComponent: React.FC<PlainTextComponentProps> = function ({children}) {
  const style = StyleBuilder.start(COPY_FONT_FAMILY)
    .vPadding("1em")
    .hPadding("1em")
    .build()

  return ce("div", {style}, children)
}
