import {mergeAll, uniq} from "ramda";
import React from "react";

export const ce = React.createElement;

export function catching(f, fallback = null) {
  try {
    return f()
  } catch (e) {
    return fallback;
  }
}


export function debounce(func, timeoutMs = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeoutMs);
  };
}

export function merge(obj, ...objs) {
  return mergeAll([obj, ...objs]);
}

export function isParent(outer, inner) {
  do {
    if (inner === outer)
      return true;

    inner = inner.parentNode;
  } while (inner)

  return false
}

export function randomItem(items) {
  return items[Math.floor(Math.random() * items.length)]
}


/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "14px verdana").
 *
 * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text, font) {
  // if given, use cached canvas for better performance
  // else, create new canvas
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);
  return metrics.width;
}


// https://stackoverflow.com/questions/28905965/textarea-how-to-count-wrapped-lines-rows
var _buffer;

export function countLines(
  content,
  textarea
) {
  if (_buffer == null) {
    _buffer = document.createElement('textarea');
    _buffer.style.border = 'none';
    _buffer.style.height = '0';
    _buffer.style.overflow = 'hidden';
    _buffer.style.padding = '0';
    _buffer.style.position = 'absolute';
    _buffer.style.left = '0';
    _buffer.style.top = '0';
    _buffer.style.zIndex = '-1';
    document.body.appendChild(_buffer);
  }

  var cs = window.getComputedStyle(textarea);
  var pl = parseInt(cs.paddingLeft);
  var pr = parseInt(cs.paddingRight);
  var lh = parseInt(cs.lineHeight);

  // [cs.lineHeight] may return 'normal', which means line height = font size.
  if (isNaN(lh)) lh = parseInt(cs.fontSize);

  // Copy content width.
  _buffer.style.width = (textarea.clientWidth - pl - pr) + 'px';

  // Copy text properties.
  _buffer.style.font = cs.font;
  _buffer.style.letterSpacing = cs.letterSpacing;
  _buffer.style.whiteSpace = cs.whiteSpace;
  _buffer.style.wordBreak = cs.wordBreak;
  _buffer.style.wordSpacing = cs.wordSpacing;
  _buffer.style.wordWrap = cs.wordWrap;

  // Copy value.
  _buffer.value = content;

  var result = Math.floor(_buffer.scrollHeight / lh);
  if (result === 0) result = 1;

  return result;
}

export const download = (path, filename) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;
  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};

export function validateEmail(email) {
  const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(String(email).toLowerCase());
}

export function isAcceptablePassword(password) {
  return password.length > 9 && uniq(password).length > 6;
}

export function isReactDevMode(){
  return '_self' in React.createElement('div');
}