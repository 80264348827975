import {Content} from "../../IppeUtils/IppeTypes";
import React from "react";
import DownloadIcon from '@mui/icons-material/Download';
import {ce, download} from "../../IppeUtils/MiscUtils";
import {lookupExtension} from "../../IppeUtils/MimeUtils";
import {AckingIconButton} from "../../IppeUtils/AckingIconButton";

export type DownloadButtonProps = {
  content: Content | undefined
}

export class DownloadButton extends React.Component<DownloadButtonProps, {}> {
  constructor(props: DownloadButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(): Promise<void> {
    return new Promise((resolve, reject) => {
      const {mimeType, content} = this.props.content || {};
      const extension = mimeType ? lookupExtension(mimeType) : undefined;
      const filename = `content${extension || ""}`
      const blob = new Blob([content!!], {type: mimeType});
      const url = URL.createObjectURL(blob);
      download(url, filename);
      URL.revokeObjectURL(url);

      resolve();
    });
  }

  render() {
    return ce(AckingIconButton, {
      toolTip: "Download",
      onClick: this.onClick,
      disabled: this.props.content === undefined,
      icon: DownloadIcon
    });
  }
}
