import {Content, createContent} from "../../IppeUtils/IppeTypes";
import React from "react";
import {CenterVertical} from "../../IppeUtils/MiscComponents";
import StyleBuilder from "../../IppeUtils/StyleBuilder";
import {FileUploader} from "react-drag-drop-files";
import {ce, merge} from "../../IppeUtils/MiscUtils";

export type ContentEditorFileUploaderProps = {
  mimeType: string | undefined,
  onValueChange: (value: Content) => void,
  style: React.CSSProperties
}

export class ContentEditorFileUploader extends React.Component<ContentEditorFileUploaderProps, any> {
  constructor(props: ContentEditorFileUploaderProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(file: File) {
    // TODO: are we just going to accept arbitrarily large files?  Seems like a bad idea...  and potentially bad for
    // history.  TODO: Not sure I trust file.type to produce good mime-types.
    file.arrayBuffer().then((content: ArrayBuffer) => {
      this.props.onValueChange(createContent(file.type, content))
    })
  }

  render() {
    const style = merge({
      paddingLeft: "2em",
      paddingRight: "2em",
    }, this.props.style);

    return ce(CenterVertical, {style},
      // TODO: FileUploader seems to be kind of buggy... can't update to newer version without updating to react 18
      ce(FileUploader, {
        multiple: false,
        // types: this.props.mimeType
        label: "Upload or drop a file.",
        classes: ["react-drop-box"],  // SEE NOTE IN index.css.
        handleChange: this.onChange,
      }));
  }
}