import {Content, createContent} from "../IppeUtils/IppeTypes";
import React, {ReactElement} from "react";
import {isMimeTypeEditable, MimeTypePickerComponent} from "./ContentEditor/ContentComponentMisc";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {FullScreenButton} from "./ActionButtons/FullScreenButton";
import {UploadButton} from "./ActionButtons/UploadButton";
import {
  Centered,
  CenterVertical,
  HorizontalSliver,
  HorizontalStack,
  VerticalSpacer,
  VerticalStack
} from "../IppeUtils/MiscComponents";
import {ce, merge} from "../IppeUtils/MiscUtils";
import {IppePreviewEvaluationStage} from "./IppePreviewEvaluator";
import {
  CODE_FONT_FAMILY,
  IPPE_CARD_BORDER_COLOR, IPPE_COMMAND_STYLE, IPPE_LIGHT_GREY_COLOR,
  IPPE_PREVIEW_CONTENT_BORDER, IPPE_SHALLOW_BOX_SHADOW
} from "../IppeUtils/IppeStyles";
import {readablizeBytes} from "../IppeUtils/MiscUtilsTs";
import {DownloadButton} from "./ActionButtons/DownloadButton";
import {ContentCopyButton} from "./ActionButtons/ContentCopyButton";
import {ShowWarningsButton} from "./ActionButtons/ShowWarningsButton";
import {IppeContentComponent} from "./IppeContentComponent";
import {IppeErrorComponent} from "../IppeErrors/IppeErrorComponent";
import {containsFatalErrors} from "../IppeUtils/IppeUtils";
import {IppeCommandViewer} from "../IppeEditor/IppeCommandViewerComponent";
import {parseIppePipelineNode} from "../IppeUtils/IppeParser";
import {Alert, AlertColor, CircularProgress, Tooltip} from "@mui/material";
import {removeSampleContentIdentifierFromUrl} from "./IppeSampleContentUtils";
import {SampleContentButton} from "./ActionButtons/SampleContentButton";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";


export type Dimensions = {
  top: number,
  left: number,
  width: number,
  height: number
}

export type SampleContentComponentProps = {
  nodeId: string,
  value: Content,
  onValueChange: (value: Content) => void,
  dimensions: Dimensions,
}

export type SampleContentComponentState = {
  anchorEl: HTMLButtonElement | undefined
}


export class SampleContentComponent extends React.Component<SampleContentComponentProps, SampleContentComponentState> {
  constructor(props: SampleContentComponentProps) {
    super(props);

    this.state = {anchorEl: undefined};
  }

  render() {
    const {value, onValueChange} = this.props;

    const onMimeTypeChange = (mimeType: string) => {
      removeSampleContentIdentifierFromUrl();
      onValueChange(createContent(mimeType, undefined));
    }

    const mimeTypePicker = ce(MimeTypePickerComponent, {
      onChange: onMimeTypeChange,
      value: value.mimeType,
      style: {flexGrow: 1, background: "white"},
    });

    const sampleContentButton = ce(SampleContentButton, {
      mimeType: value.mimeType,
      onChange: onValueChange
    });

    const onValueChangeWithSampleContentReset = isMimeTypeEditable(value.mimeType) ?
      (value: Content) => {
        onValueChange(value);
        removeSampleContentIdentifierFromUrl();
      } : undefined;

    const fullScreenButton = ce(FullScreenButton, {
      value: value,
      onChange: onValueChangeWithSampleContentReset,
      errors: []
    });

    // const pasteButton = ce(PasteButton);
    const uploadButton = ce(UploadButton, {
      onChange: (content) => {
        removeSampleContentIdentifierFromUrl();
        onValueChange(content)
      }
    });

    // const warningButton = ce(ShowWarningsButton); // TODO: warning if content doesn't seem to match content type
    const footer = ce(HorizontalStack, {style: StyleBuilder.start({justifyContent: "space-evenly"}).vPadding(5).build()},
      sampleContentButton, uploadButton, fullScreenButton);

    let body: React.ReactElement;

    if (!value.mimeType) {
      body = ce(Centered, {style: IPPE_PREVIEW_CONTENT_BORDER},
        ce(IppeMarkdownComponent, {codeCopy: false, style: {textAlign: "center", padding: 20, overflow: "scroll"}}, SAMPLE_CONTENT_COPY))
    } else {
      body = ce(IppeContentComponent, {
        mimeType: value.mimeType!!,
        content: value.content || new ArrayBuffer(0),
        contentHash: value.contentHash,
        onContentChange: (changedValue: ArrayBuffer) => {
          onValueChange(createContent(value.mimeType, changedValue));
          removeSampleContentIdentifierFromUrl();
        },
        errors: [],
        style: {}
      });
    }

    return ce(CommonStageComponent, {
      key: this.props.nodeId,
      nodeId: this.props.nodeId,
      dimensions: this.props.dimensions,
      // style: this.props.style,
      header: mimeTypePicker,
      body: body,
      footer: footer
    });
  }
}


export type CommandContentComponentProps = {
  nodeId: string,
  stage: IppePreviewEvaluationStage,
  dimensions: Dimensions
}

type CommandContentComponentState = {}


export class CommandContentComponent extends React.Component<CommandContentComponentProps, CommandContentComponentState> {
  constructor(props: CommandContentComponentProps) {
    super(props);
  }

  render() {
    const {stage} = this.props;

    const content = stage.output;
    const subExpression = stage.subExpression;
    const subExpressionElement = ce(IppeCommandViewer, {
      expression: subExpression,
      parsedExpression: parseIppePipelineNode(subExpression),
      style: StyleBuilder.start(CODE_FONT_FAMILY).singleLineEllipsizeText().build(),
    });

    const contentLengthBytes = content?.content?.byteLength || 0;
    const header = ce(VerticalStack, {},
      // @ts-ignore
      ce(Tooltip, {title: ce("div", {style: {fontFamily: CODE_FONT_FAMILY}}, subExpression)},
        ce("div", {}, subExpressionElement)),
      ce(VerticalSpacer, {thickness: ".35em"}),
      ce(HorizontalStack, {},
        ce("pre", {style: merge(CODE_FONT_FAMILY, {margin: 0})}, content?.mimeType || "unknown"),
        ce("div", {style: {display: "flex", flexGrow: 1}}),
        ce("pre", {style: merge(CODE_FONT_FAMILY, {margin: 0})}, `${readablizeBytes(contentLengthBytes)}`),
      ));

    // TODO: clean this mess up.
    let body: ReactElement;
    const createAltBody = (content: any, borderColor: string | undefined = undefined): React.ReactElement =>
      ce(CenterVertical, {
        style: merge(IPPE_PREVIEW_CONTENT_BORDER, {
          textAlign: "center",
          borderColor: borderColor || IPPE_PREVIEW_CONTENT_BORDER.borderColor,
          width: "100%",
          height: "100%",
          overflowY: "scroll"  // errors may be long
        })
      }, content);

    if (stage.aborted) {
      body = createAltBody(createAlert("Aborted due to previous errors.", "warning"), "orange");
    } else if (stage.pending) {
      body = createAltBody(ce(Centered, null, ce(CircularProgress)))
    } else if (containsFatalErrors(stage.errors)) {
      const errorComponents = stage.errors.map((error) => {
        return ce(IppeErrorComponent, {error, style: {width: "100%", maxWidth: "100%"}}, null);
      });

      body = createAltBody(ce(VerticalStack, {
        divider: ce(HorizontalSliver, {color: "white"}),
        style: StyleBuilder.start().height("100%").width("100%").build()
      }, errorComponents), "red");
    } else if (!content || !content.mimeType || !content.content || content.content.byteLength === 0) {
      body = stage.errors.length === 0
        ? createAltBody(createAlert("no content", "info"))
        : createAltBody(createAlert("no content", "warning"), "orange");
    } else {
      body = ce(IppeContentComponent, {
        mimeType: content.mimeType!!,
        content: content.content!!,
        contentHash: content.contentHash!!,
        onContentChange: undefined,
        errors: stage.errors,
        style: {}
      });
    }

    // TODO: download & copy should only be enabled if no errors for this stage... and there is actually output to be
    // downloaded.
    const downloadButton = ce(DownloadButton, {content: stage.output});
    const copyButton = ce(ContentCopyButton, {content: stage.output});

    const fullScreenButton = ce(FullScreenButton, {
      value: content,
      onChange: undefined,
      errors: stage.errors
    });

    const warningButton = ce(ShowWarningsButton, {errors: stage.errors});

    const footer = ce(HorizontalStack, {style: StyleBuilder.start({justifyContent: "space-evenly"}).vPadding(5).build()},
      downloadButton, copyButton, fullScreenButton, warningButton);

    return ce(CommonStageComponent, {
      key: this.props.nodeId,
      nodeId: this.props.nodeId,
      header, body, footer,
      dimensions: this.props.dimensions
    });
  }
}


type CommonStageComponentProps = {
  nodeId: string,
  header: React.ReactElement<any, any>,
  body: React.ReactElement<any, any>,
  footer: React.ReactElement<any, any>,
  dimensions: Dimensions
}

type CommonStageComponentState = {}


class CommonStageComponent extends React.Component<CommonStageComponentProps, CommonStageComponentState> {
  constructor(props: CommonStageComponentProps) {
    super(props);
  }

  render() {
    const {header, body, footer, dimensions} = this.props;
    const padding = 14;
    const nodeWidth = dimensions.width
    const nodeHeight = dimensions.height
    const contentWidth = nodeWidth - (padding * 2)

    const style = StyleBuilder.start()
      .position("absolute")
      .background(IPPE_LIGHT_GREY_COLOR)
      .roundedGrayBorder()
      .borderColor(IPPE_CARD_BORDER_COLOR)
      .leftTop(dimensions.left, dimensions.top)
      .height(nodeHeight - (padding * 2))
      .width(nodeWidth - padding * 2)
      .vPadding(padding)
      .hPadding(padding)
      .boxShadow(IPPE_SHALLOW_BOX_SHADOW)
      .build();

    const headerHeight = 60;
    const footerHeight = 45;

    const headerStyle = StyleBuilder.start()
      .width(contentWidth)
      .height(headerHeight)
      .build();

    const bodyStyle = StyleBuilder
      .start({flexGrow: 1})
      .width(contentWidth)
      .height(dimensions.height - headerHeight - footerHeight - (2 * padding))
      .background("white")
      .build();
    const footerStyle = StyleBuilder.start().height(footer).build();

    return ce(VerticalStack, {id: this.props.nodeId, spacing: ".5em", separator: ce(HorizontalSliver), style: style},
      ce("div", {style: headerStyle}, header),
      ce("div", {style: bodyStyle}, body),
      ce("div", {style: footerStyle}, footer))
  }
}

const SAMPLE_CONTENT_COPY = `
Test Input
----------

Use this area to experiment with sample input.  Upload your own sample, or select from a range of example inputs. 
`


function createAlert(
  message: string,
  severity: AlertColor
): ReactElement {
  return ce("div", {style: {height: "100%"}}, ce(Alert, {severity}, message));
}