import {
  Content,
  IppeCommandParsedNode,
  IppePipelineParsedNode,
  LinkError,
  ParseError,
  ExecError,
  createContent
} from "../IppeUtils/IppeTypes";
import axios from "axios";
import {getPostExecsUrl} from "../IppeUtils/IppeUrls";
import {decodeToString} from "../IppeUtils/ArrayBufferUtils";
import {accumulateParseErrors} from "../IppeUtils/IppeUtils";
import {merge} from "../IppeUtils/MiscUtils";
import {getMimeTypeFromAxiosResponse} from "../IppeUtils/MiscUtilsTs";


export type IppePreviewEvaluationStage = {
  subExpression: string,
  command: IppeCommandParsedNode,
  output: Content | undefined,
  errors: Array<ParseError | LinkError | ExecError>,
  pending: boolean,
  aborted: boolean  // due to previous errors
}

export class IppePreviewEvaluator {
  subscribers: Array<(stages: Array<IppePreviewEvaluationStage>) => void>
  abortController: AbortController | undefined  // LOWTODO: does this make sense?

  constructor() {
    this.subscribers = []
    this.abortController = undefined
  }

  update(
    expression: string,
    parsedPipeline: IppePipelineParsedNode,
    initialInput: Content | undefined,
  ) {
    this.cancel(); // safe even if there's not currently an update running.

    const stages: Array<IppePreviewEvaluationStage> = []
    const commands = parsedPipeline.commands
    let abort = false;
    let hitFirstPending = false;

    for (const command of commands) {
      const subExpression = expression.substring(command.startIdx, command.endIdx);

      if (abort) {
        stages.push({
          subExpression,
          command: command,
          errors: [],
          aborted: true,
          pending: false,
          output: undefined
        });

        continue
      }

      const parseErrors = accumulateParseErrors(command);
      if (parseErrors.length > 0) {
        stages.push({
          subExpression,
          command: command,
          errors: parseErrors,
          aborted: false,
          pending: false,
          output: undefined
        });

        abort = true
        continue
      }

      if (hitFirstPending) {
        stages.push({
          subExpression,
          command: command,
          errors: [],
          aborted: false,
          pending: true,
          output: undefined
        });

        continue
      }

      stages.push({
        subExpression,
        command: command,
        errors: [],
        aborted: false,
        pending: true,
        output: undefined
      });

      hitFirstPending = true
    }

    this.notifySubscribers(stages);
    this.updateOutputs(initialInput, stages)
  }

  private async updateOutputs(
    initialInput: Content | undefined,
    stages: Array<IppePreviewEvaluationStage>
  ) {
    console.log("Kicking off updateOutputs...")

    let abort = false

    for (let idx = 0; idx < stages.length; ++idx) {
      const stage = stages[idx];
      const command = stage.command;
      const subExpression = stage.subExpression

      if (stage.aborted || abort) {
        console.log("Skipping aborted stage for " + subExpression)

        stages[idx] = merge(stages[idx], {
          pending: false,
          aborted: true
        });
      } else {
        console.log("Resolving pending stage " + subExpression)

        const nextInput = idx === 0 ? initialInput : stages[idx - 1].output;

        // axios strips the content type header if the body null / undefined.
        const postBody = nextInput?.content ?? new ArrayBuffer(0)

        // empty string because null sends as "null".  Similar for undefined.
        const headers = {"Content-Type": nextInput?.mimeType ?? ""};

        this.abortController = new AbortController()
        const response = (await axios.post<ArrayBuffer>(getPostExecsUrl(subExpression, true), postBody, {
          signal: this.abortController.signal,
          headers: headers as any, responseType: 'arraybuffer',
          validateStatus: () => true, // otherwise, axios will throw exception on non-200 responses.
        }));
        this.abortController = undefined

        if (response.status >= 200 && response.status <= 299) {
          console.log("Successfully resolved pending stage " + subExpression)

          const errors: Array<ParseError | LinkError | ExecError> = []
          const warningAndErrorHeaders = Object.entries(response.headers)
            .filter(([k, _]) => k.startsWith("x-ippe-warning") || k.startsWith("x-ippe-error"))
            .flatMap(([_, v]) => v)

          warningAndErrorHeaders.forEach((rawWarning) => {
            const [type, rawStartIdx, rawEndIdx, ...message] = rawWarning.split("|")
            const startIdx = !isNaN(parseInt(rawStartIdx)) ? parseInt(rawStartIdx) : undefined;
            const endIdx = !isNaN(parseInt(rawEndIdx)) ? parseInt(rawEndIdx) : undefined;
            errors.push(startIdx !== undefined && endIdx !== undefined ?
              {startIdx, endIdx, description: message.join("|"), isFatal: false} :
              {description: message.join("|"), isFatal: false})
          })

          stages[idx] = merge(stages[idx], {
            pending: false,
            aborted: false,
            errors,
            // TODO: current impl strips char encoding.
            output: createContent(getMimeTypeFromAxiosResponse(response), response.data)
          });
        } else {
          console.log("Errors resolved pending stage " + subExpression)

          let errors: Array<ParseError | LinkError | ExecError> = []

          try {
            const errorsBody = (JSON.parse(decodeToString(response.data))) as any;
            errors = (errorsBody?.parseErrors ?? []).concat(errorsBody?.linkErrors ?? []).concat(errorsBody?.execErrors ?? [])
          } catch (e) {
            console.log(e);
            errors = [{description: "Unknown runtime error", isFatal: true}];
          }

          stages[idx] = merge(stages[idx], {
            errors: errors,
            pending: false,
            aborted: false,
            output: undefined
          });

          abort = true
        }
      }

      this.notifySubscribers(stages);
    }
  }

  cancel() {
    if (this.abortController !== undefined) {
      console.log("Cancelling preview evaluation")
      this.abortController.abort()
      this.abortController = undefined
    }
  }

  subscribe(listener: (stages: Array<IppePreviewEvaluationStage>) => void) {
    this.subscribers.push(listener)
  }

  unsubscribe(listener: (stages: Array<IppePreviewEvaluationStage>) => void) {
    this.subscribers.pop() // TODO
  }

  private notifySubscribers(stages: Array<IppePreviewEvaluationStage>) {
    this.subscribers.forEach((subscriber) => {
      // defensively copy the array so that nobody else can screw with the evaluator's internal state, but also
      // because consumers are taking this array and storing it as react state, so if we continue to update (directly)
      // an array react component's are storing as state, then wierd bugs arise.
      subscriber([...stages]);
    });
  }
}