import React from "react";
import {ce, merge} from "../../IppeUtils/MiscUtils";
import {CODE_FONT_FAMILY, CODE_FONT_FAMILY_BOLD} from "../../IppeUtils/IppeStyles";
import {ContentComponentProps} from "./ContentComponentMisc";
import {decodeToString, encodeToBuffer} from "../../IppeUtils/ArrayBufferUtils";
import CodeEditor from "@uiw/react-textarea-code-editor";
import {TextPlainContentComponent} from "./TextPlainContentComponent";

// CodeEditor hangs when trying to present large content.
const MAX_CODE_EDITOR_SIZE_BYTES = 1024 * 10;  // 10 KB

interface StringToStringMap {
  [key: string]: string
}

const CONTENT_TYPE_TO_LANGUAGE: StringToStringMap = {
  "application/csv": "csv",
  "application/json": "json",
  "application/x-ndjson": "json",
  "application/x-ldjson": "json",
  "application/ndjson": "json",
  "application/ldjson": "json",
  "application/xml": "xml",
  "application/yaml": "yaml",
  "application/x-yaml": "yaml"
};

export class GeneralCodeContentComponent extends React.Component<ContentComponentProps, {}> {
  constructor(props: ContentComponentProps) {
    super(props);
  }

  render() {
    const wrapperStyle = merge({
      overflow: "scroll",
      height: "calc(100% - 5px)", // need to account for padding
      width: "calc(100% - 5px)",
      paddingTop: 5,
      paddingLeft: 5
    }, this.props.style);

    // LOWTODO: present an indicator that code-styling is disabled due to size.
    const tooBigForCodeEditor = (this.props.content.byteLength > MAX_CODE_EDITOR_SIZE_BYTES);
    return tooBigForCodeEditor ? ce(TextPlainContentComponent, this.props) :
      ce("div", {style: wrapperStyle},
        createCodeEditor(this.props.mimeType, this.props.content, this.props.onContentChange))
  }
}

function createCodeEditor(
  mimeType: string,
  content: ArrayBuffer,
  onContentChange: undefined | ((value: ArrayBuffer) => void)
): React.ReactElement {
  const language = CONTENT_TYPE_TO_LANGUAGE[mimeType];

  const editorStyle = merge(CODE_FONT_FAMILY, {
    width: "max-content",
    minWidth: "100%",
    minHeight: "100%",
    background: "transparent",
    resize: "none",
    border: "none",
    whiteSpace: "nowrap",
    fontSize: "1em"
  });

  return ce(CodeEditor, {
    value: decodeToString(content),
    padding: 0,
    language,
    disabled: onContentChange === undefined,
    onChange: (e: any) => onContentChange?.(encodeToBuffer(e.target.value)),
    style: editorStyle
  })
}