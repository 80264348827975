type  Entry = {
  ext: string,
  mt: string
}

export const MIME_TYPES: Array<Entry> = [
  // Derived from https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  {"ext": ".aac", "mt": "audio/aac"},
  {"ext": ".abw", "mt": "application/x-abiword"},
  {"ext": ".arc", "mt": "application/x-freearc"},
  {"ext": ".avif", "mt": "image/avif"},
  {"ext": ".avi", "mt": "video/x-msvideo"},
  {"ext": ".azw", "mt": "application/vnd.amazon.ebook"},
  {"ext": ".bin", "mt": "application/octet-stream"},
  {"ext": ".bmp", "mt": "image/bmp"},
  {"ext": ".bz", "mt": "application/x-bzip"},
  {"ext": ".bz2", "mt": "application/x-bzip2"},
  {"ext": ".cda", "mt": "application/x-cdf"},
  {"ext": ".csh", "mt": "application/x-csh"},
  {"ext": ".css", "mt": "text/css"},
  {"ext": ".csv", "mt": "text/csv"},
  {"ext": ".doc", "mt": "application/msword"},
  {"ext": ".docx", "mt": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"},
  {"ext": ".eot", "mt": "application/vnd.ms-fontobject"},
  {"ext": ".epub", "mt": "application/epub+zip"},
  {"ext": ".gz", "mt": "application/gzip"},
  {"ext": ".gif", "mt": "image/gif"},
  {"ext": ".htm", "mt": "text/html"},
  {"ext": ".html", "mt": "text/html"},
  {"ext": ".ico", "mt": "image/vnd.microsoft.icon"},
  {"ext": ".ics", "mt": "text/calendar"},
  {"ext": ".jar", "mt": "application/java-archive"},
  {"ext": ".jpeg", "mt": "image/jpeg"},
  {"ext": ".jpg", "mt": "image/jpg"},
  {"ext": ".js", "mt": "text/javascript"},
  {"ext": ".json", "mt": "application/json"},
  {"ext": ".jsonld", "mt": "application/ld+json"},
  {"ext": ".mid .midi", "mt": "audio/midi audio/x-midi"},
  {"ext": ".mjs", "mt": "text/javascript"},
  {"ext": ".mp3", "mt": "audio/mpeg"},
  {"ext": ".mp4", "mt": "video/mp4"},
  {"ext": ".mpeg", "mt": "video/mpeg"},
  {"ext": ".mpkg", "mt": "application/vnd.apple.installer+xml"},
  {"ext": ".odp", "mt": "application/vnd.oasis.opendocument.presentation"},
  {"ext": ".ods", "mt": "application/vnd.oasis.opendocument.spreadsheet"},
  {"ext": ".odt", "mt": "application/vnd.oasis.opendocument.text"},
  {"ext": ".oga", "mt": "audio/ogg"},
  {"ext": ".ogv", "mt": "video/ogg"},
  {"ext": ".ogx", "mt": "application/ogg"},
  {"ext": ".opus", "mt": "audio/opus"},
  {"ext": ".otf", "mt": "font/otf"},
  {"ext": ".png", "mt": "image/png"},
  {"ext": ".pdf", "mt": "application/pdf"},
  {"ext": ".php", "mt": "application/x-httpd-php"},
  {"ext": ".ppt", "mt": "application/vnd.ms-powerpoint"},
  {"ext": ".pptx", "mt": "application/vnd.openxmlformats-officedocument.presentationml.presentation"},
  {"ext": ".rar", "mt": "application/vnd.rar"},
  {"ext": ".rtf", "mt": "application/rtf"},
  {"ext": ".sh", "mt": "application/x-sh"},
  {"ext": ".svg", "mt": "image/svg+xml"},
  {"ext": ".swf", "mt": "application/x-shockwave-flash"},
  {"ext": ".tar", "mt": "application/x-tar"},
  {"ext": ".tiff", "mt": "image/tiff"},
  {"ext": ".tif", "mt": "image/tiff"},
  {"ext": ".ts", "mt": "video/mp2t"},
  {"ext": ".ttf", "mt": "font/ttf"},
  {"ext": ".txt", "mt": "text/plain"},
  {"ext": ".vsd", "mt": "application/vnd.visio"},
  {"ext": ".wav", "mt": "audio/wav"},
  {"ext": ".weba", "mt": "audio/webm"},
  {"ext": ".webm", "mt": "video/webm"},
  {"ext": ".webp", "mt": "image/webp"},
  {"ext": ".woff", "mt": "font/woff"},
  {"ext": ".woff2", "mt": "font/woff2"},
  {"ext": ".xhtml", "mt": "application/xhtml+xml"},
  {"ext": ".xls", "mt": "application/vnd.ms-excel"},
  {"ext": ".xlsx", "mt": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
  {"ext": ".xml", "mt": "application/xml"},
  {"ext": ".xul", "mt": "application/vnd.mozilla.xul+xml"},
  {"ext": ".zip", "mt": "application/zip"},
  {"ext": ".3gp", "mt": "video/3gpp"},
  {"ext": ".3g2", "mt": "video/3gpp2"},
  {"ext": ".7z", "mt": "application/x-7z-compressed"},

  // ADDED BY PAUL
  {"ext": ".ndjson", "mt": "application/x-ndjson"},
  {"ext": ".ndjson", "mt": "application/ndjson"},
  {"ext": ".ndjson", "mt": "application/ldjson"},
  {"ext": ".ndjson", "mt": "application/x-ldjson"},

  {"ext": ".yaml", "mt": "application/x-yaml"},
]

export function lookupExtension(mimeType: string): string | undefined {
  const mt = mimeType.split(";")[0].toLowerCase()
  return MIME_TYPES.find(e => e.mt === mt)?.ext
}