import React from "react";
import {ce, merge} from "./MiscUtils";
import {IPPE_PRIMARY_COLOR, IPPE_SMALL_BUTTON_STYLE} from "./IppeStyles";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StyleBuilder from "./StyleBuilder";
import {pick} from "ramda";

export type OpenNewWindowButtonProps = {
  url: string,
  style: React.CSSProperties,
}

export class OpenNewWindowButton extends React.Component<OpenNewWindowButtonProps, any> {
  render() {
    const style = merge(StyleBuilder.start().textColor(IPPE_PRIMARY_COLOR).size(24).build(), this.props.style)
    return ce("a", {
      href: this.props.url,
      target: "_blank",
      style
    }, ce(OpenInNewIcon, {style: pick(["width", "height"], style)}));
  }
}