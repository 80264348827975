import '../App.css';
import React from "react";
import {IppeShellComponent} from "../IppeShell/IppeShellComponent";
import {HorizontalSliver, HorizontalStack, VerticalSliver, VerticalStack} from "../IppeUtils/MiscComponents";
import {getDryrunsUrl, getListToolsUrl, getSampleContentUrl} from "../IppeUtils/IppeUrls";
import axios from "axios";
import {DEFAULT_PREVIEW_ZOOM_LEVEL, IppePreviewComponent} from "../IppePreview/IppePreviewComponent";
import {debounce} from "../IppeUtils/MiscUtils";
import {
  Content,
  Dryrun,
  IppeToolSpec,
  IppeCursorPosition,
  createContent,
  IppePipelineParsedNode
} from "../IppeUtils/IppeTypes";
import {IppeErrorsSnackbar} from "../IppeErrors/IppeErrorsSnackbar";
import {INITIAL_IPPE_ASSIST_WIDTH, IppeAssistComponent} from "../IppeAssist/IppeAssistComponent";
import {IppeToolbarComponent} from "../IppeToolbar/IppeToolbarComponent";
import {
  IPPE_CONTENT_AREA_HEIGHT,
  IPPE_LIGHT_GREY_COLOR,
  IPPE_PRIMARY_COLOR
} from "../IppeUtils/IppeStyles";
import StyleBuilder from '../IppeUtils/StyleBuilder';
import {IppeArgEditorComponent} from "../IppeArgEditor/IppeArgEditor";
import {parseIppePipelineNode} from "../IppeUtils/IppeParser";
import {stringifyPipeline} from "../IppeUtils/IppeUtils";
import {
  getMimeTypeFromAxiosResponse,
  hashString,
  updateURLParameter
} from "../IppeUtils/MiscUtilsTs";
import {IppePreviewEvaluationStage, IppePreviewEvaluator} from "../IppePreview/IppePreviewEvaluator";
import {removeSampleContentIdentifierFromUrl} from "../IppePreview/IppeSampleContentUtils";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {IPPE_LOGIN_MANAGER} from "../IppeLogin/IppeLoginManager";
import {WarningBanner} from "../IppeUtils/WarningBanner";
import {Warning} from "@mui/icons-material";

const ce = React.createElement;
const MAX_PREVIEW_CONTENT_SIZE_MB = 25;
const LOGIN_MANAGER_TAG = "ippe-builder";

type IppeBuilderProps = {}

type IppeBuilderState = {
  tools: Array<IppeToolSpec>
  currentExpression: string,
  parsedExpression: IppePipelineParsedNode,
  cursorPosition: IppeCursorPosition | undefined
  previewInput: Content
  dryrun: Dryrun | undefined  // TODO: implement linker in javascript so this is no longer necessary.
  dryrunCancelToken: any | undefined  // LOWTODO: does this make sense?
  stages: Array<IppePreviewEvaluationStage>,
  warning: React.ReactElement | string | undefined,
  windowWidth: number,
  ippeAssistWidth: number,
  lastUpdateHash: number,  // used to determine if the preview is stale.
  currPreviewZoomLevel: number,
}

class IppeBuilderComponent extends React.Component<IppeBuilderProps, IppeBuilderState> {
  private evaluator = new IppePreviewEvaluator();

  constructor(props: IppeBuilderProps) {
    super(props);
    this.state = {
      tools: [],
      currentExpression: "",
      lastUpdateHash: 0,
      parsedExpression: parseIppePipelineNode(""),
      previewInput: createContent(undefined, undefined),
      dryrun: undefined,
      dryrunCancelToken: null,
      cursorPosition: undefined,
      stages: [],
      warning: undefined,
      windowWidth: Math.max(window.innerWidth, document.body.offsetWidth),
      ippeAssistWidth: INITIAL_IPPE_ASSIST_WIDTH,
      currPreviewZoomLevel: DEFAULT_PREVIEW_ZOOM_LEVEL
    }

    this.onCurrExpressionChange = this.onCurrExpressionChange.bind(this);
    this.onCursorChange = this.onCursorChange.bind(this);
    this.onCurrExpressionSubmit = this.onCurrExpressionSubmit.bind(this);
    this.onCurrExpressionReset = this.onCurrExpressionReset.bind(this);
    this.onPreviewInputChange = this.onPreviewInputChange.bind(this);
    this.dryrun = debounce(this.dryrun.bind(this), 2000);
    this.onResize = debounce(this.onResize.bind(this), 50);
    this.onStagesChange = this.onStagesChange.bind(this);
  }

  onResize() {
    this.setState({windowWidth: Math.max(window.innerWidth, document.body.offsetWidth)});
  }

  onCurrExpressionChange(expression: string) {
    this.setState({
      // cursorPosition: undefined,
      currentExpression: expression,
      parsedExpression: parseIppePipelineNode(expression),
    }, this.dryrun)
  }

  onCurrExpressionSubmit() {
    if (!IPPE_LOGIN_MANAGER.isLoggedIn())
      return

    this.setState({lastUpdateHash: calcHash(this.state.currentExpression, this.state.previewInput)});

    this.evaluator.update(this.state.currentExpression, this.state.parsedExpression,
      this.state.previewInput);
  }

  onCurrExpressionReset() {
    this.evaluator.cancel();

    removeSampleContentIdentifierFromUrl();
    this.setState({
      cursorPosition: undefined,
      lastUpdateHash: 0,
      currentExpression: "",
      parsedExpression: parseIppePipelineNode(""),
      previewInput: createContent(undefined, undefined),
      dryrun: undefined,
      stages: [],
    });
  }

  onCursorChange(position: IppeCursorPosition | undefined) {
    this.setState({cursorPosition: position})
  }

  onPreviewInputChange(previewInput: Content, evaluate?: boolean) {
    const previewNumBytes = previewInput.content?.byteLength ?? 0;
    const maxPreviewSizeBytes = MAX_PREVIEW_CONTENT_SIZE_MB * 1024 * 1024;
    if (previewNumBytes > maxPreviewSizeBytes) {
      this.flashWarning(ce(IppeMarkdownComponent, {},
        `Dashboard limits preview content to ${MAX_PREVIEW_CONTENT_SIZE_MB} MB.`));
      return;
    }


    this.setState({previewInput}, () => {
      if (evaluate) this.onCurrExpressionSubmit()
    });
  }

  flashWarning(warning: string | React.ReactElement) {
    const clearWarning = () => {this.setState({warning: undefined})}
    this.setState({warning}, () => {setTimeout(clearWarning, 5000)})
  }

  dryrun() {
    if (!IPPE_LOGIN_MANAGER.isLoggedIn()) {
      console.log("Not running dry-run because not logged in.")
      return;
    }

    if (this.state.dryrunCancelToken) {
      console.log("Canceling...")
      this.state.dryrunCancelToken.cancel("Canceling request");
    }

    if (this.state.currentExpression.length >= 3) {
      let dryrunCancelToken = axios.CancelToken.source();
      this.setState({dryrunCancelToken})

      console.log(`Dry-running ${this.state.currentExpression}`)
      axios.get(getDryrunsUrl(this.state.currentExpression, this.state.previewInput.mimeType),
        {cancelToken: dryrunCancelToken.token}
      ).then((response) => {
        console.log("Saving result of dry-run")
        const dryrun = response.data as Dryrun;

        const s1 = stringifyPipeline(dryrun.parsedPipeline).join("\n")

        const p2 = parseIppePipelineNode(this.state.currentExpression)
        const s2 = stringifyPipeline(p2).join("\n")
        if (s1 !== s2) {
          // alert("MISMATCH")
          console.log(s1);
          console.log("---");
          console.log(s2);
        } else {
          console.log("SUCCESS")
        }

        this.setState({dryrunCancelToken: undefined, dryrun})
      }).catch((error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403))
          IPPE_LOGIN_MANAGER.logout() // we must not actually be logged in.
      });

    } else {
      this.setState({dryrunCancelToken: undefined, dryrun: undefined});
    }
  }

  onStagesChange(stages: Array<IppePreviewEvaluationStage>) {
    this.setState({stages});
  }

  componentDidMount() {
    this.evaluator.subscribe(this.onStagesChange);

    window.addEventListener("resize", this.onResize);

    let queryParams = new URLSearchParams(window.location.search);

    let expression = queryParams.get("expression");
    if (expression)
      this.setState({currentExpression: expression, parsedExpression: parseIppePipelineNode(expression)})

    axios.get(getListToolsUrl()).then((response) => {
      this.setState({tools: response.data.data})
    });

    const sampleInputId = queryParams.get("input_id");
    const mimeType = queryParams.get("mime_type");
    if (sampleInputId) {
      axios.get(getSampleContentUrl(sampleInputId), {responseType: "arraybuffer"}).then(response => {
        this.onPreviewInputChange(createContent(getMimeTypeFromAxiosResponse(response), response.data), true);
      });
    } else if (mimeType) {
      this.setState({previewInput: createContent(mimeType, undefined)},
        this.onCurrExpressionSubmit);
    } else {
      // this is a bit of a hack...  but this.onCurrExpressionSubmit can't run until the state set above
      // is flushed.
      this.setState({}, this.onCurrExpressionSubmit);
    }

    this.dryrun();

    IPPE_LOGIN_MANAGER.registerOnLoginHandler(LOGIN_MANAGER_TAG, () => { this.forceUpdate() });
    IPPE_LOGIN_MANAGER.registerOnLogoutHandler(LOGIN_MANAGER_TAG, () => { this.forceUpdate() });
  }

  componentDidUpdate(prevProps: Readonly<IppeBuilderProps>, prevState: Readonly<IppeBuilderState>, snapshot?: any) {
    if (this.state.currentExpression !== prevState.currentExpression) {
      window.history.replaceState({}, 'Title', updateURLParameter(window.location.href, "expression", this.state.currentExpression));
    }

    if (this.state.previewInput?.mimeType !== prevState.previewInput?.mimeType) {
      window.history.replaceState({}, 'Title',
        updateURLParameter(window.location.href, "mime_type", this.state.previewInput?.mimeType ?? undefined));
    }
  }

  componentWillUnmount() {
    // TODO: confirm that this actually removes.
    window.removeEventListener("resize", this.onResize)
    this.evaluator.unsubscribe(this.onStagesChange);

    IPPE_LOGIN_MANAGER.unregisterOnLogoutHandler(LOGIN_MANAGER_TAG);
    IPPE_LOGIN_MANAGER.unregisterOnLoginHandler(LOGIN_MANAGER_TAG);
  }

  render() {
    const shellComponent = ce(IppeShellComponent, {
      // execs: this.state.execs,
      currentExpression: this.state.currentExpression,
      parsedExpression: this.state.parsedExpression,
      onExpressionChange: this.onCurrExpressionChange,
      onExpressionSubmit: this.onCurrExpressionSubmit,
      onExpressionReset: this.onCurrExpressionReset,
      onCursorChange: this.onCursorChange,
      tools: this.state.tools,
      previewContent: this.state.previewInput,
      previewLoading: this.state.stages.find(stage => stage.pending) !== undefined
    });

    // LOWTODO: consider some smarter alternatives for disabling copy-as
    // const errorCount = this.state.dryrun ? allErrors(this.state.dryrun).length : 0;
    // const errorCount = accumulateParseErrors(parseIppePipelineNode(this.state.currentExpression)).length;
    const ippeToolbar = ce(IppeToolbarComponent, {
      expression: this.state.currentExpression,
      parsedExpression: this.state.parsedExpression,
      mimeType: this.state.previewInput?.mimeType,
      currPreviewZoomLevel: this.state.currPreviewZoomLevel,
      onPreviewZoomLevelChanged: (level) => { this.setState({currPreviewZoomLevel: level})}
    });

    const previewWidth = this.state.windowWidth - this.state.ippeAssistWidth;
    const previewComponent = ce(IppePreviewComponent, {
      tools: this.state.tools,
      previewInput: this.state.previewInput,
      onPreviewInputChange: this.onPreviewInputChange,
      stages: this.state.stages,
      currPreviewZoomLevel: this.state.currPreviewZoomLevel,
      widthPx: previewWidth
    });

    // const errorsSnackbar = this.state.dryrun ?
    //   ce(IppeErrorsSnackbar, {dryrun: this.state.dryrun}) :
    //   null;

    const ippeAssist = ce(IppeAssistComponent, {
      cursorPosition: this.state.cursorPosition,
      tools: this.state.tools,
      expression: this.state.currentExpression,
      parsedExpression: this.state.parsedExpression,
      onWidthChange: (ippeAssistWidth) => this.setState({ippeAssistWidth}),
      width: this.state.ippeAssistWidth
    });

    const ippeArgEditor = ce(IppeArgEditorComponent, {
      cursorPosition: this.state.cursorPosition,
      tools: this.state.tools,
      expression: this.state.currentExpression,
      onExpressionChange: this.onCurrExpressionChange,
      parsedExpression: this.state.parsedExpression,
    });


    const isStale = calcHash(this.state.currentExpression, this.state.previewInput) !== this.state.lastUpdateHash;
    const staleWarning = isStale ?
      ce(WarningBanner, {}, ce(StalePreviewWarning, {onReload: this.onCurrExpressionSubmit})) :
      null;

    const badCredsWarning = IPPE_LOGIN_MANAGER.isLoggedIn() ? undefined :
      ce(WarningBanner, {width: previewWidth}, ce(BadCredsWarning));

    const warning = this.state.warning ? ce(WarningBanner, {}, this.state.warning) : undefined;

    const previewFrameStyle = {
      position: "relative",
      display: "flex",
      flexGrow: 1,
      background: "white",
      width: previewWidth,
      // overflow: "scroll"
    };

    const wrapperStyle = StyleBuilder.start()
      .width(this.state.windowWidth)
      .height(IPPE_CONTENT_AREA_HEIGHT)
      .build()
    return ce(VerticalStack, {style: wrapperStyle},
      ce("div", {style: {background: IPPE_LIGHT_GREY_COLOR}}, shellComponent),
      ce(HorizontalSliver, {thickness: 1, color: IPPE_PRIMARY_COLOR}),
      ce("div", {style: {background: "white", padding: 0}}, ippeToolbar),
      ce(HorizontalSliver, {thickness: 1.5, color: IPPE_PRIMARY_COLOR}),
      ce(HorizontalStack, {style: {flexGrow: 1, overflow: "hidden"}}, ippeAssist,
        ce(VerticalSliver, {thickness: 1.5, color: IPPE_PRIMARY_COLOR}),
        ce("div", {style: previewFrameStyle},
          ce("div", {style: {zIndex: 3}}, badCredsWarning ?? warning ?? staleWarning),
          ce("div", {style: {overflow: "scroll", width: previewWidth}}, previewComponent))),
      // errorsSnackbar,
      ippeArgEditor);
  }
}

export default IppeBuilderComponent;


function BadCredsWarning() {
  const onClick = () => { IPPE_LOGIN_MANAGER.requestLogin() };

  return ce("div", {},
    "Please ",
    ce("span", {style: {textDecoration: "underline", cursor: "pointer"}, onClick}, "login"),
    ".");
}

type StaleWarningProps = { onReload: () => void }

function StalePreviewWarning(props: StaleWarningProps) {
  return ce("span", {style: {textDecoration: "underline", cursor: "pointer"}, onClick: props.onReload}, "Reload Preview");
}

function calcHash(
  expression: string,
  input: Content
): number {
  return hashString(expression) + input.contentHash;
}