import React from "react";
import {IPPE_COPY_AS_FACTORY} from "./CopyAsMisc";
import {ce} from "../IppeUtils/MiscUtils";
import {Box, Modal} from "@mui/material";
import {HorizontalSliver, VerticalStack} from "../IppeUtils/MiscComponents";
import {IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import {IppePipelineParsedNode} from "../IppeUtils/IppeTypes";
import StyleBuilder from "../IppeUtils/StyleBuilder";


export type IppeCopyAsModalComponentProps = {
  copyAsType: string,
  // style: React.CSSProperties,
  mimeType: string | undefined,
  expression: string,
  parsedExpression: IppePipelineParsedNode,
  onClose: () => void
}

type IppeCopyAsModalComponentState = {
  functionName: string
}


const MODAL_STYLE = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderColor: IPPE_PRIMARY_COLOR,
  boxShadow: 24,
  maxHeight: "90vh",
  maxWidth: "80vw"
};


export class IppeCopyAsModalComponent extends React.Component<IppeCopyAsModalComponentProps, IppeCopyAsModalComponentState> {
  constructor(props: IppeCopyAsModalComponentProps) {
    super(props);
    this.state = {functionName: ""}
    this.onFunctionNameChange = this.onFunctionNameChange.bind(this);
  }

  onFunctionNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({functionName: event.target.value.replace(/[^a-zA-Z0-9_]/, "")});
  }

  render() {
    const {mimeType, expression, parsedExpression} = this.props;

    const copyAsComponent = ce(IPPE_COPY_AS_FACTORY[this.props.copyAsType],
      {mimeType, expression, parsedExpression, style: {}});

    return ce(Modal, {open: true, onClose: this.props.onClose} as any,  // TODO: shouldn't need to cast
      ce(Box, {sx: MODAL_STYLE}, ce(VerticalStack, {divider: ce(HorizontalSliver), style: {maxHeight: "inherit", maxWidth: "inherit"}},
        ce("h1", {style: StyleBuilder.start().hPadding("30px").build()}, `Copy as ${this.props.copyAsType}`),
        // ce("span", {}, "Give your function a name:"),
        ce("div", {style: StyleBuilder.start().hPadding("30px").vPadding("20px").overflowScroll().build()}, copyAsComponent))));
  }
}
