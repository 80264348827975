export function base64Encode(buffer: ArrayBuffer): string {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;

  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64Decode(base64: string): ArrayBuffer {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);

  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

const ENCODER = new TextEncoder();

export function encodeToBuffer(str: string): ArrayBuffer {
  return ENCODER.encode(str);
}

const DECODER = new TextDecoder("utf-8");

export function decodeToString(buffer: ArrayBuffer): string {
  return DECODER.decode(buffer);
}

export function buffersEquivalent(
  left: ArrayBuffer | undefined,
  right: ArrayBuffer | undefined
): boolean {
  // TODO: is there a less hacky way to do this?
  return (left !== undefined && right !== undefined && base64Encode(left) === base64Encode(right)) || (left === right);
}