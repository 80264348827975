import React from "react";
import {InputAdornment, OutlinedInput, Snackbar, TextField} from "@mui/material";
import {ce} from "./MiscUtils";
import {CopyButton} from "./CopyButton";
import {MONO_FONT_FAMILY} from "./IppeStyles";


export type TextCopyFieldProps = {
  label: string,
  value: string,
  subtext?: string
}


export class TextCopyFieldComponent extends React.Component<TextCopyFieldProps, {}> {
  render() {
    return ce(TextField, {
      disabled: true,
      sx: {
        "& .Mui-disabled": {
          WebkitTextFillColor: "black",
        },
        "& .MuiInputBase-input.Mui-disabled": {
          fontFamily: MONO_FONT_FAMILY.fontFamily
        }
      },
      label: this.props.label,
      value: this.props.value,
      fullWidth: true,
      variant: "standard",
      helperText: this.props.subtext,
      InputProps: {
        endAdornment: ce(InputAdornment, {
          position: "end"
        }, ce(CopyButton, {
          content: this.props.value,
          compact: true,
          style: {}
        }))
      }
    })
  }
}