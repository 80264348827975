import {CopyAsComponentProps, PlainTextComponent, SnippetComponent} from "./CopyAsMisc";
import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {IPPE_HOST} from "../IppeUtils/IppeUrls";
import {VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {TextField} from "@mui/material";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {IppeAccessKeyPicker} from "../IppeAccessKeys/IppeAccessKeyPickerComponent";
import {AccessKeyResponse} from "../IppeUtils/IppeTypes";


type CopyAsCurlComponentState = {
  functionName: string,
  accessKey: AccessKeyResponse | undefined
}

export class CopyAsCurlComponent extends React.Component<CopyAsComponentProps, CopyAsCurlComponentState> {
  constructor(props: CopyAsComponentProps) {
    super(props);

    this.state = {
      functionName: "",
      accessKey: undefined
    };

    this.onFunctionNameChange = this.onFunctionNameChange.bind(this);
  }

  onFunctionNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({functionName: event.target.value.replace(/[^a-zA-Z0-9_]/, "")});
  }

  render() {
    const functionName = this.state.functionName || "ippe_function";
    const onAccessKeyChange = (accessKey: AccessKeyResponse) => this.setState({accessKey})

    return ce(VerticalStack, {spacing: "1.5em", style: StyleBuilder.start().size("inherit").build()},
      ce(IppeMarkdownComponent, null, createIntroSnippet()),
      ce(TextField, {label: "Function Name", onChange: this.onFunctionNameChange, value: this.state.functionName || ""}),
      ce(IppeAccessKeyPicker, {nameHint: this.state.functionName, accessKey: this.state.accessKey, onChange: onAccessKeyChange}),
      ce(IppeMarkdownComponent, {codeCopy: true},
        createBodySnippet(functionName, this.props.expression, this.props.mimeType, this.state.accessKey)));
  }
}


function createIntroSnippet() {
  return `
This snippet provides the capability defined by your Ippe expression to your shell.

The function accepts input on stdin, and produces output to stdout.  It will return a 0 exit code on success.

It is compatible \`sh\`, \`bash\`, and \`zsh\`, and it depends on \`curl\`.`
}

function createBodySnippet(
  name: string,
  expression: string,
  mimeType: string | undefined,
  accessKey: AccessKeyResponse | undefined,
): string {
  const contentTypeHeader = mimeType === undefined ? "" : `-H "Content-Type: ${mimeType}" \\\n    `
  const accessKeyIdValue = accessKey?.accessKeyId || "[access key id]"
  const accessKeySecretValue = accessKey?.accessKeySecret || "[access key secret]"

  return `
~~~bash  
function ${name} 
{
  IPPE_ACCESS_KEY_ID="${accessKeyIdValue}"
  IPPE_ACCESS_KEY_SECRET="${accessKeySecretValue}"
  
  curl -XPOST --fail \\
    "${IPPE_HOST}/api/v0/execs?expression=${encodeURIComponent(expression)}" \\
     -H "Access-Key-ID: $\{IPPE_ACCESS_KEY_ID\}" \\
     -H "Access-Key-Secret: $\{IPPE_ACCESS_KEY_SECRET\}" \\
     ${contentTypeHeader} --data-binary @-
}
~~~

Examples:

~~~
${name} < some_file.in > some_file.out
~~~

~~~
cat some_file.in | ${name} | md5sum
~~~

`
}
