import React from "react";
import {Button, Popover, PopoverProps, TextField, Tooltip} from "@mui/material";
import IconButton, {IconButtonProps} from "@mui/material/IconButton";
import StyleBuilder from "../../IppeUtils/StyleBuilder";
import ScienceIcon from "@mui/icons-material/Science";
import {HorizontalStack, VerticalStack} from "../../IppeUtils/MiscComponents";
import {ce} from "../../IppeUtils/MiscUtils";
import {replaceAll} from "../../IppeUtils/StringUtils";
import {Content} from "../../IppeUtils/IppeTypes";
import axios from "axios";
import {getPostExecsUrl} from "../../IppeUtils/IppeUrls";

export type SaveTestButtonProps = {
  expression: string,
  input: Content
}

type SaveTestButtonState = {
  anchorEl: HTMLButtonElement | undefined,
  workingTitle: string,
  executing: boolean
}

export class SaveTestButton extends React.Component<SaveTestButtonProps, SaveTestButtonState> {
  constructor(props: SaveTestButtonProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
      workingTitle: "",
      executing: false
    };

    this.onSaveTestClick = this.onSaveTestClick.bind(this);
    this.onTitleUpdated = this.onTitleUpdated.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSaveTestClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({anchorEl: event.currentTarget})
  }

  onTitleUpdated(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({workingTitle: event.target.value})
  }

  onSubmit() {
    this.setState({executing: true})

    const testname = replaceAll(this.state.workingTitle.trim(), " ", "_");
    const headers = {"Content-Type": this.props.input.mimeType ?? ""};
    const url = getPostExecsUrl(this.props.expression, true) + `&testname=${encodeURIComponent(testname)}`

    axios.post<ArrayBuffer>(url, this.props.input.content, {
      headers: headers as any,
      responseType: 'arraybuffer',
      validateStatus: () => true, // otherwise, axios will throw exception on non-200 responses.
    }).then((response) => {
      this.setState({anchorEl: undefined, workingTitle: "", executing: false})
    });
  }

  onCancel() {
    this.setState({anchorEl: undefined, workingTitle: ""})
  }

  render() {
    const popoverProps: PopoverProps = {
      open: this.state.anchorEl !== undefined,
      onClose: () => {this.setState({anchorEl: undefined})},
      anchorEl: this.state.anchorEl || null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      }
    };

    const popover = this.state.anchorEl === undefined ? undefined :
      ce(Popover, popoverProps,
        ce(VerticalStack, {spacing: 1, style: {padding: 10, paddingTop: 25, width: 300}},
          ce(TextField, {disabled: this.state.executing, label: "Test Name", value: this.state.workingTitle, onChange: this.onTitleUpdated}),
          ce(HorizontalStack, {spacing: 1, justifyContent: "flex-end"},
            ce(Button, {onClick: this.onCancel, disabled: this.state.executing}, "Cancel"),
            ce(Button, {onClick: this.onSubmit, disabled: !this.state.workingTitle.trim() || this.state.executing}, "Submit"))));

    const buttonProps: IconButtonProps = {
      size: "small",
      onClick: this.onSaveTestClick
    };

    return ce("div", {style: {display: "flex"}},
      ce(Tooltip, {title: "Save as Test"} as any,
        ce(IconButton, buttonProps,
          ce(ScienceIcon, {fontSize: "small"}))),
      popover);
  }
}