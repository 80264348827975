import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {IPPE_LOGIN_MANAGER} from "./IppeLoginManager";
import {IppeCopyrightComponent} from "../IppeUtils/IppeCopyrightComponent";
import {IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import {useState} from "react";
import {ce, isAcceptablePassword, validateEmail} from "../IppeUtils/MiscUtils";
import axios from "axios";
import {getPostLoginsUrl} from "../IppeUtils/IppeUrls";
import {VerticalStack} from "../IppeUtils/MiscComponents";
import {CircularProgress} from "@mui/material";

type IppeLoginProps = {
  onDismiss: () => void,
};

export const IppeLoginComponent: React.FC<IppeLoginProps> = function (props: IppeLoginProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowErrors(true);

    if (!validateEmail(email) || !isAcceptablePassword(password))
      return

    setLoading(true);
    axios.post(getPostLoginsUrl(), {email, password}).then((response) => {
      setLoading(false);

      props.onDismiss();
      IPPE_LOGIN_MANAGER.notifyLogin()
    }).catch((error) => {
      setLoading(false);

      try {
        setErrors(error.response.data.errors.map((i: any) => i.message))
      } catch (e) {
        setErrors(["Unable to login."])
      }
    });
  };

  const errorsComponent = errors.length === 0 ? null : (
    <VerticalStack style={{paddingTop: "1em", fontSize: ".75em"}}>
      {errors.map(e => { return <div key={e} style={{color: "red"}}>{e}</div> })}
    </VerticalStack>);

  const loadingSpinner = loading ? ce(CircularProgress, {
    style: {width: 24, height: 24, color: "white"}
  }) : undefined;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{m: 1, bgcolor: IPPE_PRIMARY_COLOR}}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email || ""}
            onChange={e => setEmail(e.target.value)}
            error={showErrors && !validateEmail(email)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            value={password || ""}
            onChange={e => { setPassword(e.target.value) }}
            error={showErrors && !isAcceptablePassword(password)}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {errorsComponent}
          <Button
            type="submit"
            disabled={loading}
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            {loadingSpinner ?? "Sign In"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Button>Forgot password?</Button>
            </Grid>
            <Grid item>
              <Button onClick={() => IPPE_LOGIN_MANAGER.requestCreateAccount()}>Sign Up</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <IppeCopyrightComponent sx={{mt: 5}}/>
    </Container>
  );
}