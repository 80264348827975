import React from "react";
import {ce, merge} from "../../IppeUtils/MiscUtils";
import {CODE_FONT_FAMILY} from "../../IppeUtils/IppeStyles";
import {ContentComponentProps} from "./ContentComponentMisc";
import {decodeToString, encodeToBuffer} from "../../IppeUtils/ArrayBufferUtils";
import {mbAsBytes} from "../../IppeUtils/MiscUtilsTs";
import {WarningBanner} from "../../IppeUtils/WarningBanner";

const EDITABLE_LIMIT_MB = 1

type TextPlainContentComponentState = {
  showContentTooLargeToEditWarning: boolean
};

export class TextPlainContentComponent extends React.Component<ContentComponentProps, TextPlainContentComponentState> {
  constructor(props: ContentComponentProps) {
    super(props);
    this.onClick = this.onClick.bind(this)
    this.state = {
      showContentTooLargeToEditWarning: false
    }
  }

  onClick() {
    const showContentTooLargeToEditWarning = this.props.content.byteLength > mbAsBytes(EDITABLE_LIMIT_MB);
    if (this.props.onContentChange && showContentTooLargeToEditWarning)
      this.setState({showContentTooLargeToEditWarning}, () => {
        setTimeout(() => {this.setState({showContentTooLargeToEditWarning: false})}, 2500)
      });
  }

  render() {
    const style = merge(CODE_FONT_FAMILY, {
      padding: 5,
      height: "calc(100% - 10px)", // need to account for padding
      width: "calc(100% - 10px)",
      resize: "none",
      border: "none",
      whiteSpace: "pre",
      fontSize: "1em",
      background: "white",
      color: "black",
      // had to jump through a bunch of hoops to get the text color black when the
      // textarea is disabled.  Ultimately, setting `webkitTextFillColor` seems
      // to have resolved the issue.
      webkitTextFillColor: "black"
    }, this.props.style);

    const contentSurpassesEditableLimit = this.props.content.byteLength > mbAsBytes(EDITABLE_LIMIT_MB);
    const textArea = ce("textarea", {
        spellCheck: false,
        autoCapitalize: "off",
        autoComplete: "off",
        autoCorrect: "off",
        value: this.props.content ? decodeToString(this.props.content) : "",
        disabled: this.props.onContentChange === undefined || contentSurpassesEditableLimit,
        onChange: (e: any) => this.props.onContentChange?.(encodeToBuffer(e.target.value)),
        style
      }
    );

    const warning = this.state.showContentTooLargeToEditWarning ?
      ce(WarningBanner, {}, `Unable to inline-edit content larger than ${EDITABLE_LIMIT_MB} MB.`) :
      undefined;

    return ce("div", {onClick: this.onClick, style: {position: "relative", height: "100%", width: "100%"}},
      textArea,
      warning)
  }
}