import {ce} from "./MiscUtils";
import {Alert, AlertTitle} from "@mui/material";
import {IPPE_SHALLOW_BOX_SHADOW} from "./IppeStyles";
import StyleBuilder from "./StyleBuilder";
import {Centered} from "./MiscComponents";


export type IppeErrorProps = {
  message: string
}

export const IppeErrorComponent = (props: IppeErrorProps) => {
  const alertStyle = StyleBuilder.start()
    .width(300)
    .roundedGrayBorder()
    .borderColor("#f06260")
    .boxShadow(IPPE_SHALLOW_BOX_SHADOW)
    .build()

  return ce(Centered, {style: {width: "100%", height: "100%"}},
    ce(Alert, {severity: "error", style: alertStyle},
      ce(AlertTitle, {}, "Error"),
      props.message));
}