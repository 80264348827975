import {IppeCursorPosition, IppePipelineParsedNode, IppeToolSpec} from "../IppeUtils/IppeTypes";
import React from "react";
import {getCursorContext, updateArgName, updateCommandNameInExpression} from "../IppeUtils/IppeUtils";
import {ce, merge} from "../IppeUtils/MiscUtils";
import {CODE_FONT_FAMILY, IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import {IppeCommandViewer} from "./IppeCommandViewerComponent";

export type IppeEditorProps = {
  onCursorChange: (position: IppeCursorPosition) => void,
  onExpressionSubmit: (value: string) => void,
  onExpressionChange: (value: string) => void,
  expression: string,
  parsedExpression: IppePipelineParsedNode,
  tools: Array<IppeToolSpec>,
  style: React.CSSProperties
}

type IppeEditorState = {
  selectionStart: number,
  caretReset: number | undefined
}

// TODO: entering two spaces in rapid succession inserts a period.  This is a MacOS thing, and there's not an
// obvious one-line solution.
export class IppeEditor extends React.Component<IppeEditorProps, IppeEditorState> {
  private textRef: React.RefObject<HTMLTextAreaElement>;

  constructor(props: IppeEditorProps) {
    super(props);

    this.state = {selectionStart: 0, caretReset: undefined}

    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClick = this.onClick.bind(this);

    this.textRef = React.createRef()
  }


  onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const currValue = this.props.expression;
    const newValue = event.target.value;

    // TODO: any other characters to ignore?   Also, is there a better way to do this?
    if (newValue.replace("\n", "") !== currValue) {
      this.props.onExpressionChange(event.target.value);
      // this.props.onCursorChange({offset: event.target.selectionStart,});
      // this.setState({selectionStart: event.target.selectionStart});
    }
  }

  onKeyDown(event: any) {
    if (event.key === 'Enter') {
      // TODO: check if cursor location is after a "\" character.
      // TODO: prune \
      event.preventDefault()
      this.props.onExpressionSubmit(this.props.expression);
      this.setState({selectionStart: 0})
    }

    const cursorPosition = event.target.selectionStart;
    if (event.key === 'Tab') {
      const {expression, parsedExpression, tools} = this.props;
      const {cmdCtxt, cmdNameCtxt, namedArgCtxt, namedArgNameCtxt, namedArgValueCtxt, toolSpec, argSpec} =
        getCursorContext(this.props.expression, this.props.parsedExpression, cursorPosition, this.props.tools);

      // TODO: tab on period is currently failing
      if (cmdCtxt !== undefined && toolSpec === undefined && cmdNameCtxt !== undefined) {
        const candidates = tools.filter((tool) => tool.name.startsWith(cmdNameCtxt.value));
        if (candidates.length > 0) {
          const fullCommandNameWithSpace = candidates[0].name + " ";

          this.props.onExpressionChange(updateCommandNameInExpression(
            expression, cmdCtxt, fullCommandNameWithSpace));

          this.setState({caretReset: cmdNameCtxt.startIdx + fullCommandNameWithSpace.length});
        }
      } else if (toolSpec !== undefined && namedArgCtxt !== undefined && namedArgNameCtxt !== undefined) {
        const candidates = toolSpec.argSpecs.filter(spec => spec.name.startsWith(namedArgNameCtxt.value));
        if (candidates.length > 0) {
          const argName = candidates[0].name;

          this.props.onExpressionChange(updateArgName(
            expression, toolSpec.argSpecs.find(a => a.name === argName), namedArgCtxt, argName));

          this.setState({caretReset: namedArgNameCtxt.startIdx + argName.length + 1});  // +1 is for equals
        }
      }

      // TODO: if user presses tab and they haven't yet provided a required argument, should autocomplete that
      // required argument.

      // basically, so long as we're not in the "value" context of a javascript function, we don't want Tab
      // to switch focus.  If we ARE in this context, then the javascript editor will be raised, in which
      // case we want tab to navigate to it.
      if (namedArgValueCtxt === undefined || argSpec?.caster?.type !== "javascript_function") {
        event.preventDefault();
      }
    }
  }

  onKeyUp(event: any) {
    if (event.target.selectionStart !== this.state.selectionStart) {
      this.setState({selectionStart: event.target.selectionStart});
      this.props.onCursorChange({offset: event.target.selectionStart});
      return
    }
  }

  onClick(event: any) {
    this.props.onCursorChange({offset: event.target.selectionStart});
    this.setState({selectionStart: event.target.selectionStart})
  }

  componentDidUpdate(prevProps: Readonly<IppeEditorProps>, prevState: Readonly<IppeEditorState>, snapshot?: any) {
    if (this.state.caretReset !== undefined && this.textRef.current) {
      this.textRef.current.selectionStart = this.state.caretReset;
      this.textRef.current.selectionEnd = this.state.caretReset;

      this.setState({caretReset: undefined});
    }
  }

  render() {
    const commonStyle: React.CSSProperties = merge(CODE_FONT_FAMILY, {
      padding: 0,
      margin: 0,
      fontSize: "1em",
      width: "inherit",
      border: "none",
      whiteSpace: "pre-wrap",
      overflowWrap: "break-word",
      lineHeight: 1.25
    })

    const editorStyle: React.CSSProperties = merge(commonStyle, {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      color: "transparent",
      background: "transparent",
      caretColor: "black",
      resize: "none"
    });

    const eltsStyle: React.CSSProperties = merge(commonStyle, {
      minHeight: "1.25em",
    });

    const viewer = ce(IppeCommandViewer, {
      expression: this.props.expression,
      parsedExpression: this.props.parsedExpression,
      cursorLocation: this.state.selectionStart,
      style: eltsStyle
    });

    const editor = ce("textarea", {
      id: "shell",
      ref: this.textRef,
      tabIndex: 1,
      className: "ippe-editor",
      onChange: this.onChange,
      onKeyUp: this.onKeyUp,
      onKeyDown: this.onKeyDown,
      onClick: this.onClick,
      value: this.props.expression,
      spellCheck: false,
      autoCapitalize: "off",
      autoComplete: "off",
      autoCorrect: "off",
      style: editorStyle
    });

    return ce("div", {style: merge(this.props.style, {position: "relative"})}, viewer, editor);
  }
}