import {removeURLParameter, updateURLParameter} from "../IppeUtils/MiscUtilsTs";

export function removeSampleContentIdentifierFromUrl() {
  window.history.replaceState({}, 'Title',
    removeURLParameter(window.location.href, "input_id"));
}

export function updateSampleContentIdentifierInUrl(
  sampleUrl: string
) {
  window.history.replaceState({}, 'Title',
    updateURLParameter(window.location.href, "input_id", sampleUrl));
}
