import React from "react";
import {ce, merge} from "./MiscUtils";
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {nord} from "react-syntax-highlighter/dist/esm/styles/prism";
import {CopyButton} from "./CopyButton";
import {DownloadButton} from "./DownloadButton";
import {HorizontalStack} from "./MiscComponents";

type IppeMarkdownComponentProps = {
  children?: string,
  codeCopy?: boolean | undefined,
  downloadCodeAs?: string | undefined,
  compact?: boolean
  style?: React.CSSProperties,
}

export const IppeMarkdownComponent: React.FC<IppeMarkdownComponentProps> = function ({children, codeCopy, downloadCodeAs, style, compact}) {
  let components = {
    //@ts-ignore
    code({node, inline, className, children, ...props}) {
      const copyButton = (codeCopy === true) ? ce(CopyButton, {
        content: children,
        secondaryColor: true,
        style: {}
      }) : undefined;

      const downloadButton = (downloadCodeAs !== undefined) ? ce(DownloadButton, {
        content: children,
        secondaryColor: true,
        filename: downloadCodeAs,
        style: {}
      }) : undefined;

      const buttons = copyButton || downloadButton ?
        ce(HorizontalStack, { style: {position: "absolute", top: 10, right: 10}, spacing: 1}, copyButton, downloadButton) :
        undefined;

      const language = /language-(\w+)/.exec(className || '')?.[1] ?? "bash"
      return inline ? ce("span", {className: "ippe-markdown-code-style", ...props}, children) :
        ce("div", {style: {position: "relative"}},
          ce(SyntaxHighlighter, {children: children, style: nord, language, ...props}),
          buttons
        );
    }
  };

  // @ts-ignore
  return ce("div", {style}, ce(ReactMarkdown, {
    // NOTE: additional styling is applied in index.css.
    className: compact ? "ippe-react-markdown-compact" : "ippe-react-markdown",
    children,
    remarkPlugins: [remarkGfm],
    components
  }));
}
