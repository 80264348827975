import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {Box, Modal} from "@mui/material";
import {HorizontalSliver, VerticalStack} from "../IppeUtils/MiscComponents";
import {IPPE_PRIMARY_COLOR} from "../IppeUtils/IppeStyles";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {IppeFavoritesComponent} from "./IppeFavoritesComponent";


export type IppeFavoriteModalComponentProps = {
  onClose: () => void
}


const MODAL_STYLE = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderColor: IPPE_PRIMARY_COLOR,
  boxShadow: 24,
  maxHeight: "90vh",
  maxWidth: "80vw"
};


export class IppeFavoritesModalComponent extends React.Component<IppeFavoriteModalComponentProps, {}> {
  render() {
    return ce(Modal, {open: true, onClose: this.props.onClose} as any,  // TODO: shouldn't need to cast
      ce(Box, {sx: MODAL_STYLE}, ce(VerticalStack, {divider: ce(HorizontalSliver), style: {maxHeight: "inherit", maxWidth: "inherit"}},
        ce("h1", {style: StyleBuilder.start().hPadding("30px").build()}, `Favorites`),
        ce("div", {style: StyleBuilder.start().hPadding("15px").vPadding("20px").overflowScroll().build()},
          ce(IppeFavoritesComponent)))));
  }
}
