import React from "react";
import {Stack} from "@mui/material";
import {merge} from "./MiscUtils";


const ce = React.createElement;


export const VerticalSliver = (
  {color = "lightgrey", thickness = 1}
) => ce("div", {style: {width: thickness, minWidth: thickness, height: "100%", background: color}});

export const HorizontalSliver = (
  {color = "lightgrey", thickness = 1}
) => ce("div", {style: {width: "100%", minHeight: thickness, height: thickness, background: color}});

export const VerticalSpacer = (
  {thickness = "1em",}
) => ce("div", {style: {height: thickness, minHeight: thickness, width: "100%"}});

export const HorizontalSpacer = (
  {thickness = "1em"}
) => ce("span", {style: {marginLeft: thickness}});

export class HorizontalStack extends React.Component {
  render() {
    let {...props} = this.props;
    props.direction = "row";
    return ce(Stack, props);
  }
}

export class VerticalStack extends React.Component {
  render() {
    let {...props} = this.props;
    props.direction = "column";
    return ce(Stack, props);
  }
}

export function CenterVertical(props) {
  return ce(VerticalStack, {style: merge({height: "inherit"}, props.style || {})},
    ce("div", {style: {flexGrow: 1}}),
    props.children,
    ce("div", {style: {flexGrow: 1}}));
}

export function Centered(props) {
  return ce(VerticalStack, {style: merge({height: "inherit", alignItems: "center"}, props.style || {})},
    ce("div", {style: {flexGrow: 1}}),
    props.children,
    ce("div", {style: {flexGrow: 1}}));
}
