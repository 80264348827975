import React from "react";
import {ce, merge} from "../../IppeUtils/MiscUtils";
import {ContentComponentProps} from "./ContentComponentMisc";
import {base64Encode} from "../../IppeUtils/ArrayBufferUtils";
import {ContentEditorFileUploader} from "./ContentEditorFileUploader";
import {IPPE_PREVIEW_CONTENT_BORDER} from "../../IppeUtils/IppeStyles";
import {Content} from "../../IppeUtils/IppeTypes";

export class ImageContentComponent extends React.Component<ContentComponentProps, {}> {
  constructor(props: ContentComponentProps) {
    super(props);
  }

  render() {
    // TODO: TIFF not rendering correctly
    const subType = this.props.mimeType.split("/")[1]
    const onValueChange = (value: Content) => {
      if (value.content !== undefined && this.props.onContentChange !== undefined)
        this.props.onContentChange(value.content)
    };

    const img = this.props.content.byteLength > 0 ?
      ce("img", {
        src: `data:image/${subType};base64,` + base64Encode(this.props.content),
        style: {width: "inherit", height: "inherit", objectFit: "contain"}
      }) :
      ce(ContentEditorFileUploader, {
        mimeType: this.props.mimeType,
        onValueChange,
        style: IPPE_PREVIEW_CONTENT_BORDER
      });

    return img || ce("div");
  }
}