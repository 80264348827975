import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {NavLink} from "react-router-dom";
import {IPPE_LOGIN_MANAGER} from "../IppeLogin/IppeLoginManager";
import {ce} from "../IppeUtils/MiscUtils";
import SettingsIcon from '@mui/icons-material/Settings';
import {
  IPPE_APP_BAR_HEIGHT,
  IPPE_PRIMARY_COLOR,
  MONO_FONT_FAMILY
} from "../IppeUtils/IppeStyles";
import {HorizontalSpacer} from "../IppeUtils/MiscComponents";

type MenuLink = {
  type: string,
  name: string,
  link: string
};

function menuLink(name: string, link: string): MenuLink {
  return {type: "link", name, link};
}

type MenuHandler = {
  type: string,
  name: string,
  handler: () => void
};

function menuHandler(name: string, handler: () => void): MenuHandler {
  return {type: "handler", name, handler};
}

const PAGES_LOGGED_IN: Array<MenuLink | MenuHandler> = [
  menuLink('Home', '/'),
  menuLink('Builder', '/builder'),
  menuLink('Docs', '/docs'),
  // menuLink('Pricing', '/pricing'),
];

const PAGES_LOGGED_OUT: Array<MenuLink | MenuHandler> = [
  menuLink('Home', '/'),
  menuLink('Builder', '/builder'),
  menuLink('Docs', '/docs'),
  // menuLink('Pricing', '/pricing'),
];

const SETTINGS_LOGGED_IN: Array<MenuLink | MenuHandler> = [
  menuLink('Account', '/account'),
  menuLink('Credentials', '/credentials'),
  menuLink('Terms', '/terms'),
  menuHandler('Logout', () => {
    IPPE_LOGIN_MANAGER.logout()
  }),
];

const SETTINGS_LOGGED_OUT: Array<MenuLink | MenuHandler> = [
  menuLink('Terms', '/terms'),
  menuHandler('Login', () => {
    IPPE_LOGIN_MANAGER.requestLogin()
  }),
  menuHandler('Create Account', () => {
    IPPE_LOGIN_MANAGER.requestCreateAccount()
  }),
];

type IppeAppBarProps = {}

type IppeAppBarState = {
  anchorElNav: any, // LOWTODO
  anchorElUser: any, // LOWTODO
  pages: Array<MenuLink | MenuHandler>,
  settings: Array<MenuLink | MenuHandler>,
};


function createDropdownMenuItem(
  elt: MenuLink | MenuHandler,
  onClick: () => void
): React.ReactElement | undefined {
  if (elt.type === "link") {
    return ce(MenuItem, {
      key: elt.name,
      // @ts-ignore
      component: NavLink,
      to: (elt as MenuLink).link,
      onClick
    }, ce(Typography, {textAlign: "center"}, elt.name))
  } else if (elt.type === "handler") {
    return ce(MenuItem, {
        key: elt.name,
        onClick: () => {
          (elt as MenuHandler).handler();
          onClick();
        }
      },
      ce(Typography, {textAlign: "center"}, elt.name))
  }

  return undefined
}

function createNavBarItem(
  elt: MenuLink | MenuHandler,
  onClick: () => void
): React.ReactElement | undefined {
  const sx = {
    my: 2,
    color: 'white',
    display: 'block',
    textAlign: "center",
    minWidth: elt.name.length * 13,  // so that widths don't change when activated and text turns bold.
    fontSize: 17,
    "&:hover": {
      color: "white",
      fontWeight: 600
    },
    "&.active": {
      color: "white",
      fontWeight: 600
    }
  };

  if (elt.type === "link") {
    return ce(Button, {
      key: elt.name,
      // @ts-ignore
      component: NavLink,
      to: (elt as MenuLink).link,
      onClick,
      sx
    }, elt.name)
  } else if (elt.type === "handler") {
    return ce(Button, {
        key: elt.name,
        onClick: () => {
          (elt as MenuHandler).handler();
          onClick();
        }
      },
      elt.name)
  }

  return undefined
}


class IppeAppBarComponent extends React.Component<IppeAppBarProps, IppeAppBarState> {
  constructor(props: IppeAppBarProps) {
    super(props);

    this.state = {
      anchorElUser: undefined,
      anchorElNav: undefined,
      settings: IPPE_LOGIN_MANAGER.isLoggedIn() ? SETTINGS_LOGGED_IN : SETTINGS_LOGGED_OUT,
      pages: IPPE_LOGIN_MANAGER.isLoggedIn() ? PAGES_LOGGED_IN : PAGES_LOGGED_OUT,
    }
  }

  componentDidMount() {
    IPPE_LOGIN_MANAGER.registerOnLoginHandler("ippe-app-bar", () => this.setState({
      settings: SETTINGS_LOGGED_IN, pages: PAGES_LOGGED_IN
    }))
    IPPE_LOGIN_MANAGER.registerOnLogoutHandler("ippe-app-bar", () => this.setState({
      settings: SETTINGS_LOGGED_OUT, pages: PAGES_LOGGED_OUT
    }))
  }

  componentWillUnmount() {
    IPPE_LOGIN_MANAGER.unregisterOnLoginHandler("ippe-app-bar")
    IPPE_LOGIN_MANAGER.unregisterOnLogoutHandler("ippe-app-bar")
  }

  render() {
    const handleOpenNavMenu = (event: any) => this.setState({anchorElNav: event.currentTarget});
    const handleCloseNavMenu = () => this.setState({anchorElNav: undefined});

    const handleOpenUserMenu = (event: any) => this.setState({anchorElUser: event.currentTarget});
    const handleCloseUserMenu = () => this.setState({anchorElUser: undefined});

    const settingsItems: Array<React.ReactElement | undefined> = this.state.settings.map((page) =>
      createDropdownMenuItem(page, handleCloseUserMenu));

    const menuItems = this.state.pages.map((page) => createNavBarItem(page, handleCloseNavMenu));

    return ce(AppBar, {
        position: "relative", style: {
          display: "flex",
          flexWrap: "nowrap",
          height: IPPE_APP_BAR_HEIGHT,
          alignItems: "center",
          flexDirection: "row",
        }
      },
      ce(HorizontalSpacer, {thickness: "4px"}),
      ce(AlphaComponent),
      ce(HorizontalSpacer, {thickness: "4px"}),
      ce("img", {src: "/logo.svg", style: {height: 40}}),
      ce(HorizontalSpacer, {thickness: "1.25em"}),
      menuItems,
      ce("div", {style: {display: "flex", flexGrow: 1}}),
      ce(Box, {sx: {flexGrow: 0}},
        ce(Tooltip, {title: "Open Settings"} as any,
          ce(IconButton, {onClick: handleOpenUserMenu, sx: {p: 0}},
            ce(Avatar, {sx: {background: IPPE_PRIMARY_COLOR}}, ce(SettingsIcon)))),
        ce(Menu, {
          id: "menu-appbar",
          anchorEl: this.state.anchorElUser,
          anchorOrigin: {vertical: 'top', horizontal: 'right'},
          transformOrigin: {vertical: 'top', horizontal: 'right'},
          keepMounted: true,
          open: !!this.state.anchorElUser,
          onClose: handleCloseUserMenu,
        }, settingsItems)),
      ce(HorizontalSpacer, {thickness: "4px"}),
    );
  }
}

export const AlphaComponent: React.FC = function ({children}) {
  return ce("p", {
    style: {
      writingMode: "vertical-rl",
      textOrientation: "upright",
      fontFamily: MONO_FONT_FAMILY,
      fontSize: ".5em",
      margin: 0
    }
  }, "ALPHA");
}

export default IppeAppBarComponent;
