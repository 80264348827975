import {CopyAsComponentProps, PlainTextComponent, SnippetComponent} from "./CopyAsMisc";
import React from "react";
import {ce} from "../IppeUtils/MiscUtils";
import {IPPE_HOST} from "../IppeUtils/IppeUrls";
import {VerticalSpacer, VerticalStack} from "../IppeUtils/MiscComponents";
import StyleBuilder from "../IppeUtils/StyleBuilder";
import {TextField} from "@mui/material";
import {IppeMarkdownComponent} from "../IppeUtils/IppeMarkdownComponent";
import {IppeAccessKeyPicker} from "../IppeAccessKeys/IppeAccessKeyPickerComponent";
import {AccessKeyResponse} from "../IppeUtils/IppeTypes";


type CopyAsJavaComponentState = {
  functionName: string,
  accessKey: AccessKeyResponse | undefined
}

export class CopyAsJavaComponent extends React.Component<CopyAsComponentProps, CopyAsJavaComponentState> {
  constructor(props: CopyAsComponentProps) {
    super(props);

    this.state = {
      functionName: "",
      accessKey: undefined
    };

    this.onFunctionNameChange = this.onFunctionNameChange.bind(this);
  }

  onFunctionNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({functionName: event.target.value.replace(/[^a-zA-Z0-9_]/, "")});
  }

  render() {
    const functionName = this.state.functionName || "IppeFunction";
    const onAccessKeyChange = (accessKey: AccessKeyResponse) => this.setState({accessKey})

    return ce(VerticalStack, {spacing: "1.5em", style: StyleBuilder.start().size("inherit").build()},
      ce(IppeMarkdownComponent, null, createIntroSnippet(functionName)),
      ce(TextField, {label: "Function Name", onChange: this.onFunctionNameChange, value: this.state.functionName || ""}),
      ce(IppeAccessKeyPicker, {nameHint: this.state.functionName, accessKey: this.state.accessKey, onChange: onAccessKeyChange}),
      ce(IppeMarkdownComponent, {codeCopy: true, downloadCodeAs: functionName + ".java"},
        createBodySnippet(functionName, this.props.expression, this.props.mimeType, this.state.accessKey)),
      ce(IppeMarkdownComponent, {},
        createExampleSnippet(functionName)));
  }
}


function createIntroSnippet(name: string) {
  return `
This snippet provides the capability defined by your Ippe expression as a java function.  The implementation exclusively depends upon:

* Java installation and native Java libraries
* network-access to [${window.location.host}](${window.location.host})

The snippet can be invoked directly from your command-line (in which, it will operate on \`stdin\` and produce output to \`stdout\`), or dropped into an exising Java project. 
`
}

function createBodySnippet(
  name: string,
  expression: string,
  mimeType: string | undefined,
  accessKey: AccessKeyResponse | undefined,
): string {
  const accessKeyIdValue = accessKey?.accessKeyId || "[access key id]";
  const accessKeySecretValue = accessKey?.accessKeySecret || "[access key secret]";
  const contentType = mimeType ?? "";

  return `
~~~java
import java.io.InputStream;
import java.io.OutputStream;
import java.net.HttpURLConnection;
import java.net.URL;

class ${name} {
  static byte[] execute(byte[] body) throws Exception {
    URL url = new URL("${IPPE_HOST}/api/v0/execs?expression=${encodeURIComponent(expression)}");
    HttpURLConnection http = (HttpURLConnection) url.openConnection();

    http.setDoOutput(true);
    http.setDoInput(true);

    http.setRequestMethod("POST");
    http.setRequestProperty("Content-Type", "${contentType}");
    http.setRequestProperty("Access-Key-ID", "${accessKeyIdValue}");
    http.setRequestProperty("Access-Key-Secret", "${accessKeySecretValue}");

    OutputStream os = http.getOutputStream();
    os.write(body);
    os.close();

    if (http.getResponseCode() != HttpURLConnection.HTTP_OK) {
      InputStream is = http.getErrorStream();
      String errorResponse = new String(is.readAllBytes());
      is.close();
           
      throw new Exception("Execution of IPPE expression failed: " + errorResponse);
    }

    InputStream is = http.getInputStream();
    byte [] responseBody = is.readAllBytes();
    is.close();

    http.disconnect();

    return responseBody;
  }
  
  public static void main(String[] args) {
    try {
      byte [] result = execute(System.in.readAllBytes());
      System.out.write(result);
    } catch (Exception e) {
      System.err.println(e);
    }
  }
}  
~~~
`
}

function createExampleSnippet(name: string) {
  return `
Example CLI invocation:

~~~
cat some_input_file | java ${name}.java | md5sum
~~~  
`
}
