import {IppeLinkedNode, IppeParsedNode, IppePipelineParsedNode} from "../IppeUtils/IppeTypes";
import React from "react";
import {ce, merge} from "../IppeUtils/MiscUtils";
import {
  IPPE_ARG_NAME_STYLE,
  IPPE_ARG_VALUE_STYLE,
  IPPE_COMMAND_STYLE,
  IPPE_PRIMARY_COLOR
} from "../IppeUtils/IppeStyles";
import {sortBy} from "ramda";

export const COMMAND_VIEWER_CURSOR_ANCHOR_ID = "command_viewer_cursor_anchor";

export type IppeCommandViewerProps = {
  expression: string,
  parsedExpression: IppePipelineParsedNode,
  cursorLocation?: number | undefined,
  style: React.CSSProperties
}

export class IppeCommandViewer extends React.Component<IppeCommandViewerProps, {}> {
  render() {
    type Styling = {
      startIdx: number,
      endIdx: number
      style: React.CSSProperties
    };

    const stylings: Array<Styling> = [];
    this.props.parsedExpression.commands.forEach(cmd => {
      stylings.push({
        startIdx: cmd.name.startIdx,
        endIdx: cmd.name.endIdx,
        style: IPPE_COMMAND_STYLE
      });

      cmd.namedArguments.forEach((arg) => {
        stylings.push({startIdx: arg.name.startIdx, endIdx: arg.name.endIdx, style: IPPE_ARG_NAME_STYLE});
        stylings.push({startIdx: arg.value.startIdx, endIdx: arg.value.endIdx, style: IPPE_ARG_VALUE_STYLE});
      });

      cmd.positionedArguments.forEach((arg) => {
        stylings.push({startIdx: arg.startIdx, endIdx: arg.endIdx, style: IPPE_ARG_VALUE_STYLE});
      })
    });

    const expression = this.props.expression;
    const elts: Array<React.ReactElement> = [];
    const sortedStylings = sortBy((s) => s.startIdx, stylings);
    sortedStylings.forEach((styling, idx) => {
      const {startIdx, endIdx, style} = styling;

      const key = `${startIdx}-${endIdx}`;

      const gapKey = `pre-${key}`;
      elts.push(ce("span", {key: gapKey}, expression.substring(
        idx === 0 ? 0 : sortedStylings[idx - 1].endIdx,
        startIdx)));

      const cursorLoc = this.props.cursorLocation;
      const id = (cursorLoc !== undefined && startIdx <= cursorLoc && cursorLoc <= endIdx) ?
        COMMAND_VIEWER_CURSOR_ANCHOR_ID : undefined;

      elts.push(ce("span", {key, id, style}, expression.substring(startIdx, endIdx)));

      if (idx === (sortedStylings.length - 1) && (endIdx < expression.length)) {
        const gapKey = `post-${key}`;
        elts.push(ce("span", {key: gapKey}, expression.substring(endIdx)));
      }
    });

    return ce("div", {className: "prevent-safari-tooltip", style: this.props.style}, elts);
  }
}

function conditionallyApplyErrorStyling(
  linkedNode: IppeLinkedNode,
  parsedNode: IppeParsedNode,
  styling: React.CSSProperties
): React.CSSProperties {
  return (linkedNode.errors.length === 0 && parsedNode.errors.length === 0) ?
    styling :
    merge(styling, {
      textDecorationLine: "underline",
      textDerationStyle: "dotted",
      textDecorationColor: "red"
    });
}